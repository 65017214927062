import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './root-nav.component.html',
  styleUrls: ['./root-nav.component.scss']
})
export class RootNavigationComponent implements OnInit {

  @Input() menuList!: boolean;

  showDropdownMenu: boolean = false;
  showRootMenuSidebar: boolean = false;
  showRegistersMenuSidebar: boolean = this.menuList;
  isRegistersScreen: boolean = false;

  linkListHeader = [
    { name: "Exames", routerLink: "exams", routerLinkActive: "active", icon: "icon-asterisk", toView: this.superUserOrStaff || this.sessionManager.getUserPermission(PermissionConst.view_polysomnography) },
    { name: "Oxímetro", routerLink: "oximeter", routerLinkActive: "active", icon: "icon-oximetro", toView: this.superUserOrStaff || this.sessionManager.getUserPermission(PermissionConst.view_oximeter) },
    { name: "Pacientes", routerLink: "patients", routerLinkActive: "active", icon: "icon-Icon-open-person", toView: this.superUserOrStaff || this.sessionManager.getUserPermission(PermissionConst.view_patient) },
    { name: "Cadastros", routerLink: this.routerLinkRegister, routerLinkActive: "active", icon: "icon-Icon-material-edit", toView: true },
  ];

  get routerLinkRegister() {
    if (!this.superUserOrStaff) {
      let name = this.sessionManager.getClinicCurrent();
      return `registers/clinics/edit/${name?.value}`;
    }
    return 'registers';
  }

  get superUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  get routerLinkUsers() {
    if (!this.superUserOrStaff) {
      return 'clinicUsers';
    }
    return 'systemUsers';
  }

  constructor(private router: Router, private sessionManager: SessionManagerService, private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.checkRegistersScreen();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.checkRegistersScreen();
      }
      else if (event instanceof NavigationEnd) {
        this.checkRegistersScreen();
      }
    });
  }

  @HostListener('document:click', ['$event'])
  clickListener(event: Event) {
    if ((event.target! as HTMLElement).id != "dropdownButton") {
      this.showDropdownMenu = false;
    }

    if ((event.target! as HTMLElement).id == "sidebar-background") {
      this.showRootMenuSidebar = false;
      this.showRegistersMenuSidebar = false;
    }
  }

  avatarClickHandle() {
    this.showDropdownMenu = !this.showDropdownMenu;
  }


  logoutButtonHandler() {
    this.sessionManager.clearSessionData();
    this.router.navigate(["/login"]);
  }

  rootMenuButtonHandler() {
    this.showRootMenuSidebar = !this.showRootMenuSidebar;
  }

  registersMenuButtonHandler() {
    this.showRegistersMenuSidebar = !this.showRegistersMenuSidebar;
  }

  goToUserScreen() {
    if (this.router.url.substr(0, this.router.url.lastIndexOf("\/")) != `/dashboard/registers/${this.routerLinkUsers}/edit`) {
      this.router.navigate([`dashboard/registers/${this.routerLinkUsers}/edit/`, this.sessionManager.getSessionData().id!]);
    }
  }

  getProfileImage() {
    let profileImage = this.sessionManager.getSessionData().profile_image ?? "/assets/iconmonstr-user-5.svg";
    return profileImage;
  }

  checkRegistersScreen() {
    if (this.router.url.split("/")[2] == "registers" || (this.router.url.split("/")[2] == "patients" && this.router.url.split("/")[3] == "edit"))
      this.isRegistersScreen = true;
    else
      this.isRegistersScreen = false;
  }
}
