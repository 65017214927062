<div class="field mt-4 mb-4" [formGroup]="formGroup">
    <label class="field__title" [for]="id">{{label ? label : ""}}</label>

    <div *ngIf="columns == false">
        <div class="option" *ngFor="let option of optionList; let i = index" [ngClass]="disabled? 'option__disabled' : 'null'">
            <input type="checkbox" (change)="onChange(option, i)" [id]="option.name" [checked]="option.checked">
            <label [for]="option.name" [ngClass]="disabled? 'label__disabled' : 'null'">{{option.label}}</label>
        </div>
    </div>

    <div *ngIf="columns == true">
        <div class="row">
            <div *ngFor="let option of optionList; let i = index" class="col-12 col-sm-6 col-xl-4 option" [ngClass]="disabled? 'option__disabled' : 'null'">
                <input type="checkbox" (change)="onChange(option, i)" [id]="option.name" value={{option.checked}}
                    [value]="formGroup.get(id)?.value!" [checked]="option.checked">
                <label [for]="option.name" class="checkbox-label" [ngClass]="disabled? 'label__disabled' : 'null'">{{option.label}}</label>
            </div>
        </div>
    </div>

    <form-error class="mt-3" [id]="id" [formGroup]="formGroup"></form-error>
</div>
