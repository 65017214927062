import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class QuizService {
  constructor(private http: HttpClient) {
  }

  overrideImage(polysomnographyId: number, quizQuestionId: number, file: File) {
    let formData = new FormData();
    formData.set('answer', file);
    formData.set('override', 'true');
    return this.http.patch(`${environment.API_URL}/polysomnography/${polysomnographyId}/quiz_answers/${quizQuestionId}/image_answer/?upload=true`, formData);
  }

  saveImage(polysomnographyId: number, quizQuestionId: number, file: File, overridePath?: string): Observable<{
    answer: string[] | string
  }> {
    let formData = new FormData();
    formData.set('answer', file);
    if (overridePath) {
      formData.set('override', overridePath);
    }
    return this.http.patch<{
      answer: string[] | string
    }>(`${environment.API_URL}/polysomnography/${polysomnographyId}/quiz_answers/${quizQuestionId}/image_answer/?upload=true`, formData);
  }
}
