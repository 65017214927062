<div class="modal-wrapper">
    <div class="app-modal">
      <div class="app-modal__header">
        <h2>Importar Exame (CSV)</h2>
      </div>
  
      <div class="app-modal__content">
        <form enctype="multipart/form-data">
          <input type="file" accept="text/csv" (change)="fileSelected($event)">
        </form>
      </div>
  
      <div class="app-modal__buttons-wrapper">
        <button class="btn btn-link" (click)="closeModal()">Cancelar</button>
        <button class="btn btn-primary" (click)="submit()" [disabled]="loading">{{textButton}}</button>
      </div>
    </div>
</div>
