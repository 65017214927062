import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClinicsListComponent } from './clinics-list/clinics-list.component';
import { ClinicFormComponent } from './clinic-form/clinic-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';



@NgModule({
  declarations: [
    ClinicsListComponent,
    ClinicFormComponent
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    NgxMaskModule,
    ReactiveFormsModule,
    RenderersModule,
    SharedModule,
    CurrencyMaskModule
  ]
})
export class ClinicsModule { }
