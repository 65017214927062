import {NgModule} from "@angular/core";
import {PatientComponent} from "./patient.component";
import {NgxMaskModule} from "ngx-mask";
import {RouterModule} from "@angular/router";
import {QuizComponent} from './quiz/quiz.component';
import {CommonModule} from "@angular/common";
import {CoreModule} from "../../core/core.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RegisterComponent} from "./register/register.component";
import { ConfirmCodeComponent } from './confirm-code/confirm-code.component';
import { SelectVideoComponent } from './select-video/select-video.component';
import {SharedModule} from "../../shared/shared.module";
import {RenderersModule} from "../../shared/renderers/renderers.module";
import { ShowVideoComponent } from './show-video/show-video.component';
import {NgSelectModule} from "@ng-select/ng-select";

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        RouterModule,
        SharedModule,
        RenderersModule,
        NgSelectModule,
    ],
  declarations: [
    PatientComponent,
    RegisterComponent,
    QuizComponent,
    ConfirmCodeComponent,
    SelectVideoComponent,
    ConfirmCodeComponent,
    ShowVideoComponent
  ]
})
export class PatientModule {
}
