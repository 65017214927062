import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { ListTemplate } from '../models/list-template';
import * as moment from 'moment';
import { debounceTime, distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportModalComponent } from '../components/export-modal/export-modal.component';
import { phoneNumberStringFormatter } from 'src/app/core/utils/string-formatters';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ClinicsService } from 'src/app/core/services/clinics.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastrService } from 'ngx-toastr';
import { OximeterService } from 'src/app/core/services/oximeter.service';

@Component({
  selector: 'list-renderer',
  templateUrl: './list-renderer.component.html',
  styleUrls: ['./list-renderer.component.scss']
})
export class ListRendererComponent implements OnInit {

  @Input() listTemplate!: ListTemplate;
  @Output() onNextPage = new EventEmitter<number>();
  @ViewChild('searchbar') input!: ElementRef;
  @ViewChild('date') date!: ElementRef;
  @ViewChild('dateStart') dateStart!: ElementRef;
  @ViewChild('dateEnd') dateEnd!: ElementRef;

  page: number = 1;
  searchAdvancedBool: boolean = false;
  mediaQuery: boolean = false;

  labelStatus: string = 'Nenhum status selecionado';
  statusFilterIsOpened: boolean = false;
  selectedStatus: any[] = [];

  labelClinic: string = 'Todas as clinicas';
  clinicFilterIsOpened: boolean = false;
  selectedClinic: any[] = [];

  clinicList: any[] = [];

  statusList = [{ name: '', value: '' }];

  shareIsOpened: boolean = false;
  shareDropdownX: number = 0;
  shareDropdownY: number = 0;
  invoiceUrl: string | null = null;
  phoneNumber: string | null = null;

  statusExamList = [
    { value: 'IN_PROGRESS', name: 'Em andamento' },
    { value: 'DONE', name: 'Exames realizados' },
    { value: 'CONCLUDED', name: 'Concluído' },
    { value: 'CANCELED', name: 'Cancelado' }
  ];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private modalService: NgbModal,
    private renderer: Renderer2, private sessionManager: SessionManagerService, private clinicService: ClinicsService,
    private clipboard: Clipboard, private toast: ToastrService, private oximeterService: OximeterService) {
    let mediaQuery = window.matchMedia("(min-width: 768px)");
    fromEvent<MediaQueryList>(mediaQuery, 'change')
      .pipe(
        startWith(mediaQuery),
        map((list: MediaQueryList) => list.matches)
      ).subscribe((matches) => {
        this.mediaQuery = matches;
      });
  }

  ngOnInit(): void {
    this.listTemplate.is_active = true;
    if (this.listTemplate.showInfo == undefined) {
      this.listTemplate.showInfo = true;
    }
    if (this.listTemplate.count == undefined) {
      this.listTemplate.count = true;
    }
    if (this.router.url.split('/')[2] == 'exams') {
      this.statusList = this.statusExamList;
    } else {
      this.statusList = [
        { name: 'Disponível', value: 'AVAILABLE' },
        { name: 'Ocupado', value: 'OCCUPIED' }
      ];
    }

    if (this.isSuperUserOrStaff) {
      this.getClinicsName();
    }
  }

  onClickActive() {
    if (this.listTemplate.onClickActive)
      this.listTemplate.onClickActive();
  }

  get isActiveText() {
    if (this.listTemplate.is_active)
      return "Ver desativados";
    return "Ver ativos";
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  get currentClinicId() {
    if(!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent().value;
    }
    return '';
  }

  openStatusFilter() {
    this.statusFilterIsOpened = true;
  }

  closeStatusFilter() {
    this.statusFilterIsOpened = false;
  }

  openShare() {
    this.shareIsOpened = true;
  }

  closeShare() {
    this.shareIsOpened = false;
  }

  toggleAllStatus() {
    if (!this.selectedStatus) {
      this.selectedStatus = this.statusList;
      this.statusList.forEach(item => this.statusIsSelected(item));
      this.labelStatus = 'Todas os status';
    } else if (this.selectedStatus.length != this.statusList.length) {
      this.statusList.forEach(item => {
        if (!this.selectedStatus.some(value => value.name == item.name))
          this.selectedStatus.push(item);
        this.statusIsSelected(item);
      });
      this.labelStatus = 'Todas os status';
    } else {
      this.selectedStatus = [];
      this.labelStatus = 'Nenhum status selecionado';
    }
    let idsMap = this.selectedStatus.map((item) => {
      return item.value;
    });
    let idsStr = idsMap.join(',');
    if (this.listTemplate.onStatusFocusOut) this.listTemplate.onStatusFocusOut(idsStr);
  }

  toggleStatus(status: any) {
    if (this.statusIsSelected(status)) {
      this.selectedStatus = this.selectedStatus.filter(value => status.value != value.value);
    } else {
      this.selectedStatus.push(status);
    }
    let valueMap = this.selectedStatus.map((item) => {
      return item.value;
    });
    let valueStr = valueMap.join(',');
    if (this.listTemplate.onStatusFocusOut) this.listTemplate.onStatusFocusOut(valueStr);
    let nameMap = this.selectedStatus.map((item) => {
      return item.name;
    });

    if (nameMap.length == 0) {
      this.labelStatus = 'Nenhum status selecionado';
    } else if (nameMap.length == this.statusList.length) {
      this.labelStatus = 'Todos os status';
    } else {
      this.labelStatus = nameMap.join(', ');
    }
  }

  statusIsSelected(status: any) {
    return this.selectedStatus.some(value => status.name == value.name);
  }

  getSearchDate() {
    if (this.listTemplate.onDateFocusOut) {
      this.listTemplate.onDateFocusOut(this.date.nativeElement.value);
    }
  }

  getSearchDateRange() {
    let dateStart = this.dateStart.nativeElement.value;
    let dateEnd = this.dateEnd.nativeElement.value;
    if (this.listTemplate.onDateRangeFocusOut) {
      let data = {
        start: dateStart,
        end: dateEnd
      }
      this.listTemplate.onDateRangeFocusOut(data);
    }
  }

  getClinicsName() {
    this.clinicService.getAll().subscribe(response => {
      this.clinicList = response.map(res => {
        return {
          name: res.display_name,
          value: res.id
        }
      });
    }, (error) => {
      console.log(error);
    });
  }

  openClinicFilter() {
    this.clinicFilterIsOpened = true;
  }

  closeClinicFilter() {
    this.clinicFilterIsOpened = false;
  }

  toggleAllClinics() {
    if (!this.selectedClinic) {
      this.selectedClinic = this.clinicList;
      this.clinicList.forEach(item => this.clinicIsSelected(item));
      this.labelClinic = 'Todas as clínicas';
    } else if (this.selectedClinic.length != this.clinicList.length) {
      this.clinicList.forEach(item => {
        if (!this.selectedClinic.some(value => value.name == item.name))
          this.selectedClinic.push(item);
        this.clinicIsSelected(item);
      });
      this.labelClinic = 'Todas as clínicas';
    } else {
      this.selectedClinic = [];
      this.labelClinic = 'Todas as clínicas';
    }
    let idsMap = this.selectedClinic.map((item) => {
      return item.value;
    });
    let idsStr = idsMap.join(',');
    if (this.listTemplate.onClinicFocusOut) this.listTemplate.onClinicFocusOut(idsStr);
  }

  toggleClinic(clinic: any) {
    if (this.clinicIsSelected(clinic)) {
      this.selectedClinic = this.selectedClinic.filter(value => clinic.value != value.value);
    } else {
      this.selectedClinic.push(clinic);
    }
    let valueMap = this.selectedClinic.map((item) => {
      return item.value;
    });
    let valueStr = valueMap.join(',');
    if (this.listTemplate.onClinicFocusOut) this.listTemplate.onClinicFocusOut(valueStr);
    let nameMap = this.selectedClinic.map((item) => {
      return item.name;
    });

    if (nameMap.length == 0) {
      this.labelClinic = 'Todas as clínicas';
    } else if (nameMap.length == this.clinicList.length) {
      this.labelClinic = 'Todas as clínicas';
    } else {
      this.labelClinic = nameMap.join(', ');
    }
  }

  clinicIsSelected(clinic: any) {
    return this.selectedClinic.some(value => clinic.name == value.name);
  }

  exportCSV() {
    var modal = this.modalService.open(ExportModalComponent, { centered: true });
    modal.componentInstance.fields = this.listTemplate.exportOptions;
    modal.componentInstance.url = this.listTemplate.exportUrl;
  }

  ngAfterViewInit() {
    if (this.router.url.split('/')[2] == 'charts') {
      let today = moment().format('YYYY-MM-DD');
      this.renderer.setAttribute(this.date.nativeElement, 'value', today);
    }
    if (this.listTemplate.showSearch && this.listTemplate.searchBarPlaceholder) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap((text) => {
            if (this.listTemplate.onSearchFocusOut) {
              this.page = 1;
              this.listTemplate.onSearchFocusOut(this.input.nativeElement.value); //valor do input
            }
          })
        )
        .subscribe();
    }
  }

  getWidth(index: number): string {
    if (this.mediaQuery) {
      return this.listTemplate.header![index].widthInPercentage ?? '10%';
    } else {
      return this.listTemplate.header![index].width ?? '50px';
    }
  }

  getKeyByValue(columnIndex: number, object: any, value: string): string | undefined {
    if (this.listTemplate.header![columnIndex].displayStringConvertionFunction)
      return this.listTemplate.header![columnIndex].displayStringConvertionFunction!(object[value] ?? '-');
    else
      return object[value] ?? '-';
  }

  getFirstPhoneNumber(columnIndex: number, object: any, value: string, format = true): string {
    if (object[value][0]) {
      if (this.listTemplate.header![columnIndex].displayStringConvertionFunction && format) {
        if (object["phone_numbers"][0]['country_code'] == "+55") {
          return `${object["phone_numbers"][0]['country_code']} ${this.listTemplate.header![columnIndex].displayStringConvertionFunction!(object[value][0]['phone_number'])}`;
        } else {
          return `${object["phone_numbers"][0]['country_code']} ${object[value][0]['phone_number']}`;
        }
      } else {
        return `${object[value][0]['country_code']}${object[value][0]['phone_number']}`;
      }
    } else return " - ";
  }

  getPhoneNumberPatientFormatter(object: any) {
    let phone_number = object['patient__patientphonenumber__phone_number'] ?? object['patientphonenumber'];
    if (phone_number) {
      return phoneNumberStringFormatter(phone_number);
    } else return '-';
  }

  getPhoneNumberPatient(columnIndex: number, object: any, value: string) {
    return object['patient__patientphonenumber__phone_number'] ?? object['patientphonenumber'];
  }

  isPhoneNumberWhatsapp(object: any, value: string): boolean {
    if (object[value][0]) {
      if (object[value][0]['type'] == "WHATSAPP") return true;
      else return false;
    } else return false;
  }

  whatsappButtonClickHandle(phoneNumber: string) {
    window.open(`//wa.me/${phoneNumber}`, "_blank")
  }

  rowClickHandle(event: Event, index: number) {
    const element = event.target! as HTMLElement;
    if (this.listTemplate.clickRowClickHandle || this.isSuperUserOrStaff) {
      if (element.nodeName === "BUTTON") {

      } else if (index != undefined) {
        this.router.navigate(['edit', index], { relativeTo: this.activatedRoute })
      }
    }
  }

  getColorPaid(obj: any) {
    if(!obj.invoice_url) {
      return {'color': '#707070 !important'};
    }
    if (obj.payment_status == 'APPROVED') {
      return { 'color': '#5CBC79 !important' };
    }
    return { 'color': '#F48989 !important' };
  }

  abrirFaturaAsaas(obj: any) {
    if(obj.invoice_url) {
      window.open(obj.invoice_url, "_blank");
    }
  }

  getDate(index: number, obj: any, name: any) {
    if (obj[name]) {
      return moment(obj[name]).format('DD/MM/YYYY HH:mm');
    } else return '-';
  }

  getDateSentAt(index: number, obj: any, name: any) {
    if (obj[name]) {
      return moment(obj[name]).format('DD/MM/YYYY');
    } else return '-';
  }

  getExamNights(obj: any) {
    return `${obj.exam_nights} (${obj.valid_nights})`;
  }

  getStatusOximeter(object: any) {
    if (object.status == 'AVAILABLE') {
      return 'Disponível';
    } else if (object.status == 'OCCUPIED') {
      return 'Ocupado';
    } else return '-';
  }

  getStatusExam(object: any) {
    return this.statusExamList.find(item => item.value == object.status)?.name ?? '-';
  }

  searchAdvanced() {
    this.searchAdvancedBool = !this.searchAdvancedBool;
  }

  getClinicName(index: number) {
    if (this.listTemplate.data!.results![index].type == "COMPANY") {
      return this.listTemplate.data!.results![index].display_name;
    } else {
      return this.listTemplate.data!.results![index].name;
    }
  }

  getPatientName(index: number) {
    if (this.listTemplate.data!.results![index].patient__name == '') {
      return this.listTemplate.data!.results![index].patient_document_number + ' (não cadastrado)';
    } else {
      return this.listTemplate.data!.results![index].patient__name;
    }
  }

  onHeaderClick(orderBy: string) {
    this.listTemplate.onHeaderItemClick!(orderBy);
  }

  get hasNext() {
    return this.listTemplate.hasNext;
  }

  nextPage() {
    this.onNextPage.emit(++this.page);
  }

  toggleShareDropdown(ev: any, item: any) {
    this.shareDropdownX = ev.x;
    this.shareDropdownY = ev.y;
    this.shareIsOpened = !this.shareIsOpened;
    this.phoneNumber = item.patient__patientphonenumber__phone_number ?? null;
    this.invoiceUrl = item.invoice_url;
    ev.stopPropagation();
  }

  copyToClipboard() {
    if(this.invoiceUrl) {
      this.clipboard.copy(this.invoiceUrl);
      this.toast.success("Link copiado com sucesso", "Sucesso");
    }
  }

  sendInvoiceUrlViaWhatsapp() {
    let textSendInvoice = `Aqui está a cobrança da sua Polissonografia.\nAcesse o link abaixo e escolha sua forma de pagamento.\n${this.invoiceUrl}`;
    if (this.invoiceUrl && this.phoneNumber) {
      window.open(`//wa.me/${this.phoneNumber}?text=${encodeURI(textSendInvoice)}`, "_blank");
    }
  }

  emitDue(item: any) {
    this.oximeterService.polysomnographyResendInvoice(item.id, this.currentClinicId).subscribe((response: any) => {
      this.toast.success("Fatura gerada com sucesso", "Sucesso");
      item.invoice_url = response.invoice_url;
    }, (error: HttpErrorResponse) => {
      this.toast.error(error.error['detail'], "Erro");
    });
  }
}
