import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormRendererComponent } from 'src/app/shared/renderers/form-renderer/form-renderer.component';
import { inputType } from 'src/app/shared/renderers/models/form-input';
import { FormTemplate } from 'src/app/shared/renderers/models/form-template';

@Component({
  selector: 'app-patient-edit-image-database',
  templateUrl: './patient-edit-image-database.component.html',
  styleUrls: ['./patient-edit-image-database.component.scss']
})
export class PatientEditImageDatabaseComponent implements OnInit {
  formTemplate: FormTemplate = {
    groupName: "patientRegisterForm",
    submitButton: { label: "Salvar" },
    cancelButton: { label: "Cancelar" },
    separators: [
      {
        groupName: "imageDb",
        title: "Criar novo banco de imagens",
        inputs: [
          { name: "dbName", label: "Nome do banco de imagens", type: inputType.BASIC, widthInCols: { sm: 9 }, validators: [Validators.minLength(5)] },
        ]
      },
    ]
  };

  @ViewChild(FormRendererComponent) form!: FormRendererComponent;

  constructor() { }

  ngOnInit(): void {
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.form.formExitHandler();
  };
}
