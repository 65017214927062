import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserGroupsListComponent } from './user-groups-list/user-groups-list.component';
import { UserGroupRegisterComponent } from './user-group-register/user-group-register.component';
import { UserGroupEditComponent } from './user-group-edit/user-group-edit.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';

@NgModule({
  declarations: [
    UserGroupsListComponent,
    UserGroupRegisterComponent,
    UserGroupEditComponent
  ],
  imports: [
    CommonModule,
    RenderersModule
  ]
})
export class UserGroupsModule { }
