import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { PhoneModel } from 'src/app/core/models/phone.model';
import { ProfessionalModel } from 'src/app/core/models/professional.model';
import { ClinicsService } from 'src/app/core/services/clinics.service';
import { ProfessionalsService } from 'src/app/core/services/professionals.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/modals/confirmation-modal/confirmation-modal.component';
import { Options } from 'src/app/shared/components/select-input/models/Options';
import { ErrorModalComponent } from 'src/app/shared/renderers/components/error-modal/error-modal.component';
import { FieldValidator } from 'src/app/shared/renderers/utils/field-validator/field-validator';

@Component({
  selector: 'app-professionals-form',
  templateUrl: './professionals-form.component.html',
  styleUrls: ['./professionals-form.component.scss']
})
export class ProfessionalsFormComponent implements OnInit {

  formGroup = this.fb.group({
    id: [],
    name: [null],
    display_name: [],
    gender: [],
    birthdate: [null],
    email: ['', [Validators.email]],
    document_number: ['', [FieldValidator.documentValidator()]],
    cro: [],
    clinics: [],
    tags: [],
    phone_numbers: this.fb.array([]),
    specialties: []
  });

  constructor(@Inject(DOCUMENT) private document: Document, private activatedRoute: ActivatedRoute, private fb: FormBuilder,
    private router: Router, config: NgbModalConfig, private modalService: NgbModal, private clinicsService: ClinicsService,
    private professionalService: ProfessionalsService, private sessionManager: SessionManagerService, private toast: ToastrService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }


  imageFile!: File | null;
  image!: string;
  changedImage: boolean = false;

  loadingAccount: boolean = false;
  isRegister: boolean = true;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton: String = "Desativar";
  submitButton: String = "Salvar";
  button: boolean = false;

  connect: boolean = false;

  phonesList!: PhoneModel[];
  clinicList: Options[] = [];
  fetchingClinic: boolean = false;
  professionalTagsList!: Options[];
  fetchingTags: boolean = false;
  specialtiesList!: Options[];
  fetchingSpecialties: boolean = false;

  id!: number;
  existingProfessionalData!: ProfessionalModel;
  fetchingProfessional: boolean = false;

  phoneTypeList: Options[] = [
    { label: "Whatsapp", value: "WHATSAPP" },
    { label: "Comercial", value: "COMERCIAL" },
    { label: "Residencial", value: "RESIDENTIAL" },
  ];

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params["id"];
      if (this.id) {
        this.isRegister = false;
      }
    });
    this.initializeForm();
  }

  initializeForm() {
    this.fetchProfessionalTags();
    this.fetchClinicsList();
    this.fetchSpecialties();
    this.addPhoneNumber();
    if (this.id) setTimeout(() => this.fetchProfessionalsExistingData(), 1000);
  }

  get fetchingData(): boolean {
    if (this.fetchingClinic && this.fetchingSpecialties && this.fetchingTags) {
      if (this.isRegister) {
        return true;
      } else {
        if (this.fetchingProfessional) return true;
      }
    }
    return false;
  }

  get canSave() {
    if (this.isRegister) {
      return this.sessionManager.getUserPermission(PermissionConst.add_account);
    } else {
      if (this.existingProfessionalData && (this.existingProfessionalData.account?.is_staff || this.existingProfessionalData.account?.is_superuser)) {
        return this.sessionManager.isSuperUserOrStaff();
      } else {
        return this.sessionManager.getUserPermission(PermissionConst.change_account);
      }
    }
  }

  get currentProfessional() {
    return this.sessionManager.accountData.professional_id == this.id;
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_professional);
  }

  get currentClinic() {
    if(!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent();
    }
    return '';
  }

  get currentClinicId() {
    if(!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent().value;
    }
    return '';
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  searchAccount(document_number: any) {
    this.loadingAccount = true;
    this.professionalService.searchAll(document_number).subscribe(response => {
      if (response) {
        this.setForm(response);
      }
      this.loadingAccount = false;
    }, (error) => {
      this.loadingAccount = false;
    });
  }

  setForm(data: any) {
    if (data.account) {
      if (this.isSuperUserOrStaff && data.account.is_professional) {
        this.router.navigate([`dashboard/registers/professionals/edit/${data.id}`]);
      } else {
        this.formGroup.patchValue(data.account);
      }
      if (!this.isSuperUserOrStaff) {
        let connectClinic = data.clinics.find((item: any) => item == this.currentClinicId);
        if ((connectClinic != undefined) && data.account.is_professional) {
          this.router.navigate([`dashboard/registers/professionals/edit/${data.id}`]);
        }
        if (!data.account.is_professional) {
          this.formGroup.patchValue(data.account);
        } else {
          if (data.account.is_staff || data.account.is_superuser) {
            this.formGroup.disable();
            this.formGroup.get('document_number')?.enable();
          }
          this.formGroup.patchValue(data);
          this.connect = true;
          this.submitButton = "Conectar";
        }
      }
    } else {
      if (this.isSuperUserOrStaff && data.is_professional) {
        this.router.navigate([`dashboard/registers/professionals/edit/${data.id}`]);
      } else {
        this.formGroup.patchValue(data);
      }
      if (!this.isSuperUserOrStaff) {
        let connectClinic = data.clinics.find((item: any) => item == this.currentClinicId);
        if ((connectClinic != undefined) && data.is_professional) {
          this.router.navigate([`dashboard/registers/professionals/edit/${data.id}`]);
        }
        if (!data.is_professional) {
          this.formGroup.patchValue(data);
        } else {
          if (data.is_staff || data.is_superuser) {
            this.formGroup.disable();
            this.formGroup.get('document_number')?.enable();
          }
          this.formGroup.patchValue(data);
          this.connect = true;
          this.submitButton = "Conectar";
        }
      }
    }
  }

  cancelHandler() {
    this.router.navigate(['dashboard/registers/professionals']);
  }

  revokeProfessional(clinicId: any) {
    if (this.isRegister) {
      let message = 'Removendo essa clínica o profissional não será criado.';
      const modalRef = this.modalService.open(ErrorModalComponent, { centered: true });
      modalRef.componentInstance.text = message;
      modalRef.result.then((result) => { return });
    } else {
      let message = 'Tem certeza que deseja remover esse profissional dessa clínica?';
      const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.text = message;
      modalRef.result.then((result) => {
        if (result == true) {
          this.professionalService.revoke(clinicId, this.id).subscribe(() => {
            this.toast.success('Clínica removida de profissional com sucesso', 'Sucesso');

            window.location.reload();

          }, (errorResponse: HttpErrorResponse) => {
            this.mapErrorResponse(errorResponse);
          });
        }
      });
    }
  }

  fetchProfessionalTags() {
    this.professionalService.listAllTagProfessional(this.currentClinicId).subscribe((response) => {
      this.professionalTagsList = response.map(items => {
        return {
          value: items.id!.toString(),
          label: items.name
        } as Options;
      });
      this.fetchingTags = true;
    });
  }

  fetchSpecialties() {
    this.professionalService.listAllSpecialties(this.currentClinicId).subscribe(response => {
      this.specialtiesList = response.map(items => {
        return {
          value: items.id!.toString(),
          label: items.name
        } as Options;
      });
      this.fetchingSpecialties = true;
    });
  }

  fetchClinicsList() {
    const fnResponse = (response: any) => {
      let clinicsList = response!.map((item: any) => {
        return {
          value: item.id!.toString(),
          label: item.name
        } as Options;
      });
      let currentClinic = clinicsList.find((item: any) => item.value == this.currentClinicId);

      if (!this.isSuperUserOrStaff && this.isRegister) {
        this.clinicList.push(currentClinic);
      } else {
        this.clinicList = clinicsList;
      }
      this.fetchingClinic = true;
    };
    if (this.isSuperUserOrStaff) {
      this.clinicsService.getAll().subscribe(fnResponse);
    } else {
      this.clinicsService.userClinicGetAll(this.sessionManager.clinicsListId.toString()).subscribe(fnResponse);
    }
  }

  fetchProfessionalsExistingData() {
    const fnResponse = (response: any) => {
      this.existingProfessionalData = response;
      this.phoneNumbersForms.clear();
      response.phone_numbers!.forEach(() => this.addPhoneNumber());
      this.formGroup.patchValue(this.existingProfessionalData);
      if (this.existingProfessionalData.is_active) {
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
        this.button = true;
      } else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
        this.phoneNumbersForms.disable();
      }
      this.image = response.account?.profile_image ?? '';
      if (this.fetchingClinic && this.fetchingSpecialties && this.fetchingTags) {
        this.fetchingProfessional = true;
      }
      this.formGroup.get('document_number')?.disable();
      if (!this.isSuperUserOrStaff) {
        this.removeButton = "Revogar";
        if (!this.currentProfessional) {
          this.formGroup.disable();
        }
        this.clinicList = this.clinicList.filter(item => (this.existingProfessionalData.clinics)?.some(clinicId => clinicId == item.value));
      }
    };

    const fnError = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.cancelHandler();
    };

    if (this.isSuperUserOrStaff) {
      this.professionalService.get(this.id).subscribe(fnResponse, fnError);
    } else {
      this.professionalService.getClinicUser(this.currentClinicId, this.id).subscribe(fnResponse, fnError);
    }
  }

  onImageSelect(file: any) {
    this.imageFile = file;
    this.changedImage = true
  }

  removeHandler() {
    this.submittingRemove = true;
    if(!this.isSuperUserOrStaff) {
      this.revokeProfessional(this.currentClinicId);
    } else {
      const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.text = "Deseja desativar este profissional?";
      modalRef.result.then((result) => {
        if (result == true) {
          this.professionalService.remove(this.id).subscribe(() => {
            this.toast.success('Profissional removido com sucesso', "Sucesso");
            this.cancelHandler();
          }, (errorResponse: HttpErrorResponse) => {
            this.submittingRemove = false;
            this.mapErrorResponse(errorResponse);
          });
        } else {
          this.submittingRemove = false;
        }
      });
    }
  }

  submitHandler() {
    this.formGroup.markAllAsTouched();
    this.submitting = true;
    (this.phoneNumbersForms?.value as PhoneModel[]).forEach((phone, index) => {
      if (phone.id == null) this.phoneNumbersForms.at(index)?.get('id')?.setValue(undefined);
    });
    let tags = this.formGroup.get('tags')?.value;
    let specialties = this.formGroup.get('specialties')?.value;
    if (tags == null) {
      this.formGroup.get('tags')?.setValue([]);
    } else {
      tags = tags.map((item: any) => Number(item));
      this.formGroup.get('tags')?.setValue(tags);
    }
    if (specialties == null) {
      this.formGroup.get('specialties')?.setValue([]);
    } else {
      specialties = specialties.map((item: any) => Number(item));
      this.formGroup.get('specialties')?.setValue(specialties);
    }

    let professionalData = this.formGroup.getRawValue() as ProfessionalModel;
    if (this.isRegister) {
      if (this.connect && !this.isSuperUserOrStaff) {
        this.connectClinic();
      } else {
        this.professionalRegister(professionalData);
      }
    } else {
      this.professionalEdit(professionalData);
    }
  }

  connectClinic() {
    let professional_id = this.formGroup.get('id')?.value;
    this.professionalService.connect(this.currentClinicId, professional_id).subscribe(response => {
      if (this.changedImage) {
        this.professionalService.uploadImage(this.imageFile!, response.id!, this.currentClinicId).subscribe(() => { });
      }
      this.toast.success("Profissional conectado com sucesso", "Sucesso");
      this.cancelHandler();
      this.submitting = false;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  professionalEdit(professionalData: ProfessionalModel) {
    professionalData.is_active = true;
    const fnResponse = (response: ProfessionalModel) => {
      if (this.changedImage) {
        if (this.isSuperUserOrStaff) {
          this.professionalService.uploadImage(this.imageFile!, response.account!.id!).subscribe(() => { }, (errorResponse: HttpErrorResponse) => {
            this.mapErrorResponse(errorResponse);
            this.submitting = false;
          });
        } else {
          this.professionalService.uploadImage(this.imageFile!, response.account!.id!, this.currentClinicId).subscribe(() => { }, (errorResponse: HttpErrorResponse) => {
            this.mapErrorResponse(errorResponse);
            this.submitting = false;
          });
        }
      }
      this.submitting = false;
      this.toast.success('Profissional alterado com sucesso', "Sucesso");
      this.cancelHandler();
    };

    const fnError = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.toast.error('Verifique se todos os campos obrigatórios foram preenchidos corretamente.', 'Erro');
      this.submitting = false;
    };
    if (this.isSuperUserOrStaff) {
      this.professionalService.edit(professionalData).subscribe(fnResponse, fnError);
    } else {
      this.professionalService.editClinicUser(this.currentClinicId, professionalData).subscribe(fnResponse, fnError);
    }
  }

  professionalRegister(professionalData: ProfessionalModel) {
    professionalData.id = undefined;
    const fnResponse = (response: ProfessionalModel) => {
      if (this.changedImage) {
        if (this.isSuperUserOrStaff) {
          this.professionalService.uploadImage(this.imageFile!, response.account!.id!).subscribe(() => { }, (errorResponse: HttpErrorResponse) => {
            this.mapErrorResponse(errorResponse);
            this.submitting = false;
          });
        } else {
          this.professionalService.uploadImage(this.imageFile!, response.account!.id!, this.currentClinicId).subscribe(() => { }, (errorResponse: HttpErrorResponse) => {
            this.mapErrorResponse(errorResponse);
            this.submitting = false;
          });
        }
      }
      this.isRegister = false;
      this.submitting = false;
      this.toast.success('Profissional criado com sucesso', "Sucesso");
      this.cancelHandler();
    };

    const fnError = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.toast.error('Verifique se todos os campos obrigatórios foram preenchidos corretamente.', 'Erro');
      this.submitting = false;
    };

    if (this.isSuperUserOrStaff) {
      this.professionalService.register(professionalData).subscribe(fnResponse, fnError);
    } else {
      this.professionalService.registerClinicUser(this.currentClinicId, professionalData).subscribe(fnResponse, fnError);
    }
  }

  get phoneNumbersForms() {
    return this.formGroup.get('phone_numbers') as FormArray;
  }

  getPhoneNumberForm(index: number) {
    return this.phoneNumbersForms.controls[index] as FormGroup;
  }

  addPhoneNumber() {
    let phoneNumberFormGroup = this.fb.group({
      id: [],
      country_code: ['+55'],
      phone_number: [],
      type: ['WHATSAPP'],
      is_active: [true]
    });
    this.phoneNumbersForms.push(phoneNumberFormGroup);
  }

  removePhoneNumber(at: number) {
    let formGroup = this.getPhoneNumberForm(at);
    if (formGroup.get('id')?.value) {
      formGroup.patchValue({ is_active: false });
    } else {
      this.phoneNumbersForms.removeAt(at);
    }
  }

  phoneNumberIsActive(index: number) {
    return this.getPhoneNumberForm(index).get('is_active')?.value;
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    } else {
      this.setFormErrors(errorResponse);
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "id", "name", "display_name", "gender", "email", "birthdate", "document_number", "cro", "tags",
      "phone_numbers", "specialties", "clinics"
    ];

    let phoneErrNames = [
      "id", "country_code", "phone_number", "type"
    ];

    errNames.forEach(name => {
      if (errorResponse.error[name]) {
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
      }
    });

    if (errorResponse.error.phone_numbers) {
      let phone_numbers: [] = errorResponse.error.phone_numbers;
      Object.keys(phone_numbers).map((index) => {
        let formPhone = this.getPhoneNumberForm(Number(index)) as FormGroup;
        let errorResponsephone_numbers = phone_numbers[Number(index)];
        phoneErrNames.forEach(name => {
          formPhone.get(name)?.setErrors({ response: errorResponsephone_numbers[name] });
        });
      });
    }
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  };

}
