<div class="field file-uploader">
    <label class="inputLabel" [for]="id">{{label ? label : ""}}</label>

    <label for="uploader" class="uploader-wrapper" [ngClass]="disabled? 'uploader-wrapper__disabled' : 'null'">
        <div class="preview-wrapper">
            <img id="preview">
            <i class="icon-document" *ngIf="isDocument"></i>
        </div>
        <label>{{placeholder}}</label>
    </label>
    <input type="file" id="uploader" hidden>
</div>
