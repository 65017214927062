<div class="row p-0 m-0 header">
  <div class="col-12">
    <app-header title="Oxímetro" subTitle="Oxímetros"></app-header>
  </div>
</div>
<div class="row p-0 m-0 content">
  <div class="col-12">
    <router-outlet></router-outlet>
  </div>
</div>

<div *ngIf="showMenuSidebar" class="navbar-backdrop d-lg-none" (click)="menuButtonHandler()">
</div>
