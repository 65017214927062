import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oximeters',
  templateUrl: './oximeters.component.html',
  styleUrls: ['./oximeters.component.scss']
})
export class OximetersComponent implements OnInit {

  showMenuSidebar: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  menuButtonHandler() {
    this.showMenuSidebar = !this.showMenuSidebar;
  }

}
