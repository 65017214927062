import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ListModel } from '../models/list.model';
import { ProfessionModel } from '../models/profession.model';
import { SpecialtyModel } from '../models/specialty.model';
import { TagModel } from '../models/tag.model';
import {CouponModel} from "../models/coupon.model";

@Injectable({
  providedIn: 'root'
})
export class RegistersService {

  constructor(private httpClient: HttpClient) { }

  professionGet(clinicId: string = '', professionId: number): Observable<ProfessionModel> {
    return this.httpClient.get<ProfessionModel>(`${environment.API_URL}/professions/${professionId}/?clinic_id=${clinicId}`)
  }

  professionRegister(clinicId: string = '', professionData: ProfessionModel): Observable<ProfessionModel> {
    return this.httpClient.post<ProfessionModel>(`${environment.API_URL}/professions/?clinic_id=${clinicId}`, JSON.stringify(professionData))
  }

  professionEdit(clinicId: string = '', professionData: ProfessionModel): Observable<ProfessionModel> {
    return this.httpClient.patch<ProfessionModel>(`${environment.API_URL}/professions/${professionData.id}/?clinic_id=${clinicId}`, JSON.stringify(professionData))
  }

  professionRemove(clinicId: string = '', professionId: number) {
    return this.httpClient.delete(`${environment.API_URL}/professions/${professionId}/?clinic_id=${clinicId}`)
  }

  professionsList(clinicId: string = '', searchString: string = "", orderingItem: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/professions/?clinic_id=${clinicId}&search=${searchString}&ordering=${orderingItem}&is_active=${isActive}&page=${page}`);
  }

  professionsAll(clinicId: string = ''): Observable<ProfessionModel[]> {
    return this.httpClient.get<ProfessionModel[]>(`${environment.API_URL}/professions/all/?clinic_id=${clinicId}&ordering=name&is_active=true`);
  }

  tagGet(clinicId: string = '', tagId: number, tagType: string): Observable<TagModel> {
    return this.httpClient.get<TagModel>(`${environment.API_URL}/tags/${tagType}/${tagId}/?clinic_id=${clinicId}`)
  }

  tagRegister(clinicId: string = '', tagData: TagModel): Observable<TagModel> {
    return this.httpClient.post<TagModel>(`${environment.API_URL}/tags/${tagData.type}/?clinic_id=${clinicId}`, JSON.stringify(tagData))
  }

  tagEdit(clinicId: string = '', tagData: TagModel): Observable<TagModel> {
    return this.httpClient.patch<TagModel>(`${environment.API_URL}/tags/${tagData.type}/${tagData.id}/?clinic_id=${clinicId}`, JSON.stringify(tagData))
  }

  tagRemove(clinicId: string = '', tagId: number, tagType: string) {
    return this.httpClient.delete(`${environment.API_URL}/tags/${tagType}/${tagId}/?clinic_id=${clinicId}`)
  }

  tagsList(tagType: string, clinicId: string = "", searchString: string = "", orderingItem: string = "", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/tags/${tagType}/?clinic_id=${clinicId}&search=${searchString}&ordering=${orderingItem}&is_active=${isActive}&page=${page}`);
  }

  tagGetAll(clinicId: string = "", tagType: string): Observable<TagModel[]> {
    return this.httpClient.get<TagModel[]>(`${environment.API_URL}/tags/${tagType}/all/?clinic_id=${clinicId}&ordering=name&is_active=true`);
  }

  specialtyGet(clinicId: string = "", specialtyId: number): Observable<SpecialtyModel> {
    return this.httpClient.get<SpecialtyModel>(`${environment.API_URL}/specialties/${specialtyId}/?clinic_id=${clinicId}`)
  }

  specialtyRegister(clinicId: string = "", specialtyData: SpecialtyModel): Observable<SpecialtyModel> {
    return this.httpClient.post<SpecialtyModel>(`${environment.API_URL}/specialties/?clinic_id=${clinicId}`, JSON.stringify(specialtyData))
  }

  specialtyEdit(clinicId: string = "", specialtyData: SpecialtyModel): Observable<SpecialtyModel> {
    return this.httpClient.patch<SpecialtyModel>(`${environment.API_URL}/specialties/${specialtyData.id}/?clinic_id=${clinicId}`, JSON.stringify(specialtyData))
  }

  specialtyRemove(clinicId: string = "", specialtyId: number) {
    return this.httpClient.delete(`${environment.API_URL}/specialties/${specialtyId}/?clinic_id=${clinicId}`)
  }

  specialtiesList(clinicId: string = "", searchString: string = "", orderingParam: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/specialties/?clinic_id=${clinicId}&search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  specialtyAll(clinicId: string = ""): Observable<SpecialtyModel[]> {
    return this.httpClient.get<SpecialtyModel[]>(`${environment.API_URL}/specialties/all/?clinic_id=${clinicId}&ordering=name&is_active=true`);
  }

  examsGet(examId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.API_URL}/exams/${examId}`);
  }

  examsRegister(examsData: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.API_URL}/exams/`, JSON.stringify(examsData))
  }

  examRemove(examId: number) {
    return this.httpClient.delete(`${environment.API_URL}/exams/${examId}/`);
  }

  examsList(searchString: string = '', orderingParam: string = 'name', isActive: boolean = true): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/exams/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}`);
  }

  couponsList(searchString: string = '', orderingParam: string = 'name', isActive: boolean = true): Observable<ListModel> {
      return this.httpClient.get<ListModel>(`${environment.API_URL}/coupons/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}`);
  }

  couponsRegister(data: CouponModel): Observable<CouponModel> {
    return this.httpClient.post<CouponModel>(`${environment.API_URL}/coupons/`, data);
  }

  couponGet(couponId: number): Observable<CouponModel> {
    return this.httpClient.get<CouponModel>(`${environment.API_URL}/coupons/${couponId}`)
  }

  couponEdit(couponId: number, couponData: CouponModel): Observable<CouponModel> {
    return this.httpClient.patch<CouponModel>(`${environment.API_URL}/coupons/${couponId}/`, couponData)
  }

  couponRemove(couponId: number): Observable<any> {
    return this.httpClient.delete<any>(`${environment.API_URL}/coupons/${couponId}`)
  }
}
