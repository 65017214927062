<div id="passwordRecovery">
    <div class="container">
        <div class="row">
            <div class="col logo-bar">
                <img src="/assets/chegaderonco-color.svg" alt="Chega de Ronco">
            </div>
        </div>

        <div class="row">
            <div class="offset-sm-1 offset-md-2 offset-lg-3 col-12 col-sm-10 col-md-8 col-lg-6">
                <div class="card">
                    <form *ngIf="!showConfirm" [formGroup]="formGroup" (submit)="onSubmit($event)"
                        class="form">
                        <div class="mb-4">
                            <label for="document_number" class="form__label">CPF:</label>
                            <input type="text" class="form-control form__input" mask="000.000.000-00"
                            formControlName="document_number" id="document_number">
                        </div>

                        <div class="row">
                            <div class="offset-md-3 col-12 col-md-6 mb-3">
                                <button class="btn btn-primary btn-block" type="submit" [disabled]="loading">
                                    Enviar
                                    <span *ngIf="loading" class="spinner-border" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 text-center">
                                <button (click)="loginLinkClickHandler()" class="btn btn-link">Fazer login</button>
                            </div>
                        </div>
                    </form>

                    <div class="confirm" *ngIf="showConfirm">
                        <h2 class="mb-5">Recuperação de senha</h2>
                        <h3 class="mb-3">Uma mensagem foi enviada para o email {{email}}</h3>
                        <h3 class="obs">Obs: Verifique também sua caixa de spam</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
