import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Pipe } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { OximeterService } from 'src/app/core/services/oximeter.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ListTemplate } from 'src/app/shared/renderers/models/list-template';

@Component({
  selector: 'app-exam-list',
  templateUrl: './exam-list.component.html',
  styleUrls: ['./exam-list.component.scss']
})
export class ExamListComponent implements OnInit {

  searchString: string = "";
  orderingItem: string = "-attendance__date_time";
  statusString: string = "";

  dateSince: string = "";
  dateUntil: string = "";

  request?: Subscription;

  get clinicId() {
    if (this.isSuperUserOrStaff) {
      return '';
    }
    let currentClinic = this.sessionManager.getClinicCurrent()?.value;
    if (currentClinic) {
      return currentClinic;
    } else {
      return this.sessionManager.accountData.clinic;
    }
  }

  clinicIdList: string = "";

  get canNewData() {
    const permissions = [PermissionConst.add_polysomnography];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  get canExport() {
    const permissions = [PermissionConst.export_polysomnography];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: true,
    searchBarPlaceholder: "Buscar por Paciente",
    showAdvancedSearch: false,
    searchBarStatus: true,
    searchBarDateRange: true,
    newDataText: "+ Novo Exame",
    newDataRouterLink: this.canNewData ? "register" : undefined,
    notFoundMessage: "Nenhum exame encontrado",
    clickRowClickHandle: true,
    header: [
      { name: "devolution_date", label: "Devolução", widthInPercentage: "10%", width: "100px" },
      { name: "patient__account__name", label: "Paciente", widthInPercentage: "25%", width: "300px" },
      { name: "patient__patientphonenumber__phone_number", label: "Telefone", widthInPercentage: "16%", width: "200px" },
      { name: "exam_nights", label: "Noites", widthInPercentage: "8%", width: "50px" },
      { name: "status_exam", label: "Status", widthInPercentage: "10%", width: "140px" },
      { name: "payment_status", label: "", widthInPercentage: "4%", width: "140px" },
      { name: "invoice_url", label: "", widthInPercentage: "6%", width: "140px" },
      { name: "sent_at", label: "Envio", widthInPercentage: "9%", width: "100px" },
      { name: "professional__name", label: "Profissional", widthInPercentage: "12%", width: "140px" },
    ],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      this.listTemplate.selectedOrdering = value;

      if (value == "status_exam") {
        value = "status";
      }

      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(this.searchString, `${invertString}${value}`, clinicId, this.statusString, this.listTemplate.is_active, this.dateSince, this.dateUntil);
      this.orderingItem = value;
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(value, this.orderingItem, clinicId, this.statusString, this.listTemplate.is_active, this.dateSince, this.dateUntil);
    },
    onStatusFocusOut: (value: string) => {
      this.statusString = value;
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(this.searchString, this.orderingItem, clinicId, this.statusString, this.listTemplate.is_active, this.dateSince, this.dateUntil);
    },
    onClinicFocusOut: (value: string) => {
      this.clinicIdList = value;
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(this.searchString, this.orderingItem, clinicId, this.statusString, this.listTemplate.is_active, this.dateSince, this.dateUntil);
    },
    onDateRangeFocusOut: (value: any) => {
      this.dateSince = value.start;
      this.dateUntil = value.end;
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(this.searchString, this.orderingItem, clinicId, this.statusString, this.listTemplate.is_active, this.dateSince, this.dateUntil);
    },
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
    exportUrl: undefined,
  };

  constructor(private oximeterService: OximeterService, private sessionManager: SessionManagerService,
    private titleService: Title) {
    this.titleService.setTitle('Chega de Ronco - Exames');
  }

  ngOnInit(): void {
    this.initializeList();
  }

  initializeList() {
    this.fetchList(this.searchString, this.orderingItem, this.clinicId, this.statusString, this.listTemplate.is_active, this.dateSince, this.dateUntil);
  }

  fetchList(searchString: string, orderingItem: string, clinicId: string, status: string, isActive: boolean | undefined, dateSince: string, dateUntil: string) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;
    if (this.request) {
      this.request.unsubscribe();
    }
    this.request = this.oximeterService.polysomnographyList(searchString, orderingItem, clinicId, status, isActive, dateSince, dateUntil)
      .subscribe(response => {
      this.listTemplate.loading = false;
      this.listTemplate.data = response;
      this.listTemplate.hasNext = response.next != null;
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = response.error['detail'];
    });
  }

  loadMore(page: number) {
    this.listTemplate.loadingNext = true;
    let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;

    this.oximeterService.polysomnographyList(this.searchString, this.orderingItem, clinicId, this.statusString, this.listTemplate.is_active, this.dateSince, this.dateUntil, page).subscribe(
      response => {
        this.listTemplate.hasNext = response.next != null;
        response.results!.map(item => this.listTemplate.data!.results!.push(item));
        this.listTemplate.loadingNext = false;
      },
      error => {
        console.error(error);
        this.listTemplate.loadingNext = false;
      }
    );
  }
}
