import { Component, OnInit } from '@angular/core';
import {ListTemplate} from "../../../../../shared/renderers/models/list-template";
import {PermissionConst} from "../../../../../core/models/permission-const.model";
import {SessionManagerService} from "../../../../../core/services/session-manager.service";
import {RegistersService} from "../../../../../core/services/registers.service";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-coupons-list',
  templateUrl: './coupons-list.component.html',
  styleUrls: ['./coupons-list.component.scss']
})
export class CouponsListComponent implements OnInit {

  searchString: string = "";
  orderingItem: string = "created_at";

  listTemplate: ListTemplate = {
    showSearch: true,
    searchBarPlaceholder: "Buscar por Nome",
    showAdvancedSearch: false,
    newDataText: "+ Novo Cupon",
    newDataRouterLink: this.canNewData  ? "register" : undefined,
    notFoundMessage: "Nenhum Cupon encontrada",
    // clickRowClickHandle: this.isSuperUserOrStaff,
    header: [
      { name: "name", label: "Nome", widthInPercentage: "35%", width: "100px" },
      { name: "exams_nights", label: "Noites de exame", widthInPercentage: "35%", width: "100px"},
      { name: "quiz_type", label: "Tipo de Questionário", widthInPercentage: "30%", width: "100px", displayStringConvertionFunction: this.quizTypeDisplay},
    ],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      this.fetchList(this.searchString, `${invertString}${value}`, this.listTemplate.is_active);
      this.orderingItem = value;
      this.listTemplate.selectedOrdering = value;
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      this.fetchList(value, this.orderingItem, this.listTemplate.is_active);
    },
    onClickActive: () => {
      this.listTemplate.is_active = !this.listTemplate.is_active;
      this.fetchList(this.searchString, this.orderingItem, this.listTemplate.is_active);
    },
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
  };

  constructor(private sessionManager: SessionManagerService, private registersService: RegistersService) { }

  ngOnInit(): void {
    this.initializeList();
  }

  get canNewData() {
    const permissions = [PermissionConst.add_coupon];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  initializeList() {
    this.fetchList("", "name", this.listTemplate.is_active);
  }

  quizTypeDisplay(quizType: string): string {
    let display = 'Questionário Completo'
    if (quizType == 'QUIZ_WITHOUT_IMAGES') {
      display = 'Questionário sem Imagens'
    } else if (quizType == 'SHORT_QUIZ') {
      display = 'Questionário Resumido'
    }
    return display
  }

  fetchList(searchString: string, orderingItem: string, isActive: boolean | undefined) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;

    this.registersService.couponsList(searchString, orderingItem, isActive).subscribe(response => {
      this.listTemplate.loading = false;
      this.listTemplate.data = response;
      this.listTemplate.hasNext = response.next != null;
    }, (error) => {
      const _response = error as HttpErrorResponse;
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = _response.error['detail'];
    })
  }

  loadMore(page: number) {

  }

}
