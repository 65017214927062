import { Component, OnInit } from '@angular/core';
import {Platform} from "@angular/cdk/platform";
import {Router} from "@angular/router";

@Component({
  selector: 'app-redirect-to-store',
  templateUrl: './redirect-to-store.component.html',
  styleUrls: ['./redirect-to-store.component.scss']
})
export class RedirectToStoreComponent implements OnInit {

  constructor(public platform: Platform, private router: Router) {
    if (this.platform.ANDROID) {
      location.href = 'https://play.google.com/store/apps/details?id=br.com.chegaderonco.app';
    } else if (this.platform.IOS) {
      location.href = 'https://apps.apple.com/br/app/chega-de-ronco/id1672047028';
    }
  }

  ngOnInit(): void {  }

}
