import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClinicModel } from '../models/clinic.model';
import { ListModel } from '../models/list.model';
import { ProfessionalModel } from '../models/professional.model';
import { TagModel } from '../models/tag.model';

@Injectable({
  providedIn: 'root'
})
export class ClinicsService {
  constructor(private httpClient: HttpClient) { }

  // SUPERUSER/STAFF

  register(clinicData: ClinicModel): Observable<ClinicModel> {
    return this.httpClient.post<ClinicModel>(`${environment.API_URL}/clinics/`, JSON.stringify(clinicData));
  }

  edit(clinicData: ClinicModel): Observable<ClinicModel> {
    return this.httpClient.patch<ClinicModel>(`${environment.API_URL}/clinics/${clinicData.id}/`, JSON.stringify(clinicData));
  }

  remove(clinicId: number): Observable<ClinicModel> {
    return this.httpClient.delete<ClinicModel>(`${environment.API_URL}/clinics/${clinicId}/`);
  }

  list(searchString: string = "", orderingParam: string = "", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/clinics/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  getAll(clinicId: string = ''): Observable<ClinicModel[]> {
    return this.httpClient.get<ClinicModel[]>(`${environment.API_URL}/clinics/all/?ordering=name&is_active=true&clinic_id=${clinicId}`);
  }

  getOne(clinicId: number): Observable<ClinicModel> {
    return this.httpClient.get<ClinicModel>(`${environment.API_URL}/clinics/${clinicId}/`);
  }

  professionalRegister(professionalData: ProfessionalModel): Observable<ProfessionalModel> {
    return this.httpClient.post<ProfessionalModel>(`${environment.API_URL}/professionals/`, JSON.stringify(professionalData));
  }

  professionalEdit(professionalData: ProfessionalModel): Observable<ProfessionalModel> {
    return this.httpClient.patch<ProfessionalModel>(`${environment.API_URL}/professionals/${professionalData.id}/`, JSON.stringify(professionalData));
  }

  professionalRemove(clinicId: number): Observable<ProfessionalModel> {
    return this.httpClient.delete<ProfessionalModel>(`${environment.API_URL}/professionals/${clinicId}/`);
  }

  professionalsList(searchString: string = "", orderingParam: string = "", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/professionals/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  professionalsGetAll(isActive: boolean = true): Observable<ProfessionalModel[]> {
    return this.httpClient.get<ProfessionalModel[]>(`${environment.API_URL}/professionals/all/?is_active=${isActive}`);
  }

  professionalGet(professionlId: number): Observable<ProfessionalModel> {
    return this.httpClient.get<ProfessionalModel>(`${environment.API_URL}/professionals/${professionlId}/`);
  }

  professionalSearchAll(document_number: number): Observable<ProfessionalModel> {
    return this.httpClient.get<ProfessionalModel>(`${environment.API_URL}/search_professional/?document_number=${document_number}`);
  }

  professionalSearch(clinicId: number, document_number: number): Observable<ProfessionalModel> {
    return this.httpClient.get<ProfessionalModel>(`${environment.API_URL}/professionals/search/?clinic_id=${clinicId}&document_number=${document_number}`);
  }

  uploadProfessionalImage(imageFile: File | undefined, professionalId: number): Observable<object> {
    const formData = new FormData();
    formData.append("profile_image", imageFile ? imageFile : '');

    return this.httpClient.patch<object>(`${environment.API_URL}/professionals/${professionalId}/profile_image/`, formData);
  }

  uploadClinicImage(imageFile: File | undefined, clinicId: number, currentClinicId = ''): Observable<object> {
    const formData = new FormData();
    formData.append("profile_image", imageFile ? imageFile : '');

    return this.httpClient.patch<object>(`${environment.API_URL}/clinics/${clinicId}/profile_image/?clinic_id=${currentClinicId}`, formData);
  }

  listAllTagClinic(): Observable<TagModel[]> {
    return this.httpClient.get<TagModel[]>(`${environment.API_URL}/clinic_tags/COMPANY/all/?ordering=name&is_active=true`);
  }

  // USERCLINIC

  userClinicList(currentClinicId: string, searchString: string = "", orderingParam: string = "", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/clinics/?clinic_id=${currentClinicId}&search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  userClinicEdit(currentClinicId: string = "", clinicData: ClinicModel): Observable<ClinicModel> {
    return this.httpClient.patch<ClinicModel>(`${environment.API_URL}/clinics/${clinicData.id}/?clinic_id=${currentClinicId}`, JSON.stringify(clinicData));
  }

  userClinicGetOne(currentClinicId: string = "", clinicId: number): Observable<ClinicModel> {
    return this.httpClient.get<ClinicModel>(`${environment.API_URL}/clinics/${clinicId}/?clinic_id=${currentClinicId}`);
  }

  userClinicGetAll(currentClinicId: string = ""): Observable<ClinicModel[]> {
    return this.httpClient.get<ClinicModel[]>(`${environment.API_URL}/clinics/all/?clinic_id=${currentClinicId}`);
  }


  // TAGS DE CLINIC

  tagGet(clinicId: string = '', tagId: number, tagType: string): Observable<TagModel> {
    return this.httpClient.get<TagModel>(`${environment.API_URL}/clinic_tags/${tagType}/${tagId}/?clinic_id=${clinicId}`)
  }

  tagRegister(clinicId: string = '', tagData: TagModel): Observable<TagModel> {
    return this.httpClient.post<TagModel>(`${environment.API_URL}/clinic_tags/${tagData.type}/?clinic_id=${clinicId}`, JSON.stringify(tagData))
  }

  tagEdit(clinicId: string = '', tagData: TagModel): Observable<TagModel> {
    return this.httpClient.patch<TagModel>(`${environment.API_URL}/clinic_tags/${tagData.type}/${tagData.id}/?clinic_id=${clinicId}`, JSON.stringify(tagData))
  }

  tagRemove(clinicId: string = '', tagId: number, tagType: string) {
    return this.httpClient.delete(`${environment.API_URL}/clinic_tags/${tagType}/${tagId}/?clinic_id=${clinicId}`)
  }

  tagsList(tagType: string, clinicId: string = "", searchString: string = "", orderingItem: string = "", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/clinic_tags/${tagType}/?clinic_id=${clinicId}&search=${searchString}&ordering=${orderingItem}&is_active=${isActive}&page=${page}`);
  }

  tagGetAll(): Observable<TagModel[]> {
    return this.httpClient.get<TagModel[]>(`${environment.API_URL}/clinic_tags/all/?ordering=name&is_active=true`);
  }
}
