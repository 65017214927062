import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { ClinicsService } from 'src/app/core/services/clinics.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { documentsStringFormatter, phoneNumberStringFormatter } from 'src/app/core/utils/string-formatters';
import { ListTemplate } from 'src/app/shared/renderers/models/list-template';

@Component({
  selector: 'app-clinics-list',
  templateUrl: './clinics-list.component.html',
  styleUrls: ['./clinics-list.component.scss']
})
export class ClinicsListComponent implements OnInit {

  searchString: string = "";
  orderingItem: string = "name";

  request?: Subscription;

  get clinicsId() {
    if(!this.isSuperUserOrStaff) {
      return this.sessionManager.clinicsListId;
    }
    return '';
  }

  get currentClinicId(){
    if(!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent().value;
    }
    return '';
  }

  get canNewData() {
    const permissions = [PermissionConst.add_clinic, PermissionConst.change_clinic, PermissionConst.view_clinic];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  get canExport() {
    const permissions = [PermissionConst.export_clinic, PermissionConst.change_clinic, PermissionConst.view_clinic];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: true,
    searchBarPlaceholder: "Buscar por Nome/Razão Social ou CPF/CNPJ",
    showAdvancedSearch: false,
    newDataText: "+ Nova Clínica",
    newDataRouterLink: this.isSuperUserOrStaff && this.canNewData ? "register" : undefined,
    notFoundMessage: "Nenhuma clínica encontrada",
    clickRowClickHandle: true,
    header: [
      { name: "name", label: "Nome", widthInPercentage: "45%", width: "300px" },
      { name: "document_number", label: "CPNJ/CPF", widthInPercentage: "25%", width: "150px", displayStringConvertionFunction: documentsStringFormatter },
      { name: "phone_numbers", label: "Telefone", widthInPercentage: "30%", width: "200px", displayStringConvertionFunction: phoneNumberStringFormatter }
    ],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      this.fetchList(this.searchString, `${invertString}${value}`, this.listTemplate.is_active);
      this.orderingItem = value;
      this.listTemplate.selectedOrdering = value;
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      this.fetchList(value, this.orderingItem, this.listTemplate.is_active);
    },
    onClickActive: !this.isSuperUserOrStaff ? undefined : () => {
      this.listTemplate.is_active = !this.listTemplate.is_active;
      this.fetchList(this.searchString, this.orderingItem, this.listTemplate.is_active);
    },
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
    exportUrl: this.canExport ? '/clinics/export' : undefined,
    exportOptions: {
      id: 'ID',
      type: 'Tipo',
      document_number: 'CPF/CNPJ',
      name: 'Nome/Razão Social',
      display_name: 'Nome de tratamento/Nome fantasia',
      email: 'E-mail',
      cro: 'CRO',
      phone_numbers: 'Telefones',
      address: 'Endereço'
    }
  };

  constructor(private clinicsService: ClinicsService, private sessionManager: SessionManagerService, private titleService: Title, private router: Router) {
    this.titleService.setTitle('Chega de Ronco - Clínicas');
  }

  ngOnInit(): void {
    if (this.isSuperUserOrStaff) {
      this.initializeList();
    } else {
      this.router.navigate([`dashboard/registers/clinics/edit/${this.currentClinicId}`]);
    }
  }

  initializeList() {
    this.fetchList("", "name", this.listTemplate.is_active);
  }

  fetchList(searchString: string, orderingItem: string, isActive: boolean | undefined) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;

    if (this.request) {
      this.request.unsubscribe();
    }

    const fnResponse = (response: any) => {
      this.listTemplate.loading = false;
      this.listTemplate.data = response;
      this.listTemplate.hasNext = response.next != null;
    };

    const fnError = (error: any) => {
      const response = error as HttpErrorResponse;
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = response.error['detail'];
    }

    if (this.sessionManager.isSuperUserOrStaff()) {
      this.request = this.clinicsService.list(searchString, orderingItem, isActive).subscribe(fnResponse, fnError);
    } else {
      this.request = this.clinicsService.userClinicList(this.clinicsId, searchString, orderingItem, isActive).subscribe(fnResponse, fnError);
    }
  }

  loadMore(page: number) {
    this.listTemplate.loadingNext = true;

    const fnResponse = (response: any) => {
      this.listTemplate.hasNext = response.next != null;
      response.results!.map((item: any) => this.listTemplate.data!.results!.push(item));
      this.listTemplate.loadingNext = false;
    };
    const fnError = (error: any) => {
      const response = error as HttpErrorResponse;
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = response.error['detail'];
    };

    if (this.sessionManager.isSuperUserOrStaff()) {
      this.clinicsService.list(this.searchString, this.orderingItem, this.listTemplate.is_active, page).subscribe(fnResponse, fnError);
    } else {
      this.clinicsService.userClinicList(this.clinicsId, this.searchString, this.orderingItem, this.listTemplate.is_active, page).subscribe(fnResponse, fnError);
    }

  }
}
