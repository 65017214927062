import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {PatientModel} from "../models/patient.model";
import {ListModel, PaginationModel} from "../models/list.model";
import {Observable} from "rxjs";
import {PolysomnographyModel} from "../models/polysomnography.model";
import {Question} from "../models/question.model";
import {Answer} from "../models/answer.model";

@Injectable({
  providedIn: 'root'
})
export class PatientQuizService {
  constructor(private http: HttpClient) { }

  sendFlow(data: any) {
    return this.http.post(`${environment.API_URL}/quiz/patients/send_flow/`, data)
  }

  getPhoneNumber(phoneNumber: string) {
    return this.http.get(`${environment.API_URL}/quiz/patients/phone_number/${phoneNumber}/`);
  }

  registerPatient(patient: PatientModel): Observable<PatientModel> {
    return this.http.post<PatientModel>(`${environment.API_URL}/app/accounts/no_code_confirmation/`, patient);
  }

  registerPatientWithCode(patient: PatientModel) {
    return this.http.post(`${environment.API_URL}/app/accounts/`, patient);
  }

  confirmCodeAsPatient(data: {country_code: string, phone_number: string, code: string}) {
    return this.http.post(`${environment.API_URL}/app/accounts/oauth/login/confirmation/`, data);
  }

  listPolysomnographies(patientId: number): Observable<PaginationModel<PolysomnographyModel>> {
    return this.http.get(`${environment.API_URL}/app/patients/${patientId}/polysomnography/?status=IN_PROGRESS`);
  }

  listQuizQuestions(patientId: number, polysomnographyId: number): Observable<Question[]> {
    return this.http.get<Question[]>(`${environment.API_URL}/app/patients/${patientId}/polysomnography/${polysomnographyId}/quiz/`);
  }

  answerQuestion(patientId: number, polysomnographyId: number, answer: Answer) {
    let data: any;
    let hasImage = false;

    if (Array.isArray(answer.answer)) {
      let dataList = [];

      for (let data of answer.answer) {
        if (data instanceof File) {
          hasImage = true;
        }
        dataList.push(data);
      }

      if (hasImage) {
        data = new FormData();
        data.set('quiz_question_id', answer.quiz_question_id);
        for (let file of dataList) {
          data.append('answer', file);
        }
      } else {
        data = answer;
      }
    } else {
      data = answer;
    }

    return this.http.patch(`${environment.API_URL}/app/patients/${patientId}/polysomnography/${polysomnographyId}/answer_quiz_question/?upload=${hasImage}`, data);
  }

  finishQuiz(patientId: number, polysomnographyId: number, question_id_list: number[]) {
    return this.http.patch(`${environment.API_URL}/app/patients/${patientId}/polysomnography/${polysomnographyId}/quiz_questions_done/`, {question_id_list});
  }
}
