import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfessionalTagsListComponent } from './professional-tags-list/professional-tags-list.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { ProfessionalTagFormComponent } from './professional-tag-form/professional-tag-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    ProfessionalTagsListComponent,
    ProfessionalTagFormComponent
  ],
  imports: [
    CommonModule,
    RenderersModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class ProfessionalTagsModule { }
