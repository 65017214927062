import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CouponsListComponent } from './coupons-list/coupons-list.component';
import {RenderersModule} from "../../../../shared/renderers/renderers.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import { CouponFormComponent } from './coupon-form/coupon-form.component';
import {SharedModule} from "../../../../shared/shared.module";



@NgModule({
  declarations: [
    CouponsListComponent,
    CouponFormComponent
  ],
  imports: [
    CommonModule,
    RenderersModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule,
    SharedModule,
  ]
})
export class CouponsModule { }
