<div id="login">
  <div class="container">
    <div class="row">
      <div class="col logo-bar">
        <img src="/assets/chegaderonco-color.svg" alt="Chega de Ronco">
      </div>
    </div>

    <div class="row">
      <div class="offset-sm-1 offset-md-2 offset-lg-3 col-12 col-sm-10 col-md-8 col-lg-6">
        <div class="card">
          <form [formGroup]="loginForm" class="form">
            <phone-number-input label="Telefone" [formGroup]="loginForm" [hidden]="isConfirmationCode"></phone-number-input>
            <basic-input [hidden]="!isConfirmationCode" [formGroup]="loginForm" id="code" controlName="code"
                         [maskOptions]="{mask: '00000'}"
                         label="Você recebeu um código por WhatsApp no telefone {{ phoneNumber }}">
            </basic-input>

            <div class="row pt-3">
              <div class="offset-md-3 col-12 col-md-6 mb-3">
                <button (click)="onSubmit($event)" typeof="submit" class="btn btn-primary btn-block" type="submit" [disabled]="loading || isItToDisable">
                  {{ submitButton }}
                  <span *ngIf="loading" class="spinner-border" role="status" aria-hidden="true"></span>
                </button>
              </div>
            </div>

            <div class="d-flex justify-content-center" *ngIf="isConfirmationCode">
              <div class="text-center">
                <button (click)="requestAnotherCode()" class="btn btn-link">Solicitar outro código</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
