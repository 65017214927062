import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PolysomnographyExamsService} from "../../../../core/services/polysomnography-exams.service";

@Component({
  selector: 'app-import-exam-modal',
  templateUrl: './import-exam-modal.component.html',
  styleUrls: ['./import-exam-modal.component.scss']
})
export class ImportExamModalComponent implements OnInit {
  formGroup: FormGroup = this.fb.group({
    file: [null, [Validators.required]]
  });

  polysomnographyId: number | undefined;

  selectedFile?: File;

  loading = false;
  textButton = 'Importar';

  constructor(private activeModal: NgbActiveModal, private fb: FormBuilder,
              private polysomnographyExamService: PolysomnographyExamsService) { }

  ngOnInit(): void {
  }

  fileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  closeModal() {
    this.activeModal.close();
  }

  submitFile() {
    if (this.selectedFile) {
      this.loading = true;
      this.textButton = 'Carregando...'
      this.polysomnographyExamService.importExamFromCSVFile(this.polysomnographyId!, this.selectedFile).subscribe((response) => {
        this.activeModal.close(true);
      });
    }
  }
}
