import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OptionModel } from '../../models/option';

@Component({
  selector: 'radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss']
})
export class RadioInputComponent implements OnInit {

  @Input() id!: string;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() optionList!: OptionModel[];
  @Input() formGroup!: FormGroup;
  @Input() inline: boolean = false;
  @Input() disabled!: boolean;
  @Output() onValueChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onValueChangeHandler(value: any) {
    this.onValueChange.emit(value);
  }

}
