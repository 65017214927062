<div class="confirm-modal-wrapper" *ngIf="!loading else loadingTemplate">
  <div class="confirm-modal">
    <div class="confirm-modal__header">
      <h1>Deseja associar o usuário?</h1>
    </div>
    <div class="confirm-modal__content">
      <h4 class="pt-2"> {{patient.name}} ({{patient.country_code}} {{patient.phone_number}})</h4>
      <div class="pt-3" *ngIf="!loading">
        <app-select-input id="clinic_id" label="Clínica" [items]="clinicOptions" [formGroup]="formGroup"
                          [hideSelected]="true" [addTag]="false" [multiple]="false" [clearable]="false"
                          [searchable]="true">
        </app-select-input>
      </div>
      <div class="row justify-content-end pt-4">
        <button class="btn btn-link col-sm-12 col-md-3 col-lg-3 mt-5 mb-5"
                (click)="activeModal.close(false)">Cancelar</button>
        <button class="btn btn-primary col-sm-12 col-md-3 col-lg-3 mt-5 mb-5"
                (click)="connectUser()" [disabled]="!formGroup.valid">Associar</button>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
