import { Injectable } from '@angular/core';
import { AccountModel } from '../models/account.model';
import { PermissionConst } from '../models/permission-const.model';
import {PatientModel} from "../models/patient.model";

@Injectable({
  providedIn: 'root'
})
export class SessionManagerService {
  accountData: AccountModel = {} as AccountModel;
  loggedIn: boolean = false;
  loggedInAsPatient: boolean = false;

  constructor() {
    this.getSessionData();
  }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  getSessionData() {
    const storedAccount = localStorage.getItem('account');

    if (storedAccount != undefined) {
      this.loggedIn = true;
      this.accountData = JSON.parse(storedAccount) as AccountModel;
    } else {
      this.loggedIn = false;
      this.accountData = {} as AccountModel;
    }

    return JSON.parse(storedAccount!) as AccountModel;
  }

  setSessionData(data: AccountModel): void {
    this.loggedIn = true;
    this.accountData = data;
    localStorage.setItem('account', JSON.stringify(data));
    if (data['token']) {
      localStorage.setItem('token', data['token']);
    }
  }

  clearAccountData() {
    localStorage.removeItem('account');
  }

  clearSessionData(): void {
    this.loggedIn = false;
    localStorage.clear();
  }

  getUserPermission(id: PermissionConst) {
    let hasPermission = false;
    let list = this.getSessionData().user_permissions;
    if (list) {
      hasPermission = list.some(permissionId => permissionId == id);
    }
    return hasPermission;
  }

  setClinicCurrent(clinic: any) {
    localStorage.setItem('currentClinic', JSON.stringify(clinic));
  }

  getClinicCurrent() {
    let currentClinic = localStorage.getItem('currentClinic');
    if(currentClinic) return JSON.parse(currentClinic);
    return '';
  }

  setClinics(clinic: any) {
    localStorage.setItem('clinics', JSON.stringify(clinic));
  }

  getClinics() {
    let clinic = localStorage.getItem('clinics');
    return JSON.parse(clinic!);
  }

  get clinicsListId() {
    let valueStr: string = '';
    if (this.accountData.clinics != null && this.accountData.clinics.length > 0) {
      valueStr = this.accountData.clinics.join(',');
    } else {
      valueStr = this.getClinicCurrent() ?? '';
    }
    return valueStr;
  }

  isSuperUserOrStaff() {
    if (this.accountData.is_staff || this.accountData.is_superuser) {
      return true;
    }
    return false;
  }

  // canApproveExam() {
  //   if (this.accountData.can_approve_exam) {
  //     return true;
  //   }
  //   return false;
  // }

  setPatientData(patient: PatientModel) {
    if (patient.token) {
      localStorage.setItem('token', patient.token);
    }
    localStorage.setItem('patient', JSON.stringify(patient));
  }

  getPatientData(): PatientModel | null {
    let patientData = localStorage.getItem('patient');
    let token = localStorage.getItem('token');
    if (patientData) {
      if (token) {
        this.loggedInAsPatient = true;
      }
      return JSON.parse(patientData) as PatientModel;
    }

    return null;
  }

  clearPatientData(): void {
    this.loggedInAsPatient = false;
    localStorage.removeItem('token');
    localStorage.removeItem('patient');
  }
}
