import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { ListTemplate } from 'src/app/shared/renderers/models/list-template';

@Component({
  selector: 'app-user-groups-list',
  templateUrl: './user-groups-list.component.html',
  styleUrls: ['./user-groups-list.component.scss']
})
export class UserGroupsListComponent implements OnInit {

  listTemplate: ListTemplate = {
    showSearch: true,
    searchBarPlaceholder: "Buscar por Nome",
    showAdvancedSearch: false,
    newDataText: "+ Novo grupo",
    newDataRouterLink: "register",
    notFoundMessage: "Nenhum grupo encontrado",
    header: [{ name: "name", label: "Nome", widthInPercentage: "30%" }],
    onHeaderItemClick: (value: string) => {
      this.orderingItem = value;
      this.fetchList(this.searchString, value);
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      this.fetchList(value, this.orderingItem);
    }
  };

  searchString: string = "";
  orderingItem: string = "";

  constructor(private accountsService: AccountsService) { }

  ngOnInit(): void {
    this.fetchList("", "name");
  }

  async fetchList(searchString: string, orderingItem: string) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;

    try {
      const listResponse = await this.accountsService.groupsList(searchString, orderingItem).toPromise();
      this.listTemplate.loading = false;
      this.listTemplate.data = listResponse;
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = response.error['detail'];
    }
  }
}
