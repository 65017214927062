import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemUsersListComponent } from './system-users-list/system-users-list.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { ReactiveFormsModule } from "@angular/forms";
import { SystemUsersFormComponent } from './system-users-form/system-users-form.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    SystemUsersListComponent,
    SystemUsersFormComponent,
  ],
  imports: [
    CommonModule,
    RenderersModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class SystemUsersModule {
}
