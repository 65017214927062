import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ListModel } from '../models/list.model';
import { ProfessionalModel } from '../models/professional.model';
import { SpecialtyModel } from '../models/specialty.model';
import { TagModel } from '../models/tag.model';

@Injectable({
  providedIn: 'root'
})
export class ProfessionalsService {
  constructor(private httpClient: HttpClient) { }

  register(professionalData: ProfessionalModel): Observable<ProfessionalModel> {
    return this.httpClient.post<ProfessionalModel>(`${environment.API_URL}/professionals/`, JSON.stringify(professionalData));
  }

  edit(professionalData: ProfessionalModel): Observable<ProfessionalModel> {
    return this.httpClient.patch<ProfessionalModel>(`${environment.API_URL}/professionals/${professionalData.id}/`, JSON.stringify(professionalData));
  }

  remove(clinicId: number): Observable<ProfessionalModel> {
    return this.httpClient.delete<ProfessionalModel>(`${environment.API_URL}/professionals/${clinicId}/`);
  }

  list(searchString: string, orderingItem: string, clinicId: string = '', isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/professionals/?clinic_id=${clinicId}&search=${searchString}&ordering=${orderingItem}&is_active=${isActive}&page=${page}`);
  }

  get(professionlId: number): Observable<ProfessionalModel> {
    return this.httpClient.get<ProfessionalModel>(`${environment.API_URL}/professionals/${professionlId}/`);
  }

  uploadImage(imageFile: File | undefined, accountId: number, clinicId: string = ''): Observable<object> {
    const formData = new FormData();
    formData.append("profile_image", imageFile ? imageFile : '');

    return this.httpClient.patch<object>(`${environment.API_URL}/accounts/${accountId}/profile_image/?clinic_id=${clinicId}`, formData);
  }

  listAll(): Observable<ProfessionalModel[]> {
    return this.httpClient.get<ProfessionalModel[]>(`${environment.API_URL}/professionals/all/?ordering=name&is_active=true`);
  }

  listAllTagProfessional(clinicId: string = ""): Observable<TagModel[]> {
    return this.httpClient.get<TagModel[]>(`${environment.API_URL}/tags/professional/all/?clinic_id=${clinicId}&ordering=name&is_active=true`);
  }

  listAllSpecialties(clinicId: string = ""): Observable<SpecialtyModel[]> {
    return this.httpClient.get<SpecialtyModel[]>(`${environment.API_URL}/specialties/all/?clinic_id=${clinicId}&ordering=name&is_active=true`);
  }

  searchAccount(document_number: number): Observable<ProfessionalModel> {
    return this.httpClient.get<ProfessionalModel>(`${environment.API_URL}/accounts/search/?document_number=${document_number}`);
  }

  searchAll(document_number: number): Observable<ProfessionalModel> {
    return this.httpClient.get<ProfessionalModel>(`${environment.API_URL}/search_professional/?document_number=${document_number}`);
  }

  searchByClinic(clinicId: number, document_number: number): Observable<ProfessionalModel> {
    return this.httpClient.get<ProfessionalModel>(`${environment.API_URL}/professionals/search/?clinic_id=${clinicId}&document_number=${document_number}`);
  }

  searchProfessionalAll(document_number: number): Observable<ProfessionalModel> {
    return this.httpClient.get<ProfessionalModel>(`${environment.API_URL}/professionals/search/?document_number=${document_number}`);
  }

  // CLINIC USER

  revoke(clinicId: number, professional_id: number): Observable<ProfessionalModel> {
    return this.httpClient.patch<ProfessionalModel>(`${environment.API_URL}/professionals/revoke/?clinic_id=${clinicId}`, { 'professional_id': professional_id });
  }

  connect(clinicId: number, professional_id: number): Observable<ProfessionalModel> {
    return this.httpClient.patch<ProfessionalModel>(`${environment.API_URL}/professionals/connect/?clinic_id=${clinicId}`, { 'professional_id': professional_id });
  }

  getClinicUser(clinicId: number, professionlId: number): Observable<ProfessionalModel> {
    return this.httpClient.get<ProfessionalModel>(`${environment.API_URL}/professionals/${professionlId}/?clinic_id=${clinicId}`);
  }

  clinicUserGetAll(clinicId: number): Observable<ProfessionalModel[]> {
    return this.httpClient.get<ProfessionalModel[]>(`${environment.API_URL}/professionals/all/?clinic_id=${clinicId}&ordering=name&is_active=true`);
  }

  registerClinicUser(clinicId: number, professionalData: ProfessionalModel): Observable<ProfessionalModel> {
    return this.httpClient.post<ProfessionalModel>(`${environment.API_URL}/professionals/?clinic_id=${clinicId}`, JSON.stringify(professionalData));
  }

  editClinicUser(clinicId: number, professionalData: ProfessionalModel): Observable<ProfessionalModel> {
    return this.httpClient.patch<ProfessionalModel>(`${environment.API_URL}/professionals/${professionalData.id}/?clinic_id=${clinicId}`, JSON.stringify(professionalData));
  }
}
