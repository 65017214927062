import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClinicTagsListComponent } from './clinic-tags-list/clinic-tags-list.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { ClinicTagFormComponent } from './clinic-tag-form/clinic-tag-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ClinicTagsListComponent,
    ClinicTagFormComponent
  ],
  imports: [
    CommonModule,
    RenderersModule,
    SharedModule,
    ReactiveFormsModule,
  ]
})
export class ClinicTagsModule { }
