<div class="background" *ngIf="!viewVideo else videoTemplate">
  <div class="main-div" *ngIf="!loading else loadingTemplate">
    <div>
      <img class="logo" src="../../../assets/chegaderonco-color.svg" alt="Chega de Ronco">
    </div>

    <form class="register-form" [formGroup]="formGroup">
      <ng-container *ngIf="!phoneNumber else phoneNumberAsTextTemplate">
        <div class="form-group" [class.form-group--error]="hasErrors('country_code')">
          <label class="textstyle">DDI</label>
          <ng-select [ngClass]="'custom'" [items]="countryCodes" bindValue="value" formControlName="country_code"
                     [clearable]="false"></ng-select>
        </div>

        <div class="form-group" [class.form-group--error]="hasErrors('phone_number')">
          <label class="textstyle">Telefone</label>
          <input mask="(00) 0000-0000 || (00) 0 0000-0000" formControlName="phone_number">
        </div>
      </ng-container>

      <ng-template #phoneNumberAsTextTemplate>
        <p class="textstyle">Telefone</p>
        <p class="textstyle">{{ getPhoneNumberFromFormGroup()|mask:'+00 (00) 000000000?' }}</p>
      </ng-template>

      <div class="form-group" [class.form-group--error]="hasErrors('name')">
        <label class="textstyle">Nome Completo</label>
        <input type="text" formControlName="name">
      </div>

      <div class="form-group" [class.form-group--error]="hasErrors('birthdate')">
        <label class="textstyle">Data de nascimento</label>
        <input type="text" formControlName="birthdate" mask="d0/M0/0000" [leadZeroDateTime]="true"
               [outputTransformFn]="parseToDate">
      </div>

      <div class="form-group" [class.form-group--error]="hasErrors('gender')">
        <label class="textstyle">Gênero</label>

        <div class="radio-select-group">
          <label for="male" class="textstyle radio-label">
            <input type="radio" id="male" name="gender" value="MALE" formControlName="gender">
            Masculino
          </label>

          <label for="female" class="textstyle radio-label">
            <input type="radio" id="female" name="gender" value="FEMALE" formControlName="gender">
            Feminino
          </label>
        </div>
      </div>

      <div class="form-group">
        <button class="buttonstyle textstyle" (click)="submit()">Salvar</button>
      </div>
    </form>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="background col full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Carregando...</span>
    </div>
  </div>
</ng-template>

<ng-template #videoTemplate>
  <div class="video-wrapper">
    <div class="video">
      <video src="/assets/videos/boas_vindas.mp4" #video (click)="toggleVideo(video)" [muted]="true" autoplay
             playsinline></video>

      <div class="video-muted" *ngIf="video.muted" (click)="toggleVideo(video)">
        <span>Clique aqui para<br>ativar o som</span>
      </div>

      <div class="video-controls" *ngIf="!video.muted">
        <i class="icon icon-play" *ngIf="video.paused || video.ended" (click)="toggleVideo(video)"></i>
      </div>

    </div>

    <button class="button-start" (click)="viewVideo = false">iniciar</button>
  </div>
</ng-template>

