<div class="confirm-modal-wrapper">
    <div class="confirm-modal">
        <div class="confirm-modal__header">
            <h1>Confirmar</h1>
        </div>

        <div class="confirm-modal__content">
            <p>{{text}}</p>

            <div class="row justify-content-end">
                <button class="btn btn-link col-sm-12 col-md-3 col-lg-3 mt-5 mb-5"
                    (click)="activeModal.close(false)">Cancelar</button>
                <button class="btn btn-primary col-sm-12 col-md-3 col-lg-3 mt-5 mb-5"
                    (click)="activeModal.close(true)">Ok</button>
            </div>
        </div>
    </div>
</div>