import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { OximeterService } from 'src/app/core/services/oximeter.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ListTemplate } from 'src/app/shared/renderers/models/list-template';

@Component({
  selector: 'app-oximeter-list',
  templateUrl: './oximeter-list.component.html',
  styleUrls: ['./oximeter-list.component.scss']
})
export class OximeterListComponent implements OnInit {

  searchString: string = "";
  orderingItem: string = "";
  statusString: string = "";

  request?: Subscription;

  get clinicId() {
    if (this.isSuperUserOrStaff) {
      return '';
    }
    return this.sessionManager.getClinicCurrent().value?.toString();
  }

  clinicIdList: string = "";

  get canNewData() {
    const permissions = [PermissionConst.add_account, PermissionConst.change_account, PermissionConst.view_account];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  get canExport() {
    const permissions = [PermissionConst.export_account, PermissionConst.change_account, PermissionConst.view_account];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: true,
    searchBarPlaceholder: "Buscar Número de série",
    showAdvancedSearch: false,
    searchBarStatus: true,
    newDataText: "+ Novo Oxímetro",
    newDataRouterLink: this.isSuperUserOrStaff ? "register" : undefined,
    notFoundMessage: "Nenhum oxímetro encontrado",
    header: [
      { name: "serial_number", label: "Número de série", widthInPercentage: "19%", width: '150px' },
      { name: "model", label: "Modelo", widthInPercentage: "15%", width: '150px' },
      { name: "status_oximeter", label: "Status", widthInPercentage: "10%", width: '100px' },
      { name: "current_patient__patient__name", label: "Paciente", widthInPercentage: "30%", width: '300px' },
      { name: "current_patient__sent_at", label: "Enviado", widthInPercentage: "13%", width: '100px' },
      { name: "current_patient__devolution_date", label: "Devolução", widthInPercentage: "13%", width: '100px' }
    ],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(this.searchString, `${invertString}${value}`, clinicId, this.statusString, this.listTemplate.is_active);
      this.orderingItem = value;
      this.listTemplate.selectedOrdering = value;
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(value, this.orderingItem, clinicId, this.statusString, this.listTemplate.is_active);
    },
    onClinicFocusOut: (value: string) => {
      this.clinicIdList = value;
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(this.searchString, this.orderingItem, clinicId, this.statusString, this.listTemplate.is_active);
    },
    onStatusFocusOut: (value: string) => {
      this.statusString = value;
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(this.searchString, this.orderingItem, clinicId, this.statusString, this.listTemplate.is_active);
    },
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
  };

  constructor(private oximeterService: OximeterService, private sessionManager: SessionManagerService,
    private titleService: Title) {
    this.titleService.setTitle('Chega de Ronco - Oxímetros');
  }

  ngOnInit(): void {
    this.initializeList();
  }

  initializeList() {
    this.fetchList(this.searchString, this.orderingItem, this.clinicId, this.statusString, this.listTemplate.is_active);
  }

  fetchList(searchString: string, orderingItem: string, clinicId: string, status: string, isActive: boolean | undefined) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;
    if (this.request) {
      this.request.unsubscribe();
    }
    this.request = this.oximeterService.oximetersList(clinicId, searchString, orderingItem, status, isActive)
      .subscribe(response => {
      this.listTemplate.loading = false;
      this.listTemplate.data = response;
      this.listTemplate.hasNext = response.next != null;
    }, (error: HttpErrorResponse) => {
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = error.error['detail'];
    });
  }

  loadMore(page: number) {
    this.listTemplate.loadingNext = true;
    let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
    this.oximeterService.oximetersList(clinicId, this.searchString, this.orderingItem, this.statusString, this.listTemplate.is_active, page).subscribe(
      response => {
        this.listTemplate.hasNext = response.next != null;
        response.results!.map(item => this.listTemplate.data!.results!.push(item));
        this.listTemplate.loadingNext = false;
      },
      error => {
        this.listTemplate.loadingNext = false;
      }
    );
  }

}
