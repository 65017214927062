<div class="background"></div>
<div id="body-container">
  <div id="nav-container">
    <nav class="navbar px-0 navbar-expand-lg d-flex justify-content-between">
      <button class="navbar-toggler menu-button" (click)="rootMenuButtonHandler()">
        <i class="icon-Icon-ionic-ios-menu"></i>
      </button>
      <div class="logo-wrapper">
        <img src="/assets/chegaderonco-horizontal.svg" alt="">
      </div>
      <div *ngIf="isRegistersScreen" class="navbar-toggler menu-button">
      </div>

      <div class="navbar-collapse" [ngClass]="showRootMenuSidebar ? 'collapseShow' : 'collapse' "
        (click)="showRootMenuSidebar ? rootMenuButtonHandler() : null" id="navbarHeader">
        <ul class="navbar-nav">
          <li class="nav-item" *ngFor="let l of linkListHeader">
            <a routerLink="{{l.routerLink}}" routerLinkActive="{{l.routerLinkActive}}" *ngIf="l.toView">
              <i class="{{l.icon}}"></i>
              <span>{{l.name}}</span>
            </a>
          </li>
          <div class="line"></div>
          <li class="dropdown" *ngIf="!showRootMenuSidebar">
            <button id="dropdownButton" (click)="avatarClickHandle()" class="dropdown__avatar">
              <img class="profile-image" [src]="getProfileImage()">
            </button>
          </li>
          <div *ngIf="showDropdownMenu || showRootMenuSidebar"
            [ngClass]="showRootMenuSidebar ? 'dropdown__menu-mobile' : 'dropdown__menu'">
            <button class="row" (click)="goToUserScreen()">
              <i class="icon-Icon-metro-profile col-2 p-0"></i>
              <span class="col p-0">Meu perfil</span>
            </button>
            <button class="row" (click)="logoutButtonHandler()">
              <i class="icon-Icon-material-exit-to-app col-2 p-0"></i>
              <span class="col p-0">Sair</span>
            </button>
          </div>
        </ul>
      </div>
      <div *ngIf="showRootMenuSidebar" class="navbar-backdrop" (click)="rootMenuButtonHandler()">
      </div>
    </nav>
  </div>
  <div id="main-container">
    <router-outlet></router-outlet>
  </div>

</div>
