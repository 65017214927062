
<form [formGroup]="formGroup">
    <fieldset>
      <div class="row card">
        <div class="col">
          <label for="old_password">Senha antiga</label>
          <input type="password" formControlName="old_password" id="old_password"
          [ngClass]="formGroup.get('old_password')!.errors && formGroup.get('old_password')!.touched ? 'error' : ''"/>
          <app-form-error id="old_password" [formGroup]="formGroup" controlName="old_password"></app-form-error>
          
          <label for="new_password">Nova senha</label>
          <input type="password" formControlName="new_password" id="new_password"
          [ngClass]="formGroup.get('new_password')!.errors && formGroup.get('new_password')!.touched ? 'error' : ''"/>
          <app-form-error id="new_password" [formGroup]="formGroup" controlName="new_password"></app-form-error>
  
          <label for="new_password_confirm">Confirme nova senha</label>
          <input type="password" formControlName="new_password_confirm" id="new_password_confirm"
          [ngClass]="formGroup.get('new_password_confirm')!.errors && formGroup.get('new_password_confirm')!.touched ? 'error' : ''"/>
          <app-form-error id="new_password_confirm" [formGroup]="formGroup" controlName="new_password_confirm"></app-form-error>
        </div>
      </div>
  
      <div class="row p-0 d-flex justify-content-end">
        <div class="col-12 col-md-4 mt-4">
          <button class="btn btn-link" type="button" (click)="cancelHandler()">Cancelar</button>
        </div>
  
        <div class="col-12 col-md-4 mt-4">
          <button class="btn btn-primary" type="submit" (click)="submitHandler()">
            Salvar
            <span *ngIf="submitting" class="spinner-border"></span>
          </button>
        </div>
      </div>
    </fieldset>
  </form>
  
