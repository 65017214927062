import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ListModel } from 'src/app/core/models/list.model';
import { OximeterService } from 'src/app/core/services/oximeter.service';
import * as moment from 'moment';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';

@Component({
  selector: 'app-historic-list',
  templateUrl: './historic-list.component.html',
  styleUrls: ['./historic-list.component.scss']
})
export class HistoricListComponent implements OnInit {

  id!: number;

  @ViewChild('searchbar') input!: ElementRef;
  @ViewChild('date') inputDate!: ElementRef;

  page: number = 0;
  mediaQuery: boolean = false;

  dataHistoric: ListModel = {};

  headers = [
    { name: 'sent_at', label: 'Enviado em', width: '120px', widthWithPercentage: '20%' },
    { name: 'attendance__date_time', label: 'Devolvido em', width: '120px', widthWithPercentage: '20%' },
    { name: 'patient__name', label: 'Paciente', width: '200px', widthWithPercentage: '60%' },
  ];

  searchString = '';
  selectedOrdering = "patient__name";
  invertedOrdering = false;

  loading = false;
  loadingNext = false;
  hasNext = false; // dataHistoric.next

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private oximeterService: OximeterService, private sessionManager: SessionManagerService) {
    let mediaQuery = window.matchMedia("(min-width: 768px)");
    fromEvent<MediaQueryList>(mediaQuery, 'change')
      .pipe(
        startWith(mediaQuery),
        map((list: MediaQueryList) => list.matches)
      ).subscribe((matches) => {
        this.mediaQuery = matches;
      });
  }

  get clinicId() {
    if (this.isSuperUserOrStaff) {
      return '';
    }
    return this.sessionManager.getClinicCurrent().value?.toString();
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      this.id = Number(params['id']);
    });
    // this.dataHistoric.results = [
    //   {
    //     send: '09/09/2022 9:00',
    //     get: '09/09/2022 9:00',
    //     patient: 'Bruce Wayne',
    //     notes: 'Teste 1 de observações',
    //     clicked: false,
    //   },
    //   {
    //     send: '09/09/2022 9:00',
    //     get: '09/09/2022 9:00',
    //     patient: 'Tony Stark',
    //     notes: 'Teste 2 de observações',
    //     clicked: false,
    //   },
    //   {
    //     send: '09/09/2022 9:00',
    //     get: '09/09/2022 9:00',
    //     patient: 'Nick Fury',
    //     notes: 'Teste 3 de observações',
    //     clicked: false,
    //   },
    //   {
    //     send: '09/09/2022 9:00',
    //     get: '09/09/2022 9:00',
    //     patient: 'Bellatriz Lestranger',
    //     notes: 'Teste 4 de observações',
    //     clicked: false,
    //   },
    //   {
    //     send: '09/09/2022 9:00',
    //     get: '09/09/2022 9:00',
    //     patient: 'Grizzly Adams',
    //     notes: 'Teste 5 de observações',
    //     clicked: false,
    //   },
    //   {
    //     send: '09/09/2022 9:00',
    //     get: '09/09/2022 9:00',
    //     patient: 'Afonso Potter',
    //     notes: 'Teste 6 de observações',
    //     clicked: false,
    //   },
    // ]
    this.fetchListHistoric(this.id);
  }

  getWidth(index: number): string {
    if (this.mediaQuery) {
      return this.headers![index].widthWithPercentage ?? '10%';
    } else {
      return this.headers![index].width ?? '50px';
    }
  }

  onHeaderClick(value: string) {
    let invertString = '';
    if (this.selectedOrdering == value) {
      this.invertedOrdering = !this.invertedOrdering;
      if (this.invertedOrdering)
        invertString = '-';
    }
    // this.fetchListHistoric(this.searchString, `${invertString}${value}`);
    this.selectedOrdering = value;
  }

  nextPage() {
    this.loading = true;
    this.page++;

  }

  rowClickHandle(index: number) {
    this.dataHistoric.results?.forEach((data, dataIndex) => {
      if (dataIndex == index && !data.clicked) {
        data.clicked = true;
      } else {
        data.clicked = false;
      }
    });
  }

  showNote(index: number) {
    if (this.dataHistoric.results![index].clicked) return true;
    else return false;
  }

  getKeyByValue(object: any, value: string) {
    return object[value];
  }

  fetchListHistoric(oximeterId: number) {
    // this.listTemplate.loading = true;
    this.oximeterService.oximeterGet(this.clinicId, oximeterId).subscribe(response => {
      this.dataHistoric.results = response.history;
      //   this.listTemplate.loading = false;
      // this.listTemplate.hasNext = response.next != null;
    }, (error: HttpErrorResponse) => {
      // this.listTemplate.loading = false;
      // this.listTemplate.error = true;
      // this.listTemplate.errorMessage = error.error['detail'];
    });
  }

  getDate(obj: any, name: any) {
    if (obj[name]) {
      return moment(obj[name]).format('DD/MM/YYYY HH:mm');
    } else return '-';
  }

  returnHandler() {
    this.router.navigate(["dashboard/oximeter/edit/" + this.id]);
  }
}
