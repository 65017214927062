import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent implements OnInit {

  @Input() id!: string;
  @Input() formGroup!: FormGroup;
  @Input() controlName: string = '';
  @Input() isModalReport: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  get control(): AbstractControl | null {
    return this.formGroup.get(this.controlName);
  }
}
