import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OximetersComponent } from './oximeters.component';
import { OximeterListComponent } from './oximeter-list/oximeter-list.component';
import { OximeterFormComponent } from './oximeter-form/oximeter-form.component';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NavigationModule } from '../navigation/navigation.module';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HistoricListComponent } from './historic-list/historic-list.component';



@NgModule({
  declarations: [
    OximetersComponent,
    OximeterListComponent,
    OximeterFormComponent,
    HistoricListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    NavigationModule,
    RenderersModule,
    SharedModule,
    ReactiveFormsModule,
  ]
})
export class OximetersModule { }
