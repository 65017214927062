<div class="field" [formGroup]="formGroup">
    <label class="inputLabel" [for]="id" *ngIf="label">{{label ? label : ""}}</label>

    <ng-select [id]="id" [items]="items" bindLabel="label" bindValue="value" [hideSelected]="true" [multiple]="true"
        [addTag]="addTagFn" [formControlName]="id"
        [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '', 'custom']"
        [clearable]="false" [addTag]="false" addTagText="Adicionar novo" notFoundText="Não encontrado" [readonly]="disabled">

        <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label">{{item.label}}</span>
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="false">×</span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
            <span class="ng-value-label">{{item.label}}</span>
        </ng-template>
    </ng-select>

    <form-error [id]="id" [formGroup]="formGroup"></form-error>
</div>