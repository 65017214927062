<div [ngClass]="['field', removeVerticalSpacing ? 'remove-vertical-space' : '' ]" [formGroup]="formGroup">
    <label class="field__title" [for]="id">{{label ? label : ""}}</label>

    <div class="input-wrapper"
        [ngClass]="[formGroup.get('country_code')!.errors && formGroup.get('country_code')!.touched ? 'error' : '', formGroup.get('phone_number')!.errors && formGroup.get('phone_number')!.touched ? 'error' : '']">
        <ng-select [items]="optionList" bindLabel="label" bindValue="value" formControlName="country_code"
            [ngClass]="['custom']" [clearable]="false">
            <ng-template ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
                <img class="flag" [src]="item.flag">
                {{item.value}}
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                <img class="flag" [src]="item.flag">
                {{item.label}}
            </ng-template>
        </ng-select>


        <input [id]="id" [placeholder]="placeholder ? placeholder : ''" formControlName="phone_number"
            [mask]="getMask()" [validation]="shouldValidate()"
            [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '', 'number-input']" />
    </div>
    <form-error [id]=" id" [formGroup]="formGroup"></form-error>
</div>