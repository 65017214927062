import { Component } from '@angular/core';
import { ClinicsService } from './core/services/clinics.service';
import { SessionManagerService } from './core/services/session-manager.service';
import { Options } from './shared/components/select-input/models/Options';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  constructor(private sessionManager: SessionManagerService, private clinicsService: ClinicsService) {
  }

  get user() {
    return this.sessionManager.getSessionData();
  }

  get accountId(): number | undefined {
    return this.sessionManager.accountData.id;
  }

  clinicList!: Options[];

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  ngOnInit() {
    if (!this.isSuperUserOrStaff && this.sessionManager.loggedIn) {
      this.fetchClinicsList();
    }
  }

  fetchClinicsList() {
    this.clinicsService.userClinicList(this.sessionManager.clinicsListId).subscribe(response => {
      this.clinicList = response!.results!.map(item => {
        return {
          value: item!.id!.toString(),
          label: item.name,
          items: item
        } as Options;
      });
      this.sessionManager.setClinics(this.clinicList);
      if (!this.sessionManager.getClinicCurrent()) {
        this.sessionManager.setClinicCurrent(this.clinicList.find(item => this.user.clinic == item.value));
      }
      let userClinic = this.sessionManager.getClinicCurrent();
    });
  }
}
