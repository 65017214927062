import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { PatientService } from 'src/app/core/services/patient.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { FormRendererComponent } from 'src/app/shared/renderers/form-renderer/form-renderer.component';
import { inputType } from 'src/app/shared/renderers/models/form-input';
import { FormTemplate } from 'src/app/shared/renderers/models/form-template';

@Component({
  selector: 'app-patient-edit-attachments',
  templateUrl: './patient-edit-attachments.component.html',
  styleUrls: ['./patient-edit-attachments.component.scss']
})
export class PatientEditAttachmentsComponent implements OnInit {

  formTemplate: FormTemplate = {
    disabled: !this.sessionManager.getUserPermission(PermissionConst.change_patient),
    groupName: "patientRegisterForm",
    separators: [
      {
        groupName: "attachments",
        title: "Anexos",
        inputs: [
          { name: "attachments", type: inputType.ATTACHMENTS, widthInCols: { sm: 12, md: 12 } },
        ]
      },
    ]
  };

  @ViewChild(FormRendererComponent) renderedForm!: FormRendererComponent;
  isLoading: boolean = true;

  constructor(private activatedRoute: ActivatedRoute, private patientService: PatientService, private sessionManager: SessionManagerService) { }

  get currentClinic() {
    if(!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent();
    }
    return '';
  }

  get currentClinicId() {
    if(!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent().value;
    }
    return '';
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  ngOnInit(): void {
    this.activatedRoute.parent?.params.subscribe(params => {
      if(this.isSuperUserOrStaff) {
        this.patientService.patientAttachmentsList(params['id']).subscribe((response: any) => {
          this.formTemplate.separators![0].inputs![0].value = response.attachments
          this.isLoading = false;
        });
      } else {
        this.patientService.clinicUserPatientAttachmentsList(this.currentClinicId, params['id']).subscribe((response: any)=> {
          this.formTemplate.separators![0].inputs![0].value = response.attachments
          this.isLoading = false;
        });
      }
    });
    if(!this.sessionManager.getUserPermission(PermissionConst.change_patient)) this.renderedForm.formGroup.disable();
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.renderedForm.formExitHandler();
  };
}
