import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { PatientService } from 'src/app/core/services/patient.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { documentsStringFormatter, isActiveStatusStringFormatter, phoneNumberStringFormatter } from 'src/app/core/utils/string-formatters';
import { ListTemplate } from 'src/app/shared/renderers/models/list-template';

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss']
})
export class PatientsListComponent implements OnInit {

  searchString: string = "";
  orderingItem: string = "name";

  request?: Subscription;

  get clinicId() {
    if(this.isSuperUserOrStaff) {
      return '';
    }
    return this.sessionManager.getClinicCurrent().value?.toString();
  }

  clinicIdList: string = "";

  get canNewData() {
    const permissions = [PermissionConst.add_patient, PermissionConst.change_patient, PermissionConst.view_patient];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  get canExport() {
    const permissions = [PermissionConst.export_patient, PermissionConst.change_patient, PermissionConst.view_patient];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: true,
    searchBarPlaceholder: "Buscar por Nome, CPF ou Telefone",
    showAdvancedSearch: false,
    newDataText: "+ Novo Paciente",
    newDataRouterLink: (this.isSuperUserOrStaff || this.canNewData) ? "register" : undefined,
    notFoundMessage: "Nenhum paciente encontrado",
    clickRowClickHandle: true,
    header: [
      { name: "account__name", label: "Nome", widthInPercentage: "50%", width: "300px" },
      { name: "account__document_number", label: "CPF", widthInPercentage: "25%", width: "150px", displayStringConvertionFunction: documentsStringFormatter },
      { name: "patientphonenumber", label: "Telefone", widthInPercentage: "25%", width: "200px", displayStringConvertionFunction: phoneNumberStringFormatter },
    ],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(this.searchString, `${invertString}${value}`, clinicId, this.listTemplate.is_active);
      this.orderingItem = value;
      this.listTemplate.selectedOrdering = value;
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(value, this.orderingItem, clinicId, this.listTemplate.is_active);
    },
    onClinicFocusOut: (value: string) => {
      this.clinicIdList = value;
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(this.searchString, this.orderingItem, clinicId, this.listTemplate.is_active);
    },
    onClickActive: !this.isSuperUserOrStaff ? undefined : () => {
      this.listTemplate.is_active = !this.listTemplate.is_active;
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(this.searchString, this.orderingItem, clinicId, this.listTemplate.is_active);
    },
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
    exportUrl: (this.sessionManager.getUserPermission(PermissionConst.export_patient) && this.sessionManager.getUserPermission(PermissionConst.view_patient)) ? '/patients/export' : undefined,
    exportOptions: {
      id: 'ID',
      phone_numbers: 'Telefones',
      address: 'Endereço',
      display_name: 'Nome de tratamento',
      name: 'Nome',
      gender: 'Gênero',
      birthdate: 'Data de nascimento',
      email: 'E-mail',
      document_number: 'CPF',
      identity_number: 'RG',
      marital_status: 'Estado civil',
      profession: 'Profissão',
      mother_name: 'Nome da mãe',
      father_name: 'Nome do pai',
      notes: 'Observações',
      tags: 'Tags'
    }
  };

  constructor(private patientService: PatientService, private sessionManager: SessionManagerService, private titleService: Title) {
    this.titleService.setTitle('Chega de Ronco - Pacientes');
  }

  ngOnInit(): void {
    this.initializeList();
  }
  initializeList() {
    this.fetchList("", "name", this.clinicId, this.listTemplate.is_active);
  }

  fetchList(searchString: string, orderingItem: string, clinicId: string, isActive: boolean | undefined) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;
    if (this.request) {
      this.request.unsubscribe();
    }
    this.request = this.patientService.patientsList(searchString, orderingItem, clinicId, isActive).subscribe(response => {
      this.listTemplate.loading = false;
      this.listTemplate.data = response;
      this.listTemplate.hasNext = response.next != null;

    }, (error) => {
      const response = error as HttpErrorResponse;
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = response.error['detail'];
    });

  }

  loadMore(page: number) {
    this.listTemplate.loadingNext = true;
    let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
    this.patientService.patientsList(this.searchString, this.orderingItem, clinicId, this.listTemplate.is_active, page).subscribe(response => {
      this.listTemplate.hasNext = response.next != null;
      response.results!.map(item => this.listTemplate.data!.results!.push(item));
      this.listTemplate.loadingNext = false;
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = response.error['detail'];
    });
  }
}
