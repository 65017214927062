import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OptionModel } from '../../models/option';

@Component({
  selector: 'dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss']
})
export class DropdownInputComponent implements OnInit {

  @Input() id!: string;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() optionList!: OptionModel[];
  @Input() formGroup!: FormGroup;
  @Output() onValueChange = new EventEmitter<string>();
  @Input() removeVerticalSpacing: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  onValueChangeHandler() {
    this.onValueChange.emit(this.formGroup.get(this.id)?.value);
  }

}
