import {Component, OnInit, ViewChild} from '@angular/core';
import {SessionManagerService} from "../../../core/services/session-manager.service";
import {PatientModel} from "../../../core/models/patient.model";
import {Router} from "@angular/router";
import {PolysomnographyModel} from "../../../core/models/polysomnography.model";
import {PatientQuizService} from "../../../core/services/patient-quiz.service";
import {Question} from "../../../core/models/question.model";
import {Answer} from "../../../core/models/answer.model";
import {ToastrService} from "ngx-toastr";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
  patient?: PatientModel | null;
  polysomnography?: PolysomnographyModel;

  loadingPolysomnographies = false;
  loadingQuiz = false;
  sendingAnswer = false;

  questions: Question[] = [];
  questionStack: Question[] = [];

  ignoredTypes: string[] = ['TITLE', 'VIDEO'];

  images: File[] = [];

  finished = false;

  constructor(private sessionManager: SessionManagerService, private router: Router, private toastr: ToastrService,
              private patientQuizService: PatientQuizService, private sanitizer: DomSanitizer,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.patient = this.sessionManager.getPatientData();
    if (!this.patient) {
      this.router.navigate(['/pacientes/cadastro']);
      return;
    }

    this.fetchPolysomnographies();
  }

  get isLoading(): boolean {
    return this.loadingPolysomnographies || this.loadingQuiz || this.sendingAnswer;
  }

  get title(): string {
    if ((this.currentQuestion?.order ?? 0) <= 10) {
      return 'Teste de Sonolência';
    }
    return 'Questionário de Saúde';
  }

  get currentQuestion(): Question | null {
    if (this.questionStack.length > 0) {
      let lastIndex = this.questionStack.length - 1;
      let question: Question = this.questionStack[lastIndex];
      // if (question.type == 'IMAGE') {
      //   this.images = question.answer;
      // }
      return question;
    }
    return null;
  }

  fetchPolysomnographies(): void {
    this.loadingPolysomnographies = true;
    this.patientQuizService.listPolysomnographies(this.patient!.id!).subscribe({
      next: response => {
        this.loadingPolysomnographies = false;
        if (response.results && response.results.length > 0) {
          this.polysomnography = response.results[0];
          this.fetchQuiz();
        }
      }, error: error => {
        this.loadingPolysomnographies = false;
      }
    });
  }

  fetchQuiz(): void {
    this.loadingQuiz = true;
    this.patientQuizService.listQuizQuestions(this.patient!.id!, this.polysomnography!.id!).subscribe({
      next: response => {
        this.loadingQuiz = false;
        if (response.length > 0) {
          this.questions = response;
          if (this.questions[this.questions.length - 1]) {
            this.questions.pop();
          }
          let question = this.questions[0];
          this.checkIfQuestionIsFilled(question);
        }
      }, error: error => {
        this.loadingQuiz = false;
        console.error(error);
      }
    });
  }

  answerToString(answer: any) {
    if (answer == null) {
      return '';
    }
    return answer.toString();
  }

  getEpworthAnswer(question: Question) {
    let epworthQuestions = this.questions.filter(element => element.variables == 'quiz');
    let questionIndex: number = epworthQuestions.indexOf(question);
    let answer = this.answerToString(question.answer);
    let splitedAnswer = answer.split(',');
    if (questionIndex < splitedAnswer.length) {
      return splitedAnswer[questionIndex];
    }
    return '';
  }

  checkIfQuestionIsFilled(question: Question): void {
    this.questionStack.push(question);

    if (question.answer != null || this.ignoredTypes.includes(question.type)) {
      console.log(question.question, question, this.getNextQuestionId(question));
      let nextQuestionId: number | null;
      if (question.type != 'RADIO') {
        if (question.type == 'IMAGE') {
          this.images = question.answer;
        }

        if (question.next_question_id != null) {
          nextQuestionId = question.next_question_id;
        } else {
          this.endQuiz();
        }
      } else {
        if (question.next_question_id != null) {
          nextQuestionId = question.next_question_id;
        } else {
          nextQuestionId = this.getNextQuestionId(question);
        }

        if (Array.isArray(question.data)) {
          for (let option of question.data) {
            if (option.label == question.answer) {
              nextQuestionId = option.next_question_id;
              break;
            }
          }
        }
      }

      let nextQuestion: Question | undefined = this.questions.find(element => element.id == nextQuestionId);
      let isIgnoredType: boolean = this.ignoredTypes.includes(question.type);

      if (!nextQuestion) {
        this.endQuiz();
        return;
      }
      if (this.ignoredTypes.includes(nextQuestion!.type) || isIgnoredType && nextQuestion?.answer != null || !isIgnoredType) {
        this.checkIfQuestionIsFilled(nextQuestion);
      }

      if (question.variables == 'quiz') {
        question.answer = this.getEpworthAnswer(question);
      }
    }
  }

  addQueryParamsToGTM() {
    if (this.answerMustBeSent()) {
      this.router.navigate([], {
        queryParams: {question: this.currentQuestion?.question}
      })
    }
  }

  previousQuestion() {
    if (this.questionStack.length > 1) {
      this.questionStack.pop();
    }
  }

  answerMustBeSent(): boolean {
    if (this.ignoredTypes.includes(this.currentQuestion?.type ?? '')) {
      return false;
    } else {
      return true;
    }
  }

  async nextQuestion() {
    if (this.answerMustBeSent()) {
      let answer: Answer = {} as Answer;
      answer.quiz_question_id = this.currentQuestion?.id;
      answer.answer = this.currentQuestion?.answer;

      if (this.currentQuestion?.type == 'RADIO') {
        this.currentQuestion.next_question_id = this.getNextQuestionId(this.currentQuestion);
      }

      if (this.currentQuestion?.variables == 'quiz') {
        if (this.currentQuestion?.answer == null) {
          this.toastr.error('Selecione uma das opções');
          return;
        }

        let epworthQuestions: Question[] = this.questions.filter(question => question.variables == 'quiz');
        let answers: string[] = epworthQuestions.map(question => question.answer);
        answer.answer = answers.join(',');
      } else if (this.currentQuestion?.type == 'CHECKBOX') {
        // answer.answer ??= [];
        if (!answer.answer) {
          answer.answer = [];
        }
      } else if (this.currentQuestion?.type == 'IMAGE') {
        if (!answer.answer || (Array.isArray(answer.answer) && answer.answer.length == 0)) {
          this.toastr.error('Necessário anexar uma imagem');
          return;
        }

        let tempAnswer: File[] = [];

        for (let image of this.images) {
          if (!(image instanceof File)) {
            try {
              this.sendingAnswer = true;
              let filename = new Date().getTime().toString();
              let response = await fetch(image, {mode: 'cors'});
              let blob = await response.blob();
              let file = new File([blob], filename);
              tempAnswer.push(file);
            } catch (e) {
              this.sendingAnswer = false;
              console.error(e);
            }
          } else {
            tempAnswer.push(image);
          }
        }

        this.images = tempAnswer;
        answer.answer = tempAnswer;
      } else {
        answer.answer = this.currentQuestion?.answer;
        if (this.currentQuestion?.type == 'NUMBER') {
          answer.answer = parseInt(this.currentQuestion?.answer);
        } else if (this.currentQuestion?.type == 'FLOAT') {
          let replacedAnswer = this.currentQuestion?.answer?.replaceAll(',', '.');
          answer.answer = parseFloat(replacedAnswer);
        }
      }

      try {
        this.sendingAnswer = true;
        await this.patientQuizService.answerQuestion(this.patient!.id!, this.polysomnography!.id!, answer).toPromise();
        this.sendingAnswer = false;
      } catch (error) {
        console.error(error);
        this.sendingAnswer = false;
        this.toastr.error('Erro ao enviar resposta');
        return;
      }
    }

    if (this.currentQuestion && this.currentQuestion != this.questions[this.questions.length - 1]) {
      if (this.currentQuestion.next_question_id == null) {
        this.currentQuestion.next_question_id = this.getNextQuestionId(this.currentQuestion);
      }
      let nextQuestion: Question | undefined = this.questions.find(question => question.id == this.currentQuestion?.next_question_id);
      if (nextQuestion) {
        this.questionStack.push(nextQuestion);
        this.addQueryParamsToGTM();
      } else {
        this.endQuiz();
      }
    } else {
      this.endQuiz();
    }
  }

  endQuiz() {
    let questionIds: number[] = this.questionStack.map(question => question.id);
    this.sendingAnswer = true;
    this.patientQuizService.finishQuiz(this.patient!.id!, this.polysomnography!.id!, questionIds).subscribe({
      next: response => {
        this.toastr.success('Questionário finalizado com sucesso');
        this.sessionManager.clearPatientData();
        this.finished = true;
        this.sendingAnswer = false;
      }, error: error => {
        this.sendingAnswer = false;
        this.toastr.error('Erro ao finalizar o questionário');
        console.error(error);
      }
    });
  }

  getNextQuestionId(question: Question): number | null {
    let currentQuestionIndex: number = this.questions.findIndex(_question => _question.id == question.id);

    if (question.type == 'RADIO' && question.variables != 'quiz' && Array.isArray(question.data)) {
      let option = question.data.find(_option => _option.label == question.answer);
      question.next_question_id = option.next_question_id;
    }

    if (!question.next_question_id && this.questions.length >= currentQuestionIndex) {
      let nextQuestion: Question = this.questions[currentQuestionIndex + 1];
      return nextQuestion.id;
    } else {
      return question.next_question_id || null;
    }
  }

  toggleCheckbox(answer: string) {
    if (this.currentQuestion) {
      if (!this.currentQuestion.answer) {
        this.currentQuestion.answer = [];
      }

      if (this.currentQuestion.answer.includes(answer)) {
        this.currentQuestion.answer = this.currentQuestion?.answer.filter((_answer: string) => _answer != answer);
      } else {
        this.currentQuestion.answer.push(answer);
      }
    }
  }

  readImage(file: File | string): SafeUrl {
    if (file instanceof File) {
      return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
    } else {
      return file;
    }
  }

  addImage(event: any) {
    if (this.isSelfie(this.currentQuestion!)) {
      this.images = [];
      this.currentQuestion!.answer = [];
    }
    this.images.push(...event.target.files);
    if (!this.currentQuestion!.answer) {
      this.currentQuestion!.answer = [];
    }
    this.currentQuestion!.answer.push(...event.target.files);
  }

  removeImage(file: File | string) {
    this.images = this.images.filter(image => image != file);
    this.currentQuestion!.answer = this.currentQuestion!.answer.filter((image: any) => image != file);
  }

  isSelfie(question: Question) {
    return question.question.includes('Selfie');
  }

  toggleVideo(video: HTMLVideoElement) {
    if (video.muted) {
      video.currentTime = 0;
    }

    if (video.muted || video.paused) {
      video.play();
      video.muted = false;
      video.volume = 100;
    } else {
      video.pause();
    }
  }
}
