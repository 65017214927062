<div class="row p-0 m-0 header">
    <div class="col-12">
        <app-header title="Pacientes" subTitle="Lista de pacientes"></app-header>
    </div>
</div>
<div class="row p-0 m-0 content">
    <div class="col-12">
      <list-renderer [listTemplate]="listTemplate" (onNextPage)="loadMore($event)"></list-renderer>
    </div>
</div>
