import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { InterceptorsModule } from './interceptors/interceptors.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    InterceptorsModule,
    HttpClientModule
  ]
})
export class CoreModule { }
