import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistersComponent } from './registers.component';
import { RouterModule } from '@angular/router';
import { NavigationModule } from '../navigation/navigation.module';
import { ClinicsModule } from './clinics/clinics.module';
import { ProfessionalsModule } from './professionals/professionals.module';
import { PatientTagsModule } from './patient-tags/patient-tags.module';
import { ProfessionsModule } from './professions/professions.module';
import { SystemUsersModule } from './system-users/system-users.module';
import { UserGroupsModule } from './user-groups/user-groups.module';
import { ClinicTagsModule } from './clinic-tags/clinic-tags.module';
import { ProfessionalTagsModule } from './professional-tags/professional-tags.module';
import { SpecialtiesModule } from './specialties/specialties.module';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { ClinicUsersModule } from './clinic-users/clinic-users.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import {CouponsModule} from "./coupons/coupons.module";

@NgModule({
  declarations: [
    RegistersComponent,
    ResetPasswordComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    RenderersModule,
    NavigationModule,
    ClinicsModule,
    ProfessionalsModule,
    SystemUsersModule,
    UserGroupsModule,
    ProfessionsModule,
    PatientTagsModule,
    ClinicTagsModule,
    ProfessionalTagsModule,
    SpecialtiesModule,
    CouponsModule,
    ClinicUsersModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class RegistersModule { }
