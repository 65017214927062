import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NavigationModule } from '../navigation/navigation.module';

import { PatientRegisterComponent } from './patient-register/patient-register.component';
import { PatientsListComponent } from './patients-list/patients-list.component';
import { PatientEditModule } from './patient-edit/patient-edit.module';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    PatientsListComponent,
    PatientRegisterComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    RenderersModule,
    NavigationModule,
    PatientEditModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule,
    SharedModule
  ]
})
export class PatientsModule { }
