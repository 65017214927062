import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SessionManagerService } from "../../../core/services/session-manager.service";

@Component({
  selector: 'app-registers',
  templateUrl: './registers.component.html',
  styleUrls: ['./registers.component.scss']
})
export class RegistersComponent implements OnInit {

  titleMap: string[][] = [
    ["clinics", "Clínicas"],
    ["clinicUsers", "Usuários de Clínica"],
    ["professionals", "Profissionais"],
    ["systemUsers", "Usuários do Sistema"],
    ["schedules", "Agendas"],
    ["healthInsurances", "Convênios"],
    ["professions", "Profissões"],
    ["patientTags", "Tags de Pacientes"],
    ["clinicTags", "Tags de Clínicas"],
    ["professionalTags", "Tags de Profissionais"],
    ["specialties", "Áreas de Atuação"],
    ["coupons", "Cupons"],
  ];

  headerSubTitle: string = '';
  showMenuSidebar: boolean = false;
  // role?: string = 'STAFF';

  get isStaff() {
    return this.role == 'STAFF';
  }

  get isClinic() {
    return this.role == 'CLINIC';
  }

  get isProfessional() {
    return this.role == 'PROFESSIONAL';
  }

  get role() {
    return this.sessionManager.accountData.role;
  }

  get routerLinkUsers() {
    if (this.isClinic) {
      return 'clinicUsers';
    }
    if (this.isProfessional) {
      return 'systemUsers';
    }
    return 'systemUsers';
  }


  constructor(private router: Router, private sessionManager: SessionManagerService) {

    if (this.router.url.split("/")[3] != undefined) this.headerSubTitle = this.titleMap[0][1];
    else this.headerSubTitle = (this.titleMap.filter((route) => route[0] == router.url.split("/")[3])[0][1]);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.split("/")[2] == 'registers') {
          if (this.router.url.split("/")[3] != undefined) {
            this.headerSubTitle = (this.titleMap.filter((route) => route[0] == this.router.url.split("/")[3])[0][1]);
          }
          else {
            this.headerSubTitle = this.titleMap[0][1];
          }
        }
      }
    })
  }

  menuButtonHandler() {
    this.showMenuSidebar = !this.showMenuSidebar;
  }

  ngOnInit(): void {
  }
}
