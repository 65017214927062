import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {QuizSummaryVideo} from "../models/quiz-summary-video.model";

@Injectable({
  providedIn: 'root'
})
export class QuizSummaryVideoService {

  constructor(private http: HttpClient) { }

  createQuizSummaryVideo(data: QuizSummaryVideo): Observable<QuizSummaryVideo> {
    return this.http.post<QuizSummaryVideo>(`${environment.API_URL}/quiz_summary_video/`, data)
  }

  getQuizSummaryVideo(code: string): Observable<QuizSummaryVideo> {
    return this.http.get(`${environment.API_URL}/quiz_summary_video/${code}/`);
  }

}
