import { Component, OnInit } from '@angular/core';
import {PatientService} from "../../../core/services/patient.service";
import {FormBuilder, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {Options} from "../../../shared/components/select-input/models/Options";
import {QuizSummaryVideoService} from "../../../core/services/quiz-summary-video.service";
import {Router} from "@angular/router";
import {QuizSummaryVideo} from "../../../core/models/quiz-summary-video.model";

@Component({
  selector: 'app-select-video',
  templateUrl: './select-video.component.html',
  styleUrls: ['./select-video.component.scss']
})
export class SelectVideoComponent implements OnInit {

  patientList: Options[] = [];
  formGroup = this.fb.group({
    patient_id: [null, [Validators.required]],
    country_code: ['+55', [Validators.required]],
    phone_number: [null, [Validators.required]],
    video_url: [null, [Validators.required]]
  });

  fetching: boolean = true;
  submitting: boolean = false;

  constructor(private patientService: PatientService,private fb: FormBuilder, private toast: ToastrService,
              private quizSumamryVideService: QuizSummaryVideoService, private router: Router) { }

  ngOnInit(): void {
    this.fetchPatiens();
  }

  fetchPatiens() {
    this.patientService.patientsAll().subscribe(response => {
      this.patientList = response.map(item => {
        return {
          value: item.id?.toString(),
          label:`${item.name} - CPF ${item.document_number}`,
        } as Options
      });
      this.fetching = false;
    }, error => {
      let errorMesage = error['detail']
      this.toast.error(errorMesage, 'Erro')
    })
  }

  submitHandler() {
    this.formGroup.markAllAsTouched();
    this.submitting = true
    let quizSummaryVideo = this.formGroup.getRawValue() as QuizSummaryVideo;
    let patientId = quizSummaryVideo.patient_id;
    if (this.formGroup.valid) {
      this.quizSumamryVideService.createQuizSummaryVideo(quizSummaryVideo).subscribe(response => {
        this.toast.success('Recurso criado com sucesso', 'Sucesso')
        let quizSummaryVideoCode = response.code;
        this.router.navigate([`pacientes/video/${quizSummaryVideoCode}`]);
      }, error => {
        this.fetching = false;
        let errorMesage = error['detail']
        this.toast.error(errorMesage, 'Erro')
      })
    }
    this.submitting = false;
  }

}
