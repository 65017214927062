import { Component, ElementRef, Inject, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import {  Observable } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { FormInput, inputType } from '../models/form-input';
import { FormTemplate } from '../models/form-template';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { FieldValidator } from '../utils/field-validator/field-validator';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'form-renderer',
  templateUrl: './form-renderer.component.html',
  styleUrls: ['./form-renderer.component.scss']
})
export class FormRendererComponent implements OnInit {

  @Input() formTemplate!: FormTemplate;
  @Input() isLoading!: boolean;

  @Output() submit = new EventEmitter();
  @Output() remove = new EventEmitter();

  formDisabled: boolean = false;

  formGroup!: FormGroup;

  constructor(
    private router: Router, config: NgbModalConfig,
    private modalService: NgbModal,
    @Inject(DOCUMENT) private document: Document) {
    config.backdrop = 'static';
    config.keyboard = false;

  }

  ngOnInit(): void {
    if (this.formTemplate.renderOnInit == true || this.formTemplate.renderOnInit == undefined)
      this.initializeForm();
  }

  initializeForm() {
    const renderedFormGroup: FormGroup = new FormGroup({});

    for (let sep = 0; sep < this.formTemplate.separators!.length; sep++) {
      const separatorData = this.formTemplate.separators![sep];

      const separatorFormGroup: FormGroup = new FormGroup({});

      if (separatorData.imageInput) {
        const formControl: FormControl = new FormControl("imageInput");
        separatorFormGroup.addControl("imageInput", formControl);
      }

      for (let ctrl = 0; ctrl < separatorData.inputs!.length; ctrl++) {
        const formControlData: FormInput = separatorData.inputs![ctrl];

        if (formControlData.type != inputType.MULTIDATA) {
          if (formControlData.type == inputType.CHECKBOX || formControlData.type == inputType.CHECKBOX2) {
            const formArray: FormArray = new FormArray([], formControlData.validators!);

            if (formControlData.dropdownOptions) {
              for (let option = 0; option < formControlData.dropdownOptions!.length; option++) {
                if (formControlData.dropdownOptions![option].checked) formArray.push(new FormControl(formControlData.dropdownOptions![option].name));
              }
            }

            separatorFormGroup.addControl(formControlData.name!, formArray);
          }
          else if (formControlData.type != inputType.IMAGE) {
            const formControl: FormControl = new FormControl(formControlData.value, formControlData.validators!);
            separatorFormGroup.addControl(formControlData.name!, formControl);
          }
        }
        else {
          const multiDataInputArray: FormArray = new FormArray([]);

          for (let value = 0; value < formControlData.value!.length; value++) {
            const multiDataInputGroup: FormGroup = new FormGroup({});

            for (let multiDataCtrl = 0; multiDataCtrl < formControlData.multiDataInputs!.length; multiDataCtrl++) {
              if (formControlData.multiDataInputs![multiDataCtrl].type != inputType.DDI) {
                const formControl: FormControl = new FormControl(formControlData.value[value][formControlData.multiDataInputs![multiDataCtrl].name], formControlData.multiDataInputs![multiDataCtrl].validators!);
                multiDataInputGroup.addControl(formControlData.multiDataInputs![multiDataCtrl].name, formControl);
              }
              else {
                const formControl: FormControl = new FormControl(formControlData.value[value].country_code ? formControlData.value[value].country_code : "+55", [Validators.required]);
                multiDataInputGroup.addControl("country_code", formControl);

                const formControl2: FormControl = new FormControl(formControlData.value[value].phone_number, [Validators.required]);
                multiDataInputGroup.addControl("phone_number", formControl2);
              }
            }

            multiDataInputArray.push(multiDataInputGroup);
          }

          separatorFormGroup.addControl(formControlData.name, multiDataInputArray);
        }
      }

      renderedFormGroup.addControl(separatorData.groupName!, separatorFormGroup);
    }

    this.formTemplate.rendered = true;
    this.formGroup = renderedFormGroup;
    if(this.formTemplate.disabled) {
      this.formDisabled = true;
    }
  }

  formCancelHandler() {
      this.router.navigate([this.formTemplate.cancelLink])
    }

  formSubmitHandler() {
    this.formTemplate.loading = true;
    this.submit.emit();
  }

  scrollToFirstInvalidControl() {
    this.document.getElementById('main-container')?.scroll({top: 0});
  }

  formRemoveHandler() {
    this.formTemplate.loading = true;
    this.remove.emit();
  }

  onDropdownChange(separatorIndex: number, inputIndex: number, value: string) {
    this.formTemplate.separators![separatorIndex].inputs![inputIndex].onValueChange!(value);
  }

  onImageChange(separatorIndex: number, file: File) {
    this.formTemplate.separators![separatorIndex].onImageSelect!(file);
  }

  onSubSelectorChange(separatorIndex: number, inputIndex: number, value: string) {
    this.formTemplate.separators![separatorIndex].inputs![inputIndex].onValueChange!(value);
  }

  onZipCodeChange(separatorIndex: number, inputIndex: number, value: string) {
    this.formTemplate.separators![separatorIndex].inputs![inputIndex].onValueChange!(value);
  }

  findMultiDataFormArray(groupName: string, fieldId: string): FormArray {
    return (this.formGroup.get(groupName) as FormGroup).get(fieldId) as FormArray;
  }

  findNestedFormGroup(groupName: string): FormGroup {
    return (this.formGroup.get(groupName) as FormGroup);
  }

  findFormSub(groupName: string, subName: string): FormGroup {
    return (this.findNestedFormGroup(groupName).get(subName) as FormGroup);
  }

  formExitHandler(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.formGroup && (this.formGroup.dirty && this.formTemplate.submitted == false)) {
      const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.text = "As alterações no formulário não foram salvas e serão descartadas, deseja prosseguir?";
      return modalRef.result
    }
    else
      return true;
  };

  updateClinicDocumentField(type: string) {
    if (type == "1") {
      this.formGroup.get("clinicData")?.get("document_number")?.setValidators([Validators.required, FieldValidator.documentValidator()])
    }
    else {
      this.formGroup.get("clinicData")?.get("document_number")?.setValidators([Validators.required, FieldValidator.document3Validator()])
    }
  }
}
