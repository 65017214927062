import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardModule } from './dashboard/dashboard.module';
import { PublicModule } from './public/public.module';
import { RenderersModule } from '../shared/renderers/renderers.module';

@NgModule({
  imports: [
    CommonModule,
    PublicModule,
    RenderersModule,
    DashboardModule
  ],
  declarations: [
  ]
})
export class FeaturesModule { }
