import {Component, OnInit} from '@angular/core';
import {SessionManagerService} from "../../../core/services/session-manager.service";
import {PatientModel} from "../../../core/models/patient.model";
import {Router} from "@angular/router";
import {PatientQuizService} from "../../../core/services/patient-quiz.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-confirm-code',
  templateUrl: './confirm-code.component.html',
  styleUrls: ['./confirm-code.component.scss']
})
export class ConfirmCodeComponent implements OnInit {
  patient?: PatientModel | null;

  formGroup: FormGroup = this.fb.group({
    country_code: ['', [Validators.required]],
    phone_number: ['', [Validators.required]],
    code: ['', [Validators.required]]
  });

  loading = false;

  constructor(private fb: FormBuilder, private router: Router, private sessionManager: SessionManagerService,
              private patientQuizService: PatientQuizService) {
  }

  ngOnInit(): void {
    this.patient = this.sessionManager.getPatientData();
    if (!this.patient) {
      this.router.navigate(['/pacientes/cadastro']);
    } else {
      this.formGroup.patchValue({
        country_code: this.patient.country_code,
        phone_number: this.patient.phone_number
      });
    }
  }

  backToRegister(): void {
    this.router.navigate(['/pacientes/cadastro']);
  }

  submit(): void {
    if (this.formGroup.valid) {
      let data = this.formGroup.getRawValue();
      this.loading = true;
      this.patientQuizService.confirmCodeAsPatient(data).subscribe({
        next: response => {
          this.sessionManager.setPatientData(response);
          this.loading = false;
          this.router.navigate(['/pacientes/questionario']);
        }, error: error => {
          this.loading = false;
          console.log(error);
        }
      });
    }
  }
}
