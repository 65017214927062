import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AccountModel } from 'src/app/core/models/account.model';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { PermissionModel } from 'src/app/core/models/permission.model';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { ClinicsService } from 'src/app/core/services/clinics.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/modals/confirmation-modal/confirmation-modal.component';
import { Options } from 'src/app/shared/components/select-input/models/Options';
import { FieldValidator } from 'src/app/shared/renderers/utils/field-validator/field-validator';

@Component({
  selector: 'app-system-users-form',
  templateUrl: './system-users-form.component.html',
  styleUrls: ['./system-users-form.component.scss']
})
export class SystemUsersFormComponent implements OnInit {

  formGroup = this.fb.group({
    id: [],
    name: [''],
    email: ['', [Validators.email]],
    birthdate: [''],
    document_number: ['', [FieldValidator.documentValidator()]],
    is_superuser: [],
    user_permissions: this.fb.array([]),
    clinics: [[]],
    country_code: ['+55', [Validators.required]],
    phone_number: [null, [Validators.required]]
  });

  constructor(@Inject(DOCUMENT) private document: Document, private activatedRoute: ActivatedRoute, private fb: FormBuilder,
    private router: Router, config: NgbModalConfig, private modalService: NgbModal, private clinicsService: ClinicsService,
    private accountsService: AccountsService, private toast: ToastrService, private sessionManager: SessionManagerService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  imageFile!: File | null;
  changedImage: boolean = false;
  image!: string;

  loadingAccount: boolean = false;
  isRegister: boolean = true;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  changePasswordButton: String = "Redefinir senha"
  removeButton: String = "Desativar";
  submitButton: String = "Salvar";
  button: boolean = false;

  clinicList!: Options[];
  fetchingPermissions: boolean = false;

  userPermissions!: PermissionModel[];
  checked = true;
  disabled = false;
  disabledPermissions: string[] = [];

  id!: number;
  existingAccountData!: AccountModel;
  fetchingAccount: boolean = false;

  checkedAdmin: boolean = false;
  is_active: boolean = true;

  passwordChange: boolean = false;

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params["id"];
      let idSession = this.sessionManager.getSessionData().id;
      if (this.id.toString() == idSession?.toString()) {
        this.passwordChange = true;
      }
      if (this.id) {
        this.isRegister = false;
      }
    });
    this.initializeForm();
  }

  initializeForm() {
    this.fetchUserPermissions();
    if (this.id) setTimeout(() => this.fetchAccountExistingData(), 1000);
  }

  get fetchingData(): boolean {
    if (this.fetchingPermissions) {
      if (this.isRegister) {
        return true;
      } else {
        if (this.fetchingAccount) return true;
      }
    }
    return false;
  }

  get canSave() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_account);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_account);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_account);
  }

  cancelHandler() {
    this.router.navigate(['dashboard/registers/systemUsers']);
  }

  searchAccount() {
    let phoneNumber = this.formGroup.get('phone_number')?.value;
    let countryCode = this.formGroup.get('country_code')?.value;
    let encodedCountryCode = encodeURIComponent(countryCode);
    this.loadingAccount = true;
    this.accountsService.accountSearchByPhoneNumber(phoneNumber, encodedCountryCode).subscribe(response => {
      if (response) {
        this.setForm(response);
      }
      this.loadingAccount = false;
    }, (error) => {
      this.loadingAccount = false;
    });
  }

  setForm(data: any) {
    if(!data.account) {
      if(data.is_staff || data.is_superuser) {
          this.router.navigate([`dashboard/registers/systemUsers/edit/${data.id}`]);
      } else {
        this.formGroup.patchValue(data);
      }
    } else {
      this.formGroup.patchValue(data.account);
    }
  }

  removeHandler() {
    this.submittingRemove = true;
    let message = 'Deseja desativar este Usuário?';
    if (this.existingAccountData.is_patient && this.existingAccountData.is_professional) {
      message = 'Desativando este Usuario vc vai desabilitar o acesso dele como profissional e paciente.';
    } else if (this.existingAccountData.is_patient) {
      message = 'Desativando este Usuario vc vai desabilitar o acesso dele como paciente.';
    } else if (this.existingAccountData.is_professional) {
      message = 'Desativando este Usuario vc vai desabilitar o acesso dele como profissional.';
    }
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = message;
    modalRef.result.then((result) => {
      if (result == true) {
        this.accountsService.remove(this.id).subscribe(() => {
          this.toast.success('Usuário removido com sucesso', "Sucesso");
          this.cancelHandler();
        }, (errorResponse: HttpErrorResponse) => {
          this.submittingRemove = false;
          this.mapErrorResponse(errorResponse);
        });
      } else {
        this.submittingRemove = false;
      }
    });
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();
    let accountData = this.formGroup.getRawValue() as AccountModel;
    accountData.is_superuser = this.checkedAdmin;
    accountData.is_staff = true;
    if (accountData.clinics == null) accountData.clinics = undefined;
    if (this.isRegister) {
      this.accountRegister(accountData);
    } else {
      this.accountEdit(accountData);
    }
  }

  accountEdit(accountData: AccountModel) {
    accountData.is_active = true;
    this.accountsService.edit(accountData).subscribe(res => {
      if (this.changedImage)
        this.accountsService.uploadImage(this.imageFile!, res.id!).subscribe(() => { });
      this.toast.success("Usuário alterado com sucesso", "Sucesso");
      this.cancelHandler();
      this.submitting = false;
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.toast.error('Verifique se todos os campos obrigatórios foram preenchidos corretamente.', 'Erro');
      this.submitting = false;
    });
  }

  accountRegister(accountData: AccountModel) {
    accountData.id = undefined;
    this.accountsService.register(accountData).subscribe(res => {
      if (this.changedImage) {
        this.accountsService.uploadImage(this.imageFile!, res.id!).subscribe(() => { });
      }
      this.toast.success("Usuário criado com sucesso", "Sucesso");
      this.cancelHandler();
      this.submitting = false;
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.toast.error('Verifique se todos os campos obrigatórios foram preenchidos corretamente.', 'Erro');
      this.submitting = false;
    });
  }

  onImageSelect(file: any) {
    this.imageFile = file;
    this.changedImage = true
  }


  get permissionsMap() {
    let mapping = {};
    this.userPermissions.map((permission) => {
      if (permission.id)
        Object.assign(mapping, { [permission.id]: permission.codename });
    });
    return mapping;
  }

  get permissionModels() {
    return Object.keys(this.groupBy(this.userPermissions, 'model'));
  }

  getPermissions(name: string): [] {
    return this.groupBy(this.userPermissions, 'model')[name];
  }

  groupBy(arr: any[], property: string) {
    return arr.reduce((acc, cur) => {
      acc[cur[property]] = [...acc[cur[property]] || [], cur];
      return acc;
    }, {});
  }

  onCheckChange(event: any) {
    let userPermissions: FormArray = this.formGroup.get('user_permissions') as FormArray;

    if (event.target.checked) {
      userPermissions.push(this.fb.control(event.target.value));
      this.enableCheckbox(event.target.id);
    } else {
      let controls = userPermissions.controls;
      userPermissions = this.fb.array(controls.filter(control => control.value != event.target.value));
      this.formGroup.setControl('user_permissions', userPermissions);
      this.disableCheckbox(event.target.id);
    }
  }

  isDisabled(permission: PermissionModel) {
    if (!this.sessionManager.getUserPermission(PermissionConst.change_account))
      return true;
    return this.disabledPermissions.some(codename => codename == permission!.codename);
  }

  isChecked(permission: PermissionModel) {
    if (this.isDisabled(permission))
      return false;
    let formArray = this.formGroup.get('user_permissions') as FormArray;
    return formArray.value.some((id: number) => id == permission.id);
  }

  disableCheckbox(codename: string) {
    let permissionModel = codename.split('_')[1];
    let userPermissions: FormArray = this.formGroup.get('user_permissions') as FormArray;
    let controls = userPermissions.controls;

    if (codename.includes('view')) {
      this.disabledPermissions.push(`change_${permissionModel}`);
      this.disableCheckbox(`change_${permissionModel}`);
      this.disabledPermissions.push(`export_${permissionModel}`);
      this.disableCheckbox(`export_${permissionModel}`);
    } else if (codename.includes('change')) {
      this.disabledPermissions.push(`add_${permissionModel}`);
      this.disableCheckbox(`add_${permissionModel}`);
    } else if (codename.includes('add')) {
      this.disabledPermissions.push(`remove_${permissionModel}`);
      this.disableCheckbox(`remove_${permissionModel}`);
    }

    this.disabledPermissions.map(codename => {
      let index = Object.values(this.permissionsMap).indexOf(codename);
      let id = Object.keys(this.permissionsMap)[index];
      controls = controls.filter(control => control.value != id);
    });
    userPermissions = this.fb.array(controls);
    this.formGroup.setControl('user_permissions', userPermissions);
  }

  enableCheckbox(codename: string) {
    let permissionModel = codename.split('_')[1];

    if (codename.includes('view')) {
      this.disabledPermissions = this.disabledPermissions.filter(_codename => _codename != `change_${permissionModel}`);
      this.disabledPermissions = this.disabledPermissions.filter(_codename => _codename != `export_${permissionModel}`);
    } else if (codename.includes('change')) {
      this.disabledPermissions = this.disabledPermissions.filter(_codename => _codename != `add_${permissionModel}`);
    } else if (codename.includes('add')) {
      this.disabledPermissions = this.disabledPermissions.filter(_codename => _codename != `remove_${permissionModel}`);
    }
  }

  toggleAllPermissions(event: any) {
    let userPermissions: FormArray = this.formGroup.get('user_permissions') as FormArray;
    if (event.target.checked) {
      this.userPermissions.map(permission => {
        userPermissions.push(this.fb.control(permission.id));
        this.enableCheckbox(permission.codename ? permission.codename : '');
      });
    } else {
      userPermissions = this.fb.array([]);
      this.formGroup.setControl('user_permissions', userPermissions);
      this.userPermissions.map(permission => {
        this.disableCheckbox(permission.codename ? permission.codename : '');
      });
    }

  }

  fetchUserPermissions() {
    this.accountsService.permissionsList().subscribe(response => {
      this.userPermissions = response;
      let userPermissions: FormArray = this.formGroup.get('user_permissions') as FormArray;
      this.userPermissions.map(permission => {
        userPermissions.push(this.fb.control(permission.id));
        this.enableCheckbox(permission.codename ? permission.codename : '');
      });
      this.fetchingPermissions = true;
    }, error => this.mapErrorResponse(error as HttpErrorResponse));
  }

  fetchAccountExistingData() {
    this.accountsService.getOne(this.id).subscribe(response => {
      this.existingAccountData = response;
      this.formGroup.patchValue(this.existingAccountData);
      this.checkedAdmin = this.existingAccountData.is_superuser ?? false;
      let permissionControls = this.fb.array([]);
      this.existingAccountData.user_permissions?.forEach(value => {
        let control = this.fb.control(value);
        permissionControls.push(control);
        let codename = PermissionConst[value];
        this.enableCheckbox(codename);
      });
      this.formGroup.setControl('user_permissions', permissionControls);
      if (this.existingAccountData.is_active) {
        this.changePasswordButton = "Redefinir senha"
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
        this.button = true;
      } else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
        this.is_active = false;
      }
      this.image = response.profile_image ?? '';
      if (this.fetchingPermissions) {
        this.fetchingAccount = true;
      }
      this.formGroup.get('is_superuser')?.disable();
      if (this.formGroup.get('country_code')?.value == null) {
        this.formGroup.get('country_code')?.setValue('+55');
      }
      this.formGroup.get('country_code')?.disable();
      this.formGroup.get('phone_number')?.disable();
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    } else {
      this.setFormErrors(errorResponse);
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "id", "name", "email", "birthdate", "document_number", "is_superuser", "user_permissions", "clinics"
    ];

    errNames.forEach(name => {
      if (errorResponse.error[name]) {
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
      }
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  };

  resetPassword() {
    this.router.navigate([`/dashboard/registers/systemUsers/reset_password/${this.id}`]);
  }

}
