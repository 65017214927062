import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { SpecialtyModel } from 'src/app/core/models/specialty.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-specialties-form',
  templateUrl: './specialties-form.component.html',
  styleUrls: ['./specialties-form.component.scss']
})
export class SpecialtiesFormComponent implements OnInit {


  formGroup = this.fb.group({
    id: [],
    name: [''],
  });

  get clinicId() {
    if (this.sessionManager.isSuperUserOrStaff()) {
      return '';
    }
    return this.sessionManager.getClinicCurrent().value?.toString();
  }

  constructor(@Inject(DOCUMENT) private document: Document, private activatedRoute: ActivatedRoute, private fb: FormBuilder,
    private router: Router, config: NgbModalConfig, private modalService: NgbModal, private registersService: RegistersService,
    private sessionManager: SessionManagerService, private toast: ToastrService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  isRegister: boolean = true;
  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton: String = "Desativar";
  submitButton: String = "Salvar";
  button: boolean = false;

  id!: number;
  existingSpecialtiesData!: SpecialtyModel;
  fetchingSpecialties: boolean = false;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params["id"];
      if (this.id) {
        this.isRegister = false;
      }
    });
    this.initializeForm();
  }

  initializeForm() {
    if (this.id) setTimeout(() => this.fetchSpecialtiesExistingData(), 1000);
  }

  get fetchingData(): boolean {
    if (this.isRegister) {
      return true;
    } else {
      if (this.fetchingSpecialties) return true;
    }
    return false;
  }

  get canSave() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_specialty);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_specialty);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_specialty);
  }

  cancelHandler() {
    this.router.navigate(['dashboard/registers/specialties']);
  }

  fetchSpecialtiesExistingData() {
    this.registersService.specialtyGet(this.clinicId, this.id).subscribe(response => {
      this.existingSpecialtiesData = response;
      this.formGroup.patchValue(this.existingSpecialtiesData);
      if(this.existingSpecialtiesData.is_active) {
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
        this.button = true;
      } else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
      }
      this.fetchingSpecialties = true;
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
    });
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar esta área de atuação?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.registersService.specialtyRemove(this.clinicId, this.id).subscribe(() => {
          this.toast.success('Área de atuação removida com sucesso', "Sucesso");
          this.cancelHandler();
        }, (errorResponse: HttpErrorResponse) => {
          this.submittingRemove = false;
          this.mapErrorResponse(errorResponse);
        });
      } else {
        this.submittingRemove = false;
      }
    });
  }

  submitHandler() {
    this.formGroup.markAllAsTouched();
    this.submitting = true;

    let specialtiesData = this.formGroup.getRawValue() as SpecialtyModel;
    if (this.isRegister) {
      this.specialtiesRegister(specialtiesData);
    } else {
      this.specialtiesEdit(specialtiesData);
    }
  }

  specialtiesEdit(specialtiesData: SpecialtyModel) {
    specialtiesData.is_active = true;
    this.registersService.specialtyEdit(this.clinicId, specialtiesData).subscribe(res => {
      this.toast.success('Área de atuação alterada com sucesso', 'Sucesso');
      this.submitting = false;
      this.cancelHandler();
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    });
  }

  specialtiesRegister(specialtiesData: SpecialtyModel) {
    specialtiesData.id = undefined;
    this.registersService.specialtyRegister(this.clinicId, specialtiesData).subscribe(res => {
      this.toast.success('Área de atuação alterada com sucesso', 'Sucesso');
      this.submitting = false;
      this.cancelHandler();
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    } else {
      this.setFormErrors(errorResponse);
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "id", "name"
    ];

    errNames.forEach(name => {
      if (errorResponse.error[name]) {
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
      }
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  };

}
