<div>
    <div class="error" *ngIf="formGroup.get(id)!.touched && formGroup.get(id)!.errors && formGroup.get(id)!.hasError('required'); else responseError">
        <label class="__label">Este
            campo é
            obrigatório</label>
    </div>

    <ng-template #responseError>
        <div class="error"
            *ngIf="formGroup.get(id)!.errors && formGroup.get(id)!.hasError('response'); else minlengthError">
            <label class="__label">{{formGroup.get(id)!.errors!.response}}</label>
        </div>
    </ng-template>

    <ng-template #minlengthError>
        <div class="error"
            *ngIf="formGroup.get(id)!.touched && formGroup.get(id)!.errors && formGroup.get(id)!.hasError('minlength'); else emailError">
            <label class="__label">Deve ter no mínimo
                {{formGroup.get(id)!.errors!.minlength.requiredLength}} caracteres</label>
        </div>
    </ng-template>

    <ng-template #emailError>
        <div class="error"
            *ngIf="formGroup.get(id)!.touched && formGroup.get(id)!.errors && formGroup.get(id)!.hasError('email'); else documentError">
            <label class="__label">E-mail inválido</label>
        </div>
    </ng-template>

    <ng-template #documentError>
        <div class="error"
            *ngIf="formGroup.get(id)!.touched && formGroup.get(id)!.errors && formGroup.get(id)!.hasError('document'); else documentError2">
            <label class="__label">CPF inválido</label>
        </div>
    </ng-template>

    <ng-template #documentError2>
        <div class="error"
            *ngIf="formGroup.get(id)!.touched && formGroup.get(id)!.errors && formGroup.get(id)!.hasError('document2'); else documentError3">
            <label class="__label">RG inválido</label>
        </div>
    </ng-template>

    <ng-template #documentError3>
        <div class="error"
            *ngIf="formGroup.get(id)!.touched && formGroup.get(id)!.errors && formGroup.get(id)!.hasError('document3'); else phoneError">
            <label class="__label">CNPJ inválido</label>
        </div>
    </ng-template>

    <ng-template #phoneError>
        <div class="error" *ngIf="formGroup.get(id)!.touched && formGroup.get(id)!.errors && formGroup.get(id)!.hasError('phone')">
            <label class="__label">Telefone inválido</label>
        </div>
    </ng-template>
</div>
