<div *ngIf="!fetchingData else loadingTemplate" [formGroup]="formGroup">
  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Dados da clínica</h3>
        </div>

        <div class="col-12 col-lg-3">
          <app-image-input id="profile_image" [value]="image" [disabled]="formGroup.disabled"
            (onImageChange)="onImageSelect($event)">
          </app-image-input>
        </div>

        <div class="col p-0">
          <div class="row p-0 m-0 px-2">
            <div class="col-12 p-0 m-0 radio-type mb-3">
              <label>Tipo</label>

              <div class="radio-option">
                <label for="pessoa_juridica"><input type="radio" formControlName="type" value="COMPANY"
                    (click)="onSubtypeSelected('COMPANY')" id="pessoa_juridica" /> Pessoa jurídica</label>
              </div>

              <div class="radio-option">
                <label for="pessoa_fisica"><input type="radio" formControlName="type" value="PERSONAL"
                    id="pessoa_fisica" (click)="onSubtypeSelected('PERSONAL')" />
                  Pessoa física</label>
              </div>
            </div>

            <div class="col-sm-4 p-0 m-0 mb-3 pr-sm-2">
              <app-basic-input id="document_number" controlName="document_number" [formGroup]="formGroup"
                [label]="labelCpfCnpj" [maskOptions]="{mask: mask}"></app-basic-input>
            </div>

            <div class="col-sm-8 p-0 m-0 pl-1 mb-3">
              <app-basic-input id="name" controlName="name" [formGroup]="formGroup" [label]="labelName">
              </app-basic-input>
            </div>

            <div class="col-sm-4 p-0 m-0 mb-3 pr-sm-2">
              <app-basic-input id="display_name" controlName="display_name" [formGroup]="formGroup"
                [label]="labelDisplay"></app-basic-input>
            </div>

            <div class="col-sm-5 p-0 m-0 pl-1 mb-3 pr-sm-2">
              <app-basic-input id="email" controlName="email" [formGroup]="formGroup" label="Email"></app-basic-input>
            </div>

            <div class="col-sm-3 p-0 m-0 pl-1 mb-3">
              <app-basic-input id="cro" controlName="cro" [formGroup]="formGroup" label="CRO"></app-basic-input>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Profissionais</h3>
        </div>
        <div class="px-2 col-9">
          <div class="search_professional">
            <app-basic-input id="professional" placeholder="Procurar por CPF" controlName="professional"
              [formGroup]="formGroup" [maskOptions]="{mask: maskSearch}"></app-basic-input>
            <i *ngIf="!clearSearch" class="icon-Icon-ionic-ios-search" (click)="searchProfessional()"
              [ngStyle]="(!canSave) ? {'cursor': 'default'} : {'cursor': 'pointer'}"></i>
            <i *ngIf="clearSearch" class="icon-Icon-ionic-ios-close" (click)="clearProfessional()"
              [ngStyle]="(!canSave) ? {'cursor': 'default'} : {'cursor': 'pointer'}"></i>
          </div>
          <!-- <ng-container>
            <ng-select [formGroup]="formGroup" formControlName="professionals"
            [ngClass]="formGroup.get('professionals')!.errors ? 'error' : 'custom'"
              notFoundText="Não encontrado" [addTag]="false" [items]="professionalListAll" bindLabel="label" bindValue="value">
            </ng-select>
          </ng-container> -->
          <!-- <app-form-error id="professionals" [formGroup]="formGroup" controlName="professionals">
          </app-form-error> -->
        </div>
        <div class="col-3 d-flex align-items-center">
          <button (click)="setProfessional()" class="btn btn-link" [disabled]="formGroup.disabled">Adicionar
            novo</button>
        </div>
        <div class="col-9 list">
          <p *ngFor="let item of professionalList">
            {{item.label}}
            <button (click)="removeProfessional(item)" [disabled]="formGroup.disabled"
              class="remove-button">Remover</button>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" *ngIf="isSuperUserOrStaff">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Tags</h3>
        </div>
        <div class="px-2 col-12">
          <app-select-input id="tags" [formGroup]="formGroup" [items]="clinicTagsList" [hideSelected]="true"
            [addTag]="false" [multiple]="true" [clearable]="false" [searchable]="false"></app-select-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Áreas de atuação</h3>
        </div>
        <div class="px-2 col-12">
          <app-select-input id="specialties" [formGroup]="formGroup" [items]="specialtiesList" [hideSelected]="true"
            [addTag]="false" [multiple]="true" [clearable]="false" [searchable]="false"></app-select-input>
        </div>
      </div>
    </div>

  </div>

  <div class="row separator" formArrayName="phone_numbers">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Telefone</h3>
        </div>

        <ng-container *ngFor="let phoneNumber of phoneNumbersForms.controls; index as i">
          <div class="row col-12 px-2 m-0 " [class]="{'d-none': !phoneNumberIsActive(i)}">
            <div class="col-6 pr-sm-2 p-0 m-0 mb-3">
              <phone-number-input [formGroup]="getPhoneNumberForm(i)"></phone-number-input>
            </div>

            <div class="col-4 p-0 m-0 mb-3" [formGroup]="getPhoneNumberForm(i)">
              <app-select-input id="type" [formGroup]="getPhoneNumberForm(i)" [items]="phoneTypeList"
                [searchable]="false" [clearable]="false"></app-select-input>

            </div>

            <div class="col-2">
              <button (click)="removePhoneNumber(i)" [disabled]="formGroup.disabled"
                class="remove-button">Remover</button>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="row">
          <div class="col-12 d-flex align-items-center">
            <button class="btn btn-link" [disabled]="formGroup.disabled" (click)="addPhoneNumber()">Adicionar
              novo</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Endereço</h3>
        </div>
        <div class="p-0 m-0 mb-3 px-sm-2 col-2 zipcode">
          <app-basic-input id="zipcode" controlName="zipcode" label="CEP" [formGroup]="addressFormGroup"
            (focusout)="handleZipCodeChange($event)" [maskOptions]="{mask: '00000-000'}">
          </app-basic-input>
          <span *ngIf="searchZipcode" class="spinner-border"></span>
        </div>

        <div class="col-8 p-0 m-0 mb-3 pr-sm-2">
          <app-basic-input id="address" [formGroup]="addressFormGroup" controlName="address" label="Endereço">
          </app-basic-input>
        </div>

        <div class="col-2 p-0 m-0 mb-3 pr-sm-2">
          <app-basic-input id="number" [formGroup]="addressFormGroup" controlName="number" label="Número">
          </app-basic-input>
        </div>

        <div class="col-3 p-0 m-0 mb-3 px-sm-2">
          <app-basic-input id="complement" [formGroup]="addressFormGroup" controlName="complement" label="Complemento">
          </app-basic-input>
        </div>

        <div class="col-3 p-0 m-0 mb-3 pr-sm-2">
          <app-basic-input id="neighborhood" [formGroup]="addressFormGroup" controlName="neighborhood" label="Bairro">
          </app-basic-input>
        </div>

        <div class="col-3 p-0 m-0 mb-3 pr-sm-2">
          <app-basic-input id="city" [formGroup]="addressFormGroup" controlName="city" label="Cidade"></app-basic-input>
        </div>

        <div class="col-3 p-0 m-0 mb-3 pr-sm-2">
          <app-basic-input id="state" [formGroup]="addressFormGroup" controlName="state" label="Estado">
          </app-basic-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Financeiro</h3>
        </div>
      </div>
      <div class="row" *ngIf="isSuperUserOrStaff">
        <div class="col-3 p-0 m-0 mb-3 px-sm-2 ">
          <div class=" d-flex align-items-end height-switch">
            <label class="field__title m-0 mr-4">Split de pagamento</label>
            <label class="switch m-0">
              <input type="checkbox" formControlName="split">
              <span class="slider round"></span>
            </label>
          </div>
        </div>

        <div class="col-2 p-0 m-0 mb-3 px-sm-2 field">
          <label class="field__title" for="notes">Valor Chega de ronco</label>
          <input type="text" formControlName="amount_charged_to_clinic" currencyMask
            [options]="{prefix: '', thousands: '.', decimal: ','}"
            [ngClass]="formGroup.get('amount_charged_to_clinic')!.errors && formGroup.get('amount_charged_to_clinic')!.touched ? 'error' : ''">
          <app-form-error id="amount_charged_to_clinic" [formGroup]="formGroup" controlName="amount_charged_to_clinic">
          </app-form-error>
        </div>

        <div class="col-2 p-0 m-0 mb-3 px-sm-2">
          <app-basic-input id="aliquot" [formGroup]="formGroup" controlName="aliquot" label="Alíquota(%)">
          </app-basic-input>
        </div>

        <div class="col-3 p-0 m-0 mb-3 px-sm-2">
          <label class="field__title m-0 mr-4">Wallet ID <button class="wallet-id-btn btn" (click)="openWalletModal()">?</button></label>
          <app-basic-input id="wallet_id" [formGroup]="formGroup" controlName="wallet_id" type="text">
          </app-basic-input>
        </div>

        <div class="col-2 p-0 m-0 mb-3 px-sm-2">
          <app-basic-input id="maximum_nights_per_exam" [formGroup]="formGroup" controlName="maximum_nights_per_exam"
            label="Máximo de noites" type="number" min="1">
          </app-basic-input>
        </div>
      </div>

      <div class="row">
        <div class="col-3 p-0 m-0 mb-3 px-sm-2 field">
          <label class="field__title" for="notes">Valor da Polissonografia</label>
          <input type="text" formControlName="amount_charged_by_clinic" currencyMask
            [options]="{prefix: '', thousands: '.', decimal: ','}"
            [ngClass]="formGroup.get('amount_charged_by_clinic')!.errors && formGroup.get('amount_charged_by_clinic')!.touched ? 'error' : ''">
          <app-form-error id="amount_charged_by_clinic" [formGroup]="formGroup" controlName="amount_charged_by_clinic">
          </app-form-error>
        </div>

        <div class="col-3 p-0 m-0 mb-3 px-sm-2 field">
          <label class="field__title" for="discount">Desconto</label>
          <input type="text" formControlName="discount" currencyMask
            [options]="{prefix: '', thousands: '.', decimal: ','}"
            [class.error]="hasErrors('discount')">
          <app-form-error id="discount" [formGroup]="formGroup" controlName="discount">
          </app-form-error>
        </div>

        <div class="col-3 p-0 m-0 mb-3 px-sm-2">
          <app-basic-input id="exams_due_days" [formGroup]="formGroup" controlName="exams_due_days"
            label="Vencimento (em dias úteis)" type="number" min="0">
          </app-basic-input>
        </div>

        <div class="col-3 p-0 m-0 mb-3 px-sm-2">
          <app-basic-input id="courtesy_exams" [formGroup]="formGroup" controlName="courtesy_exams"
            label="Cortesias" type="number" [readonly]="!isSuperUserOrStaff" min="0">
          </app-basic-input>
        </div>

        <div class="col-12 p-0 m-0 mb-3 px-sm-2">
          <label class="field__title" for="invoice_description">Descrição da fatura</label>
          <textarea id="invoice_description" rows="3" formControlName="invoice_description"></textarea>
          <app-form-error id="invoice_description" [formGroup]="formGroup" controlName="invoice_description">
          </app-form-error>
        </div>
      </div>
    </div>
  </div>

</div>


<div class="row justify-content-end mt-5 mb-5">
  <div class="col-12 col-md-3 mb-3">
    <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
      Cancelar
    </button>
  </div>

  <div class="col-12 col-md-3 mb-3" *ngIf="button">
    <button (click)="removeHandler()" [disabled]="submittingRemove || !canRemove" class="btn btn-secondary btn-lg"
      type="button">
      {{removeButton}}
      <span class="spinner-border" *ngIf="submittingRemove"></span>
    </button>
  </div>

  <div class="col-12 col-md-3 mb-3">
    <button (click)="submitHandler()" [disabled]="submitting || !canSave" class="btn btn-primary btn-lg" type="button">
      {{submitButton}}
      <span class="spinner-border" *ngIf="submitting"></span>
    </button>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

<div class="backdropLoading" *ngIf="loadingProfessional">
  <div class="spinner-border spinner-border-xl" role="status">
    <span class="sr-only">Carregando...</span>
  </div>
</div>
