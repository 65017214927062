<div class="background">
  <div class="main" *ngIf="!loading else loadingTemplate">
    <div class="logo">
      <img src="../../../../assets/chegaderonco-color.svg">
    </div>

    <div class="patient">
      <h2 class="exclusive">Esta avaliação é exclusiva para:</h2>
      <h2 class="name">{{ quizSummaryVideo?.name }}</h2>

      <div>
        <h2 class="info-video">Assista o vídeo com a avaliação do seu caso e assim que terminar clique no botão abaixo e
          nos falaremos por uma chamada de vídeo no Whatsapp para esclarecer suas dúvidas e avaliar os próximos
          passos...</h2>
      </div>
    </div>

    <div class="video">
      <iframe [src]="videoSource" title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>

    <div>
      <button class="button-style" (click)="goToWhatsApp()">Terminei de assistir o vídeo</button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="background col full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
