import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from 'src/app/core/services/patient.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';

@Component({
  selector: 'app-patient-edit',
  templateUrl: './patient-edit.component.html',
  styleUrls: ['../patients.scss']
})
export class PatientEditComponent implements OnInit {

  patientName!: string;
  showMenuSidebar: boolean = false;

  constructor(private patientService: PatientService, private activatedRoute: ActivatedRoute, private sessionManager: SessionManagerService) { }

  get currentClinic() {
    if(!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent();
    }
    return '';
  }

  get currentClinicId() {
    if(!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent().value;
    }
    return '';
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  ngOnInit(): void {
    this.patientName = "Carregando...";

    this.activatedRoute.params.subscribe(params => {
      if(this.isSuperUserOrStaff) {
        this.patientService.patientGet(params['id']).subscribe((patient) => {
          this.patientName = patient.name!;
        });
      } else {
        this.patientService.clinicUserPatientGet(this.currentClinicId, params['id']).subscribe((patient) => {
          this.patientName = patient.name!;
        });
      }
    });
  }

  menuButtonHandler() {
    this.showMenuSidebar = !this.showMenuSidebar;
  }
}
