<div *ngIf="!inline" class="field" [formGroup]="formGroup">
    <label class="field__title" [for]="id">{{label ? label : ""}}</label>

    <div class="option" *ngFor="let option of optionList" [ngClass]="disabled? 'option__disabled' : 'null'">
        <input type="radio" [id]="option.name" [formControlName]="id" value={{option.name}}>
        <label [for]="option.name" [ngClass]="disabled? 'label__disabled' : 'null'">{{option.label}}</label>
    </div>
</div>

<div *ngIf="inline" class="field-inline" [formGroup]="formGroup" [ngClass]="disabled? 'field-inline__disabled' : 'null'">
    <label class="field-inline__title" [for]="id">{{label ? label : ""}}</label>

    <div class="option" *ngFor="let option of optionList" [ngClass]="disabled? 'option__disabled' : 'null'">
        <input (click)="onValueChangeHandler(option.name)" type="radio" [id]="option.name" [formControlName]="id"
            value={{option.name}}>
        <label [for]="option.name" [ngClass]="disabled? 'label__disabled' : 'null'">{{option.label}}</label>
    </div>
</div>