import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RestCountriesService } from 'src/app/core/services/restcountries.service';

@Component({
  selector: 'ddi-input',
  templateUrl: './ddi-input.component.html',
  styleUrls: ['./ddi-input.component.scss']
})
export class DdiInputComponent implements OnInit {
  @Input() id!: string;
  @Input() formGroup!: FormGroup;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() removeVerticalSpacing: boolean = false;

  optionList: any = [];
  loading: boolean = true;

  constructor(private restCountries: RestCountriesService) { }

  ngOnInit(): void {
    this.restCountries.getPhonesList().subscribe((phonesList: any[]) => {
      phonesList.forEach((phone) => {
        this.optionList = [...this.optionList, { label: phone.idd.root + (phone.idd.suffixes[0] ? phone.idd.suffixes[0] : phone.idd.suffixes) + " - " + phone.translations.por.common, value: phone.idd.root + (phone.idd.suffixes[0] ? phone.idd.suffixes[0] : phone.idd.suffixes), flag: phone.flags.svg }];
      })
      this.loading = false;
    })
  }

  getMask() {
    if (this.formGroup.value.country_code == "+55" && this.formGroup.value.phone_number.length <= 10) {
      return "(00) 0000-00000";
    }
    else if (this.formGroup.value.country_code == "+55" && this.formGroup.value.phone_number.length >= 11) {
      return "(00) 00000-0000";
    }
    else return '000000000000000';
  }

  shouldValidate() {
    if (this.formGroup.value.country_code != "+55" && this.formGroup.value.phone_number.length >= 11) {
      return false;
    }
    else if (this.formGroup.value.country_code == "+55" && this.formGroup.value.phone_number.length < 10) {
      return true;
    }
    else {
      return false;
    }
  }
}
