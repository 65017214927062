import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExamsComponent } from './exams.component';
import { ExamListComponent } from './exam-list/exam-list.component';
import { ExamFormComponent } from './exam-form/exam-form.component';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NavigationModule } from '../navigation/navigation.module';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { SharedModule } from 'src/app/shared/shared.module';
import {NgbAccordionModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {ImageCropperModule} from "ngx-image-cropper";

@NgModule({
  declarations: [
    ExamsComponent,
    ExamListComponent,
    ExamFormComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    NavigationModule,
    RenderersModule,
    SharedModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    CurrencyMaskModule,
    NgbAccordionModule,
    ImageCropperModule,
  ]
})
export class ExamsModule { }
