import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AccountModel } from 'src/app/core/models/account.model';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ErrorModalComponent } from 'src/app/shared/renderers/components/error-modal/error-modal.component';
import {ClinicsService} from "../../../core/services/clinics.service";
import {Options} from "../../../shared/components/select-input/models/Options";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../publicstyle.scss']
})
export class LoginComponent implements OnInit {

  loading: boolean = false;
  submitButton: string = 'Receber código'
  isConfirmationCode: boolean = false;
  phoneNumber!: string;

  loginForm = this.fb.group({
    phone_number: [null, [Validators.required]],
    country_code: ['+55', [Validators.required]],
    code: ['', [Validators.required]]
  })

  constructor(private accountsService: AccountsService, private sessionManager: SessionManagerService,
              private router: Router, config: NgbModalConfig, private modalService: NgbModal,
              private toast: ToastrService, private clinicsService: ClinicsService, private fb: FormBuilder) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
  }

  get isItToDisable() {
    let phoneNumber = this.loginForm.get('phone_number')
    let countryCode = this.loginForm.get('country_code')
    let code = this.loginForm.get('code')
    if (!this.isConfirmationCode && (!phoneNumber?.value || !countryCode?.value)) {
      return true
    } else if (this.isConfirmationCode && !code?.value) {
      return true
    }
    return false
  }

  onSubmit(e: Event): void {
    this.loading = true;
    if (!this.isConfirmationCode) {
      this.loginRequest();
    } else {
      this.loginConfirmation();
    }
    e.preventDefault();
  }

  async loginConfirmation() {
    let data = this.loginForm.getRawValue();
    this.accountsService.loginConfirmation(data).subscribe(async response => {
      this.sessionManager.setSessionData(response);
      await this.getClinics();
      this.router.navigate(["/dashboard"]);
      this.loading = false;
    }, error => {
      this.toast.error(error.error['detail'], 'Erro');
      this.loading = false;
    })
  }

  async loginRequest() {
    let phone_number = this.loginForm.get('phone_number')!.value;
    let country_code = this.loginForm.get('country_code')!.value;
    this.phoneNumber = phone_number;
    let accountData = {phone_number: phone_number,  country_code: country_code} as AccountModel;
    this.accountsService.login(accountData).subscribe(async (response: AccountModel) => {
      this.phoneNumber = phone_number;
      this.submitButton = 'Entrar'
      this.isConfirmationCode = true;
      this.loading = false;
    }, (response: HttpErrorResponse) => {
      this.toast.error(response.error['detail'], 'Erro');
      this.loading = false;
    });
  }

  requestAnotherCode() {
    this.loading = false;
    this.isConfirmationCode = false;
    this.submitButton = 'Receber código';
    this.loginForm.get('code')?.setValue(null);
  }

  async getClinics() {
    let clinicIds = this.sessionManager.clinicsListId;
    let response = await this.clinicsService.userClinicList(clinicIds).toPromise();
    let clinicList = response!.results!.map(item => {
        return {
          value: item!.id!.toString(),
          label: item.name,
          items: item
        } as Options;
      });
      this.sessionManager.setClinics(clinicList);
      if (!this.sessionManager.getClinicCurrent()) {
        this.sessionManager.setClinicCurrent(clinicList[0]);
      }
      let userClinic = this.sessionManager.getClinicCurrent();
  }

  passwordRecoveryLinkClickHandler() {
    this.router.navigate(['passwordRecovery'])
  }
}
