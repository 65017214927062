import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {PatientQuizService} from "../../../core/services/patient-quiz.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SessionManagerService} from "../../../core/services/session-manager.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {RestCountriesService} from "../../../core/services/restcountries.service";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: [
    './register.component.scss'
  ]
})
export class RegisterComponent implements OnInit {
  formGroup: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    birthdate: ['', [Validators.required, Validators.minLength(10)]],
    country_code: ['+55', [Validators.required]],
    phone_number: ['', [Validators.required]],
    gender: [null, [Validators.required]],
  });

  loading = false;

  phoneNumber?: string;

  viewVideo = false;

  countryCodes: any[] = [];

  constructor(private fb: FormBuilder, private toastr: ToastrService, private patientQuizService: PatientQuizService,
              private router: Router, private sessionManager: SessionManagerService, private modalService: NgbModal,
              private activatedRoute: ActivatedRoute, private restCountriesService: RestCountriesService) {
  }

  ngOnInit(): void {
    this.getCountryCodes();
    this.loading = true;
    let patient = this.sessionManager.getPatientData();
    if (this.sessionManager.loggedInAsPatient) {
      this.activatedRoute.queryParams.subscribe(params => {
        console.log(params);
        if ('logout' in params) {
          this.sessionManager.clearPatientData();
        } else {
          this.router.navigate(['/pacientes/questionario']);
        }
      });
    } else {
      this.activatedRoute.params.subscribe(params => {
        this.phoneNumber = params['phoneNumber'];
        if (this.phoneNumber) {
          this.patientQuizService.getPhoneNumber(this.phoneNumber).subscribe(response => {
            this.formGroup.patchValue(response);
            this.sendFlow();
            this.loading = false;
          }, error => {
            this.phoneNumber = undefined;
            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      });
    }
  }

  getCountryCodes() {
    this.restCountriesService.getPhonesList().subscribe((response: any[]) => {
      response.forEach(phone => {
        this.countryCodes = [...this.countryCodes,
          { label: phone.idd.root + (phone.idd.suffixes[0] ? phone.idd.suffixes[0] : phone.idd.suffixes) + " - " + phone.translations.por.common,
            value: phone.idd.root + (phone.idd.suffixes[0] ? phone.idd.suffixes[0] : phone.idd.suffixes),
            flag: phone.flags.svg }];
      })
    });
  }

  sendFlow() {
    let phoneNumber = this.formGroup.get('phone_number')?.value;
    let countryCode = this.formGroup.get('country_code')?.value;
    let data = {phone_number: phoneNumber, country_code: countryCode};
    this.patientQuizService.sendFlow(data).subscribe(response => {
    });
  }

  hasErrors(formControlName: string): boolean {
    let hasErrors: boolean = this.formGroup.get(formControlName)?.errors != null;
    let isDirty: boolean = this.formGroup.get(formControlName)?.dirty == true;
    return hasErrors && isDirty;
  }

  onError(error: any) {
    this.loading = false;
    this.toastr.error('Erro ao cadastrar. Tente novamente.');
  }

  parseToDate(value: string | number | null | undefined): string {
    if (!value || value.toString().length != 8) {
      return value?.toString() || '';
    }
    value = value.toString();
    let day = value.substring(0, 2);
    let month = value.substring(2, 4);
    let year = value.substring(4);
    return `${year}-${month}-${day}`;
  }

  submit() {
    if (this.formGroup.valid) {
      let patientData = this.formGroup.getRawValue();
      this.loading = true;
      if (this.phoneNumber) {
        this.patientQuizService.registerPatient(patientData).subscribe({
          next: response => {
            this.loading = false;
            this.sessionManager.setPatientData(response);
            this.router.navigate(['/pacientes/questionario']);
          },
          error: this.onError,
        });
      } else {
        this.patientQuizService.registerPatientWithCode(patientData).subscribe({
          next: response => {
            this.loading = false;
            this.sessionManager.setPatientData(patientData);
            this.router.navigate(['pacientes/confirmar-codigo']);
          },
          error: this.onError,
        });
      }
    } else {
      this.toastr.error('Preencha todos os campos');
      this.loading = false;
    }
  }

  getPhoneNumberFromFormGroup(): string {
    let countryCode = this.formGroup.get('country_code')?.value;
    let phoneNumber = this.formGroup.get('phone_number')?.value;
    return `${countryCode}${phoneNumber}`;
  }

  toggleVideo(video: HTMLVideoElement) {
    if (video.muted) {
      video.currentTime = 0;
    }

    if (video.muted || video.paused) {
      video.play();
      video.muted = false;
      video.volume = 100;
    } else {
      video.pause();
    }
  }
}
