import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { PatientModel } from 'src/app/core/models/patient.model';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { PhoneModel } from 'src/app/core/models/phone.model';
import { BrapiService } from 'src/app/core/services/brapi.service';
import { PatientService } from 'src/app/core/services/patient.service';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { Options } from 'src/app/shared/components/select-input/models/Options';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';
import { FieldValidator } from 'src/app/shared/renderers/utils/field-validator/field-validator';
import { ClinicModel } from 'src/app/core/models/clinic.model';
import { ClinicsService } from 'src/app/core/services/clinics.service';

@Component({
  selector: 'app-patient-edit-personal',
  templateUrl: './patient-edit-personal.component.html',
  styleUrls: ['./patient-edit-personal.component.scss']
})
export class PatientEditPersonalComponent implements OnInit {
  addressFormGroup = this.fb.group({
    zipcode: [],
    address: [],
    number: [],
    complement: [],
    neighborhood: [],
    city: [],
    state: []
  });

  searchZipcode: boolean = false;

  formGroup = this.fb.group({
    id: [],
    name: [null, [Validators.required]],
    display_name: [],
    gender: [],
    birthdate: [],
    email: [],
    document_number: [[FieldValidator.documentValidator()]],
    marital_status: [],
    profession: [],
    tags: [[]],
    phone_numbers: this.fb.array([]),
    type: ['COMPANY'],
    clinic: [],
    patient_clinics: [],
    clinics: this.fb.array([]),
    address: this.addressFormGroup,
    country_code: ['+55', [Validators.required]],
    phone_number: [null, [Validators.required]]
  });

  id!: number;
  existingPatientData!: PatientModel;
  fetchingPatient: boolean = true;

  imageFile!: File | null;
  changedImage: boolean = false;

  loadingAccount: boolean = false;

  patientTagsList!: Options[];
  fetchingTags: boolean = true;
  professionList!: Options[];
  fetchingProfession: boolean = true;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton: String = "Desativar";
  submitButton: String = "Salvar";
  button: boolean = false;

  maskSearch: string = '00.000.000/0000-00';

  clinicData!: ClinicModel;

  phonesList!: PhoneModel[];

  phoneTypeList: Options[] = [
    { label: "Whatsapp", value: "WHATSAPP" },
    { label: "Comercial", value: "COMERCIAL" },
    { label: "Residencial", value: "RESIDENTIAL" },
  ];

  maritalStatusList: Options[] = [
    { value: "MARRIED", label: "Casado" },
    { value: "SINGLE", label: "Solteiro" },
    { value: "DIVORCED", label: "Divorciado" },
    { value: "WIDOWED", label: "Viúvo" }
  ];

  constructor(@Inject(DOCUMENT) private document: Document, private fb: FormBuilder, private sessionManager: SessionManagerService,
    private router: Router, private toast: ToastrService, private registersService: RegistersService, private patientService: PatientService,
    private brApiService: BrapiService, private activatedRoute: ActivatedRoute, config: NgbModalConfig, private modalService: NgbModal,
    private clinicsService: ClinicsService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.activatedRoute.parent?.params.subscribe(params => {
      this.id = params["id"];
    });
    this.initializeForm();
  }

  async initializeForm() {
    this.addPhoneNumber();
    await this.fetchProfessionsList();
    this.fetchPatientTags();
    await this.fetchClinic();
    this.fetchPatientExistingData();
  }

  get canSave() {
    if (!this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_patient);
    } else {
      if (!this.isSuperUserOrStaff) {
        if (this.existingPatientData && (this.existingPatientData.account?.is_staff || this.existingPatientData.account?.is_superuser)) {
          return false;
        } else {
          return this.sessionManager.getUserPermission(PermissionConst.change_patient);
        }
      } else {
        return this.sessionManager.getUserPermission(PermissionConst.change_patient);
      }
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_patient);
  }

  get currentClinic() {
    if(!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent();
    }
    return '';
  }

  get currentClinicId() {
    if(!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent().value;
    }
    return '';
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  cancelHandler() {
    this.router.navigate(['dashboard/patients']);
  }

  onImageSelect(file: any) {
    this.imageFile = file;
    this.changedImage = true
  }

  get fetchingData(): boolean {
    if (this.fetchingTags || this.fetchingProfession || this.fetchingPatient) {
      return true;
    }
    return false;
  }

  setForm(data: any) {
    if (!data.id) {
      this.formGroup.patchValue(data.account);
    } else {
      this.router.navigate([`dashboard/patients/edit/${data.id}`]);
    }
  }

  submitHandler() {
    this.submitting = true;
    (this.phoneNumbersForms?.value as PhoneModel[]).forEach((phone, index) => {
      if (phone.id == null) this.phoneNumbersForms.at(index).get('id')?.setValue(undefined);
    });
    if (this.formGroup.get('phone_number')?.value == '') {
      this.formGroup.get('phone_number')?.setValue(null);
    }
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      const patientData = this.formGroup.getRawValue();
      this.patientEdit(patientData);
    } else {
      this.submitting = false;
    }
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar este paciente?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.patientService.patientRemove(this.id).subscribe(() => {
          this.toast.success('Paciente removido com sucesso', "Sucesso");
          this.cancelHandler();
        }, (errorResponse: HttpErrorResponse) => {
          this.submittingRemove = false;
          this.mapErrorResponse(errorResponse);
        });
      } else {
        this.submittingRemove = false;
      }
    });
  }

  patientEdit(patientData: PatientModel) {
    patientData.is_active = true;
    const fnResponse = (response: PatientModel) => {
      if (this.changedImage) {
        if (this.isSuperUserOrStaff) {
          this.patientService.uploadImage(this.imageFile!, response.account!.id!).subscribe(() => { }, fnError);
        }
      }
      this.toast.success('Paciente alterado com sucesso', "Sucesso");
      this.cancelHandler();
    };

    const fnError = (error: HttpErrorResponse) => {
      this.submitting = false;
      this.mapErrorResponse(error);
    }

    if(this.isSuperUserOrStaff) {
      this.patientService.patientEdit(patientData).subscribe({next: fnResponse, error: fnError});
    } else {
      this.patientService.clinicUserPatientEdit(this.currentClinicId, patientData).subscribe(
        {next: fnResponse, error: fnError});
    }

  }

  async fetchProfessionsList() {
    try {
      let response = await this.registersService.professionsAll(this.currentClinicId).toPromise();
      this.professionList = response!.map(items => {
        return {
          value: items.name,
          label: items.name
        } as Options;
      });
      this.fetchingProfession = false;
    } catch (error) {
      this.mapErrorResponse(error as HttpErrorResponse);
    }
  }

  fetchPatientTags() {
    this.patientService.listAllTagPatient(this.currentClinicId).subscribe(response => {
      this.patientTagsList = response!.map(items => {
        return {
          value: items.id!.toString(),
          label: items.name
        } as Options;
      });
      this.fetchingTags = false;
    });
  }

  fetchPatientExistingData() {
    const fnResponse = (response: PatientModel) => {
      this.existingPatientData = response;
      this.phoneNumbersForms.clear();
      response.phone_numbers!.forEach(() => this.addPhoneNumber());
      let clinic = this.existingPatientData.clinics;
      clinic!.forEach(value => this.fetchClinicsList(value));
      this.formGroup.patchValue(this.existingPatientData);
      if (this.existingPatientData.is_active) {
        this.button = true;
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
      } else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
        this.phoneNumbersForms.disable();
      }
      if (this.formGroup.get('country_code')?.value == null) {
        this.formGroup.get('country_code')?.setValue('+55');
      }
      this.formGroup.get('phone_number')?.disable();
      this.formGroup.get('country_code')?.disable();
      if (!this.isSuperUserOrStaff) {
        this.button = false;
        if (this.existingPatientData.account?.is_staff || this.existingPatientData.account?.is_superuser) {
          this.formGroup.disable();
        }
      }
      this.fetchingPatient = false;
    };

    const fnError = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.cancelHandler();
    }

    if (this.isSuperUserOrStaff) {
      this.patientService.patientGet(this.id).subscribe(fnResponse, fnError);
    } else {
      this.patientService.clinicUserPatientGet(this.currentClinicId, this.id).subscribe(fnResponse, fnError);
    }
  }

  get phoneNumbersForms() {
    return this.formGroup.get('phone_numbers') as FormArray;
  }

  getPhoneNumberForm(index: number) {
    return this.phoneNumbersForms.controls[index] as FormGroup;
  }

  addPhoneNumber() {
    let phoneNumberFormGroup = this.fb.group({
      id: [],
      country_code: ['+55'],
      phone_number: [null, [Validators.required]],
      type: ['WHATSAPP'],
      is_active: [true]
    });
    this.phoneNumbersForms.push(phoneNumberFormGroup);
  }

  removePhoneNumber(at: number) {
    let formGroup = this.getPhoneNumberForm(at);
    if (formGroup.get('id')?.value) {
      formGroup.patchValue({ is_active: false });
    } else {
      this.phoneNumbersForms.removeAt(at);
    }
  }

  phoneNumberIsActive(index: number) {
    return this.getPhoneNumberForm(index).get('is_active')?.value;
  }

  handleZipCodeChange(ev: any) {
    this.searchZipcode = true;
    let zipcode = this.addressFormGroup.get('zipcode')?.value;
    this.addressFormGroup.disable();
    this.brApiService.getAddressByZipCode(zipcode).subscribe(response => {
      this.addressFormGroup.patchValue({
        address: response.street,
        neighborhood: response.neighborhood,
        city: response.city,
        state: response.state
      });
      this.searchZipcode = false;
      this.addressFormGroup.enable();
    }, (error: HttpErrorResponse) => {
      this.addressFormGroup.get('zipcode')?.setErrors({response: 'Cep não encontrado'});
      this.searchZipcode = false;
      this.addressFormGroup.enable();
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    } else {
      this.setFormErrors(errorResponse);
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "name", "display_name", "gender", "email", "birthdate", "document_number", "tags",
      "phone_numbers", "marital_status", "profession", "identity_number", "phone_number"
    ];

    let addressErrNames = [
      "zipcode", "address", "number", "complement", "neighborhood", "city", "state"
    ];

    let phoneErrNames = [
      "id", "country_code", "phone_number", "type"
    ];

    errNames.forEach(name => {
      if (errorResponse.error[name]) {
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
      }
    });

    if (errorResponse.error.address) {
      let address = errorResponse.error.address;
      addressErrNames.forEach(name => {
        this.addressFormGroup.get(name)?.setErrors({ response: address[name] });
      });
    }

    if (errorResponse.error.phone_numbers) {
      let phone_numbers: [] = errorResponse.error.phone_numbers;
      phone_numbers.forEach((obj, index) => {
        let formPhone = this.getPhoneNumberForm(Number(index)) as FormGroup;
        phoneErrNames.forEach(name => {
          formPhone.get(name)?.setErrors({ response: obj[name] });
        });

      });
    }
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  };

  onSubtypeSelected(value: string) {
    if (value == "COMPANY") {
      this.maskSearch = "00.000.000/0000-00";
    }
    else {
      this.maskSearch = "000.000.000-00";
    }
  }

  loadingClinic: boolean = false;
  clearClinicSearch: boolean = false;

  get clinicsFormArray() {
    return this.formGroup.get('clinics') as FormArray;
  }

  clinicsList: Options[] = [];
  clinicsListAll!: Options[];
  clinicsAll!: Options[];
  fetchingClinic: boolean = false;


  async fetchClinic() {
    const fnResponse = (response: any) => {
      this.clinicsListAll = response!.map((item: any) => {
        return {
          value: item.id,
          label: `${item.name}`
        } as Options;
      });
      this.clinicsAll = this.clinicsListAll;
      this.fetchingClinic = true;
    }
    const fnError = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    };
    try {
      let response = await this.clinicsService.getAll(this.currentClinicId).toPromise();
      fnResponse(response)
    } catch (error) {
      fnError(error as HttpErrorResponse)
    }
  }


  fetchClinicsList(clinicId?: any) {
    this.clinicsFormArray.push(this.fb.control(clinicId));
    this.clinicsList = this.clinicsListAll.filter((item) => {
      return this.clinicsFormArray.value.some((id: any) => item.value == id);
    });
  }


  setClinic() {
    this.loadingClinic = true;
    this.formGroup.get('clinic')?.disable();
    let document_number = this.formGroup.get('clinic')?.value;
    if (document_number) {
      this.clearClinicSearch = true;
      this.patientService.clinicSearchAll(document_number).subscribe(response => {
        this.formGroup.get('clinic')?.enable();
        let hasClinic = this.clinicsFormArray.value.some((id: any) => response.id == id);
        if (hasClinic) {
          this.formGroup.get('clinic')?.setErrors({
            response: 'Esta clínica já foi adicionada'
          });
        } else {
          this.clearClinic();
          this.clinicsFormArray.push(this.fb.control(response.id));
          this.clinicsList = this.clinicsListAll.filter((item) => {
            return this.clinicsFormArray.value.some((id: any) => item.value == id);
          });
        }
        this.loadingClinic=false
      }, (error) => {
        this.formGroup.get('clinic')?.setErrors({
          response: 'Não existe clínica com esse CNPJ/CPF'
        });
      });
      this.formGroup.get('clinic')?.enable();
    } else {
      this.formGroup.get('clinic')?.setErrors({
        response: 'Digite um CNPJ ou CPF para pesquisar'
      });
      this.loadingClinic = false;
      this.formGroup.get('clinic')?.enable();
    }
  }

  clearClinic() {
    this.maskSearch = '00.000.000/0000-00';
    this.formGroup.get('clinic')?.enable();
    this.formGroup.get('clinic')?.reset();
    this.clearClinicSearch = false;
  }

  removeClinic(clinic: any) {
    let index = this.clinicsList.indexOf(clinic);
    this.clinicsList.splice(index, 1);
    let formArrayIndex = this.clinicsFormArray.value.indexOf(clinic.value);
    this.clinicsFormArray.removeAt(formArrayIndex);
  }
}
