import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RenderersModule } from './renderers/renderers.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BasicInputComponent } from './components/basic-input/basic-input.component';
import { PhoneNumberInputComponent } from './components/phone-number-input/phone-number-input.component';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { DefaultModalComponent } from './components/modals/default-modal/default-modal.component';
import { ExportModalComponent } from './components/modals/export-modal/export-modal.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImageInputComponent } from './components/image-input/image-input.component';
import { SelectInputComponent } from './components/select-input/select-input.component';
import { BadgeInputComponent } from './components/badge-input/badge-input.component';
import { WalletIdModalComponent } from './components/modals/wallet-id-modal/wallet-id-modal.component';
import { ImportExamModalComponent } from './components/modals/import-exam-modal/import-exam-modal.component';
import { ImportClinicUserExamModalComponent } from './components/modals/import-clinic-user-exam-modal/import-clinic-user-exam-modal.component';
import { ImageCropperModalComponent } from './components/modals/image-cropper-modal/image-cropper-modal.component';
import {ImageCropperModule} from "ngx-image-cropper";
import { AnswerQuestionModalComponent } from './components/modals/answer-question-modal/answer-question-modal.component';
import { AssociateUserModalComponent } from './components/modals/associate-user-modal/associate-user-modal.component';

@NgModule({
  declarations: [
    BasicInputComponent,
    PhoneNumberInputComponent,
    FormErrorComponent,
    ConfirmationModalComponent,
    DefaultModalComponent,
    ExportModalComponent,
    ImageInputComponent,
    SelectInputComponent,
    BadgeInputComponent,
    WalletIdModalComponent,
    ImportExamModalComponent,
    ImportClinicUserExamModalComponent,
    ImageCropperModalComponent,
    AnswerQuestionModalComponent,
    AssociateUserModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RenderersModule,
    NgxMaskModule,
    NgSelectModule,
    ImageCropperModule,
  ],
    exports: [
        BasicInputComponent,
        PhoneNumberInputComponent,
        ImageInputComponent,
        SelectInputComponent,
        FormErrorComponent,
        BadgeInputComponent,
        DefaultModalComponent

    ]
})
export class SharedModule { }
