import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ListTemplate } from 'src/app/shared/renderers/models/list-template';

@Component({
  selector: 'app-patient-tags-list',
  templateUrl: './patient-tags-list.component.html',
  styleUrls: ['./patient-tags-list.component.scss']
})
export class PatientTagsListComponent implements OnInit {

  searchString: string = "";
  orderingItem: string = "name";

  request?: Subscription;

  get clinicId() {
    if (this.isSuperUserOrStaff) {
      return '';
    }
    return this.sessionManager.getClinicCurrent().value?.toString();
  }

  clinicIdList: string = "";

  get canNewData() {
    const permissions = [PermissionConst.add_tag, PermissionConst.change_tag, PermissionConst.view_tag];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  get canExport() {
    const permissions = [PermissionConst.export_tag, PermissionConst.change_tag, PermissionConst.view_tag];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: true,
    searchBarPlaceholder: "Buscar por Tag",
    showAdvancedSearch: false,
    newDataText: "+ Nova Tag",
    newDataRouterLink: this.canNewData && this.clinicId != ""  ? "register" : undefined,
    notFoundMessage: "Nenhuma Tag encontrada",
    clickRowClickHandle: true,
    header: [{ name: "name", label: "Tag", widthInPercentage: "100%", width: "200px" }],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(this.searchString, `${invertString}${value}`, clinicId, this.listTemplate.is_active);
      this.orderingItem = value;
      this.listTemplate.selectedOrdering = value;
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(value, this.orderingItem, clinicId, this.listTemplate.is_active);
    },
    onClinicFocusOut: (value: string) => {
      this.clinicIdList = value;
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(this.searchString, this.orderingItem, clinicId, this.listTemplate.is_active);
    },
    onClickActive: !this.isSuperUserOrStaff ? undefined : () => {
      this.listTemplate.is_active = !this.listTemplate.is_active;
      let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
      this.fetchList(this.searchString, this.orderingItem, clinicId, this.listTemplate.is_active);
    },
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
    exportUrl: this.canExport ? '/tags/patient/export' : undefined,
    exportOptions: {
      id: 'ID',
      name: 'Nome'
    }
  };

  constructor(private registersService: RegistersService, private sessionManager: SessionManagerService, private titleService: Title) {
    this.titleService.setTitle('Chega de Ronco - Tags Paciente');
  }

  ngOnInit(): void {
    this.initializeList();
  }

  initializeList() {
    this.fetchList("", "name", this.clinicId, this.listTemplate.is_active);
  }

  fetchList(searchString: string, orderingItem: string, clinicId: string, isActive: boolean | undefined) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;
    if (this.request) {
      this.request.unsubscribe();
    }
    this.request = this.registersService.tagsList("PATIENT", clinicId, searchString, orderingItem, isActive).subscribe(response => {
      this.listTemplate.loading = false;
      this.listTemplate.data = response;
      this.listTemplate.hasNext = response.next != null;
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = response.error['detail'];
    });
  }

  loadMore(page: number) {
    this.listTemplate.loadingNext = true;
    let clinicId = this.clinicIdList != '' ? this.clinicIdList : this.clinicId;
    this.registersService.tagsList("PATIENT", clinicId, this.searchString, this.orderingItem, this.listTemplate.is_active, page).subscribe(
      response => {
        this.listTemplate.hasNext = response.next != null;
        response.results!.map(item => this.listTemplate.data!.results!.push(item));
        this.listTemplate.loadingNext = false;
      },
      error => {
        console.error(error);
        this.listTemplate.loadingNext = false;
      }
    );
  }
}
