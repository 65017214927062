import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AccountModel } from 'src/app/core/models/account.model';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { PermissionModel } from 'src/app/core/models/permission.model';
import { AccountClinicService } from 'src/app/core/services/account-clinic.service';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { ClinicsService } from 'src/app/core/services/clinics.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/modals/confirmation-modal/confirmation-modal.component';
import { Options } from 'src/app/shared/components/select-input/models/Options';
import { ErrorModalComponent } from 'src/app/shared/renderers/components/error-modal/error-modal.component';
import { FieldValidator } from 'src/app/shared/renderers/utils/field-validator/field-validator';
import { AssociateUserModalComponent } from '../../../../../shared/components/modals/associate-user-modal/associate-user-modal.component';

@Component({
  selector: 'app-clinic-user-form',
  templateUrl: './clinic-user-form.component.html',
  styleUrls: ['./clinic-user-form.component.scss'],
})
export class ClinicUserFormComponent implements OnInit {
  formGroup = this.fb.group({
    id: [],
    name: [''],
    email: ['', [Validators.email]],
    birthdate: [''],
    document_number: ['', [FieldValidator.documentValidator()]],
    user_permissions: this.fb.array([]),
    clinics: [[], [Validators.required]],
    can_approve_exam: [false],
    country_code: ['+55', [Validators.required]],
    phone_number: [null, [Validators.required]],
  });

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    config: NgbModalConfig,
    private modalService: NgbModal,
    private clinicsService: ClinicsService,
    private accountsService: AccountsService,
    private accountClinicService: AccountClinicService,
    private toast: ToastrService,
    private sessionManager: SessionManagerService
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  imageFile!: File | null;
  changedImage: boolean = false;
  image!: string;

  loadingAccount: boolean = false;
  isRegister: boolean = true;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton: String = 'Desativar';
  submitButton: String = 'Salvar';
  button: boolean = false;

  connect: boolean = false;

  clinicList: Options[] = [];
  fetchingClinic: boolean = false;
  fetchingPermissions: boolean = false;

  userPermissions!: PermissionModel[];
  checked: boolean = true;
  disabled: boolean = false;
  disabledPermissions: string[] = [];

  id!: number;
  existingAccountData!: AccountModel;
  fetchingAccount: boolean = false;

  checkedAdmin: boolean = true;
  is_active: boolean = true;

  passwordChange: boolean = false;

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      this.id = params['id'];
      let idSession = this.sessionManager.getSessionData().id;
      if (this.id.toString() == idSession?.toString()) {
        this.passwordChange = true;
      }
      if (this.id) {
        this.isRegister = false;
        this.fetchAccountExistingData();
      }
    });
    this.initializeForm();
  }

  initializeForm() {
    this.fetchClinicsList();
    this.fetchUserPermissions();
  }

  get isLoading(): boolean {
    return (
      this.fetchingClinic || this.fetchingPermissions || this.fetchingAccount
    );
  }

  get canSave() {
    if (this.isRegister) {
      return this.sessionManager.getUserPermission(PermissionConst.add_account);
    } else {
      if (!this.isSuperUserOrStaff) {
        if (
          this.existingAccountData &&
          (this.existingAccountData.is_staff ||
            this.existingAccountData.is_superuser)
        ) {
          return false;
        } else {
          return this.sessionManager.getUserPermission(
            PermissionConst.change_account
          );
        }
      } else {
        return this.sessionManager.getUserPermission(
          PermissionConst.change_account
        );
      }
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(
      PermissionConst.remove_account
    );
  }

  get currentClinic() {
    if (!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent();
    }
    return '';
  }

  get currentClinicId() {
    if (!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent().value;
    }
    return '';
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  openAssociateUserModalComponent(response: AccountModel) {
    let modalRef = this.modalService.open(AssociateUserModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.patient = response;
    modalRef.componentInstance.clinic = this.currentClinic
      ? this.currentClinic
      : null;
    modalRef.result.then((result) => {
      if (result) {
        this.setForm(response);
      } else {
        this.loadingAccount = false;
      }
    });
  }

  searchAccount() {
    let phoneNumber = this.formGroup.get('phone_number')?.value;
    let countryCode = this.formGroup.get('country_code')?.value;
    let encodedCountryCode = encodeURIComponent(countryCode);
    this.loadingAccount = true;
    this.accountsService
      .accountSearchByPhoneNumber(phoneNumber, encodedCountryCode)
      .subscribe(
        (response) => {
          if (response) {
            if (response.clinics?.length == 0) {
              this.openAssociateUserModalComponent(response);
            } else {
              this.setForm(response);
            }
          } else {
            this.loadingAccount = false;
          }
        },
        (error) => {
          this.loadingAccount = false;
        }
      );
  }

  setForm(data: any) {
    let connectClinic = data.clinics.find(
      (item: any) => item == this.currentClinicId
    );
    if (connectClinic != undefined || this.isSuperUserOrStaff) {
      this.router.navigate([`dashboard/registers/clinicUsers/edit/${data.id}`]);
    } else {
      this.formGroup.patchValue(data);
      this.connect = true;
      this.submitButton = 'Conectar';
      if (!this.isSuperUserOrStaff && (data.is_superuser || data.is_staff)) {
        this.formGroup.disable();
        this.formGroup.get('document_number')?.enable();
      }
    }
  }

  cancelHandler() {
    this.router.navigate(['dashboard/registers/clinicUsers']);
  }

  revokeClinic(clinicId: any) {
    if (this.isRegister) {
      let message = 'Removendo essa clínica o usuário não será criado.';
      const modalRef = this.modalService.open(ErrorModalComponent, {
        centered: true,
      });
      modalRef.componentInstance.text = message;
      modalRef.result.then((result) => {
        return;
      });
    } else {
      let message = 'Tem certeza que quer sair dessa clínica?';
      if (this.existingAccountData.is_professional) {
        message =
          'Saindo dessa clínica como Usuário você vai desabilitar o acesso como profissional.';
      }
      const modalRef = this.modalService.open(ConfirmationModalComponent, {
        centered: true,
      });
      modalRef.componentInstance.text = message;
      modalRef.result.then((result) => {
        if (result == true) {
          this.accountClinicService.revoke(clinicId, this.id).subscribe(
            () => {
              this.toast.success('Clínica removida com sucesso', 'Sucesso');
              let currentAccountId = this.sessionManager.accountData.id;

              if (this.id == currentAccountId) {
                if (this.currentClinicId == clinicId) {
                  let clinicsIds = this.sessionManager.clinicsListId;
                  let newClinicId = clinicsIds
                    .split(',')
                    .find((c) => c != clinicId);
                  this.sessionManager.setClinicCurrent(
                    this.clinicList.find((c) => c.value == newClinicId)
                  );
                  this.reloadAccount(newClinicId);
                } else {
                  this.reloadAccount(this.currentClinicId);
                }
              }
            },
            (errorResponse: HttpErrorResponse) => {
              this.submittingRemove = false;
              this.mapErrorResponse(errorResponse);
            }
          );
        }
      });
    }
  }

  reloadAccount(clinicId: any) {
    const fnResponse = (response: any) => {
      this.sessionManager.setSessionData(response);
      window.location.reload();
    };
    const fnError = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    };

    if (clinicId) {
      if (this.isSuperUserOrStaff) {
        this.accountsService
          .clinicUserGet(this.id)
          .subscribe(fnResponse, fnError);
      } else {
        this.accountClinicService
          .getOne(clinicId, this.id)
          .subscribe(fnResponse, fnError);
      }
    } else {
      this.sessionManager.clearSessionData();
    }
  }

  removeHandler() {
    this.submittingRemove = true;
    let message = 'Deseja desativar este Usuário?';
    if (
      this.existingAccountData.is_patient &&
      this.existingAccountData.is_professional
    ) {
      message =
        'Desativando este Usuario você vai desabilitar o acesso dele como profissional e paciente.';
    } else if (this.existingAccountData.is_patient) {
      message =
        'Desativando este Usuario você vai desabilitar o acesso dele como paciente.';
    } else if (this.existingAccountData.is_professional) {
      message =
        'Desativando este Usuario você vai desabilitar o acesso dele como profissional.';
    }
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.text = message;
    modalRef.result.then((result) => {
      if (result == true) {
        this.accountsService.clinicUserRemove(this.id).subscribe(
          () => {
            this.toast.success('Usuário removido com sucesso', 'Sucesso');
            this.cancelHandler();
          },
          (errorResponse: HttpErrorResponse) => {
            this.submittingRemove = false;
            this.mapErrorResponse(errorResponse);
          }
        );
      } else {
        this.submittingRemove = false;
      }
    });
  }

  submitHandler() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.submitting = true;
      let accountData = this.formGroup.getRawValue() as AccountModel;
      if (accountData.clinics == null) accountData.clinics = undefined;
      if (this.isRegister) {
        if (this.connect && !this.isSuperUserOrStaff) {
          this.connectClinic();
        } else {
          this.accountRegister(accountData);
        }
      } else {
        this.accountEdit(accountData);
      }
    } else {
      this.toast.error('Selecione uma clínica', 'Erro');
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
  }

  connectClinic() {
    let accountId = this.formGroup.get('id')?.value;
    this.accountClinicService
      .connect(this.currentClinicId, accountId)
      .subscribe(
        (response) => {
          if (this.changedImage) {
            this.accountClinicService
              .uploadImage(
                this.imageFile!,
                response.id!,
                Number(this.currentClinicId)
              )
              .subscribe(() => {});
          }
          this.toast.success('Usuário conectado com sucesso', 'Sucesso');
          this.cancelHandler();
          this.submitting = false;
        },
        (error) => {
          const response = error as HttpErrorResponse;
          this.mapErrorResponse(response);
          this.submitting = false;
        }
      );
  }

  accountEdit(accountData: AccountModel) {
    accountData.is_active = true;
    const fnResponse = (response: AccountModel) => {
      if (this.changedImage) {
        if (this.isSuperUserOrStaff) {
          this.accountsService
            .uploadImage(this.imageFile!, response.id!)
            .subscribe(() => {}, fnError);
        } else {
          this.accountClinicService
            .uploadImage(this.imageFile!, response.id!, this.currentClinicId)
            .subscribe(() => {}, fnError);
        }
      }
      this.submitting = false;
      this.toast.success('Usuário alterado com sucesso', 'Sucesso');
      if (this.id == this.sessionManager.accountData.id) {
        this.reloadAccount(this.currentClinicId);
      }
      this.cancelHandler();
    };

    const fnError = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.toast.error(
        'Verifique se todos os campos obrigatórios foram preenchidos corretamente.',
        'Erro'
      );
      this.submitting = false;
    };

    if (this.isSuperUserOrStaff) {
      this.accountsService
        .clinicUserEdit(accountData)
        .subscribe(fnResponse, fnError);
    } else {
      this.accountClinicService
        .edit(this.currentClinicId, accountData)
        .subscribe(fnResponse, fnError);
    }
  }

  accountRegister(accountData: AccountModel) {
    accountData.id = undefined;
    const fnResponse = (response: any) => {
      if (this.changedImage) {
        if (this.isSuperUserOrStaff) {
          this.accountsService
            .uploadImage(this.imageFile!, response.id!)
            .subscribe(() => {});
        } else {
          this.accountClinicService
            .uploadImage(
              this.imageFile!,
              response.id!,
              Number(this.currentClinicId)
            )
            .subscribe(() => {});
        }
      }
      this.toast.success('Usuário criado com sucesso', 'Sucesso');
      this.cancelHandler();
      this.submitting = false;
    };
    const fnError = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.toast.error(
        'Verifique se todos os campos obrigatórios foram preenchidos corretamente.',
        'Erro'
      );
      this.submitting = false;
    };
    if (this.isSuperUserOrStaff) {
      this.accountsService
        .clinicUserRegister(accountData)
        .subscribe(fnResponse, fnError);
    } else {
      this.accountClinicService
        .register(Number(this.currentClinicId), accountData)
        .subscribe(fnResponse, fnError);
    }
  }

  onImageSelect(file: any) {
    this.imageFile = file;
    this.changedImage = true;
  }

  get permissionsMap() {
    let mapping = {};
    this.userPermissions.map((permission) => {
      if (permission.id)
        Object.assign(mapping, { [permission.id]: permission.codename });
    });
    return mapping;
  }

  get permissionModels() {
    return Object.keys(this.groupBy(this.userPermissions, 'model'));
  }

  getPermissions(name: string): [] {
    return this.groupBy(this.userPermissions, 'model')[name];
  }

  groupBy(arr: any[], property: string) {
    return arr.reduce((acc, cur) => {
      acc[cur[property]] = [...(acc[cur[property]] || []), cur];
      return acc;
    }, {});
  }

  onCheckChange(event: any) {
    let userPermissions: FormArray = this.formGroup.get(
      'user_permissions'
    ) as FormArray;

    if (event.target.checked) {
      userPermissions.push(this.fb.control(event.target.value));
      this.enableCheckbox(event.target.id);
    } else {
      let controls = userPermissions.controls;
      userPermissions = this.fb.array(
        controls.filter((control) => control.value != event.target.value)
      );
      this.formGroup.setControl('user_permissions', userPermissions);
      this.disableCheckbox(event.target.id);
    }
  }

  isDisabled(permission: PermissionModel) {
    if (!this.canSave) return true;
    return this.disabledPermissions.some(
      (codename) => codename == permission!.codename
    );
  }

  isChecked(permission: PermissionModel) {
    let formArray = this.formGroup.get('user_permissions') as FormArray;
    return formArray.value.some((id: number) => id == permission.id);
  }

  disableCheckbox(codename: string) {
    let permissionModel = codename.split('_')[1];
    let userPermissions: FormArray = this.formGroup.get(
      'user_permissions'
    ) as FormArray;
    let controls = userPermissions.controls;

    if (codename.includes('view')) {
      this.disabledPermissions.push(`change_${permissionModel}`);
      this.disableCheckbox(`change_${permissionModel}`);
      this.disabledPermissions.push(`export_${permissionModel}`);
      this.disableCheckbox(`export_${permissionModel}`);
      this.disabledPermissions.push(`import_csv_${permissionModel}`);
      this.disableCheckbox(`import_csv_${permissionModel}`);
    } else if (codename.includes('change')) {
      this.disabledPermissions.push(`add_${permissionModel}`);
      this.disableCheckbox(`add_${permissionModel}`);
    } else if (codename.includes('add')) {
      this.disabledPermissions.push(`remove_${permissionModel}`);
      this.disableCheckbox(`remove_${permissionModel}`);
    }

    this.disabledPermissions.map((codename) => {
      let index = Object.values(this.permissionsMap).indexOf(codename);
      let id = Object.keys(this.permissionsMap)[index];
      controls = controls.filter((control) => control.value != id);
    });
    userPermissions = this.fb.array(controls);
    this.formGroup.setControl('user_permissions', userPermissions);
  }

  enableCheckbox(codename: string) {
    let permissionModel = codename.split('_')[1];

    if (codename.includes('view')) {
      this.disabledPermissions = this.disabledPermissions.filter(
        (_codename) => _codename != `change_${permissionModel}`
      );
      this.disabledPermissions = this.disabledPermissions.filter(
        (_codename) => _codename != `export_${permissionModel}`
      );
      this.disabledPermissions = this.disabledPermissions.filter(
        (_codename) => _codename != `import_csv_${permissionModel}`
      );
    } else if (codename.includes('change')) {
      this.disabledPermissions = this.disabledPermissions.filter(
        (_codename) => _codename != `add_${permissionModel}`
      );
    } else if (codename.includes('add')) {
      this.disabledPermissions = this.disabledPermissions.filter(
        (_codename) => _codename != `remove_${permissionModel}`
      );
    }
  }

  toggleAllPermissions(event: any) {
    let userPermissions: FormArray = this.formGroup.get(
      'user_permissions'
    ) as FormArray;
    if (event.target.checked) {
      this.userPermissions.map((permission) => {
        userPermissions.push(this.fb.control(permission.id));
        this.enableCheckbox(permission.codename ? permission.codename : '');
      });
    } else {
      userPermissions = this.fb.array([]);
      this.formGroup.setControl('user_permissions', userPermissions);
      this.userPermissions.map((permission) => {
        this.disableCheckbox(permission.codename ? permission.codename : '');
      });
    }
  }

  fetchUserPermissions() {
    this.fetchingPermissions = true;
    this.accountsService.permissionsList().subscribe(
      (response) => {
        this.userPermissions = response;
        let userPermissions: FormArray = this.formGroup.get(
          'user_permissions'
        ) as FormArray;
        this.userPermissions.map((permission) => {
          userPermissions.push(this.fb.control(permission.id));
          this.enableCheckbox(permission.codename ? permission.codename : '');
        });
        this.fetchingPermissions = false;
      },
      (error) => this.mapErrorResponse(error as HttpErrorResponse)
    );
  }

  fetchClinicsList() {
    const fnResponse = (response: any) => {
      let clinicsList = response!.map((item: any) => {
        return {
          value: item.id!.toString(),
          label: item.name,
        } as Options;
      });
      let currentClinic = clinicsList.find(
        (item: any) => item.value == this.currentClinicId
      );

      if (!this.isSuperUserOrStaff && this.isRegister) {
        this.clinicList.push(currentClinic);
      } else {
        this.clinicList = clinicsList;
      }
      this.fetchingClinic = false;
    };

    this.fetchingClinic = true;
    if (this.isSuperUserOrStaff) {
      this.clinicsService.getAll().subscribe(fnResponse);
    } else {
      this.clinicsService
        .userClinicGetAll(this.sessionManager.clinicsListId.toString())
        .subscribe(fnResponse);
    }
  }

  fetchAccountExistingData() {
    const fnResponse = (response: any) => {
      this.existingAccountData = response;
      this.formGroup.patchValue(this.existingAccountData);
      this.checkedAdmin = this.existingAccountData.is_superuser ?? false;
      let permissionControls = this.fb.array([]);
      this.existingAccountData.user_permissions?.forEach((value) => {
        let control = this.fb.control(value);
        permissionControls.push(control);
        let codename = PermissionConst[value];
        this.enableCheckbox(codename);
      });
      this.formGroup.setControl('user_permissions', permissionControls);
      if (this.existingAccountData.is_active) {
        this.removeButton = 'Desativar';
        this.submitButton = 'Salvar';
        this.button = true;
      } else {
        this.button = false;
        this.submitButton = 'Reativar';
        this.formGroup.disable();
        this.is_active = false;
      }
      if (this.formGroup.get('country_code')?.value == null) {
        this.formGroup.get('country_code')?.setValue('+55');
      }
      if (!this.isSuperUserOrStaff) {
        this.clinicList = this.clinicList.filter((item) =>
          this.existingAccountData.clinics?.some(
            (clinic) => clinic == item.value
          )
        );
        this.button = false;
        if (
          this.existingAccountData.is_staff ||
          this.existingAccountData.is_superuser
        ) {
          this.formGroup.disable();
        }
      }
      this.image = response.profile_image ?? '';
      if (this.fetchingClinic && this.fetchingPermissions) {
        this.fetchingAccount = true;
      }
      this.formGroup.get('document_number')?.disable();
      this.formGroup.get('is_superuser')?.disable();
    };

    const fnError = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    };

    if (this.isSuperUserOrStaff) {
      this.accountsService
        .clinicUserGet(this.id)
        .subscribe(fnResponse, fnError);
    } else {
      this.accountClinicService
        .getOne(Number(this.currentClinicId), this.id)
        .subscribe(fnResponse, fnError);
    }
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error['detail']) {
      this.toast.error(errorResponse.error['detail'], 'Erro');
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    } else {
      this.setFormErrors(errorResponse);
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      'id',
      'name',
      'email',
      'birthdate',
      'document_number',
      'user_permissions',
      'clinics',
    ];

    errNames.forEach((name) => {
      if (errorResponse.error[name]) {
        this.formGroup
          .get(name)
          ?.setErrors({ response: errorResponse.error[name] });
      }
    });
  }

  resetPassword() {
    this.router.navigate([
      `/dashboard/registers/clinicUsers/reset_password/${this.id}`,
    ]);
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  }
}
