<div class="row list-nav ">
  <div class="list-nav__menu">
    <div class="list-nav__menu--card ">
      <ul class="list-nav__menu--card--items">
        <li *ngFor="let item of menuItems; let i = index">
          <ng-container>
            <a *ngIf="!item.subItems" [routerLink]="item.routerLink" routerLinkActive="active" [ngClass]="item.toView ? '' : 'd-none'">
              {{item.label}}
            </a>

            <button *ngIf="item.subItems" (click)="subItemsClickHandle(i)"
              [class]="!item.collapsed ? 'displaying-sub-items': ''">
              {{item.label}}
              <span class="icon icon-Icon-awesome-caret-down"></span>
            </button>

            <ul *ngIf="item.subItems" [ngClass]="[item.collapsed ? 'collapsed': '']">
              <li *ngFor="let subItem of item.subItems" [ngClass]="subItem.toView ? '' : 'd-none'">
                <a class="sub-item" [routerLink]="subItem.routerLink" routerLinkActive="active">
                  {{subItem.label}}
                </a>
              </li>
            </ul>
          </ng-container>
        </li>
      </ul>
    </div>
  </div>
</div>
