import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { HealthInsuranceModel } from 'src/app/core/models/health-insurance.model';
import { FormInput, inputType } from '../../models/form-input';
import { OptionModel } from '../../models/option';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'multidata-input',
  templateUrl: './multidata-input.component.html',
  styleUrls: ['./multidata-input.component.scss']
})
export class MultidataInputComponent implements OnInit {

  @Input() id!: string;
  @Input() label!: string;
  @Input() inputs!: FormInput[];
  @Input() array!: FormArray;
  @Input() formGroup!: FormGroup;
  @Input() canRemoveFirst!: boolean;
  @Input() canRemoveAll!: boolean;
  @Input() healthInsuranceData: HealthInsuranceModel[] = [];
  @Input() healthInsurancePlanInputOptions: any[] = [];
  @Input() healthInsuranceEdit!: boolean;
  @Input() phoneNumber!: boolean;
  @Input() readOnlyMultidata!: boolean;
  @Input() readOnlyMultidataInitialValues!: any[];
  @Input() showAddItem!: boolean;

  readOnlyValues: any[] = [];

  constructor(private elRef: ElementRef, config: NgbModalConfig, private modalService: NgbModal) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    if (this.healthInsuranceEdit) {
      for (var i = 0; i < this.array.length; i++) {
        if (i == 0) {
          this.onSearchValueChange(0, 1, this.array.get([i])?.get("health_insurance_id")?.value, true);
        }
        else {

          this.onSearchValueChange(i, 1, this.array.get([i])?.get("health_insurance_id")?.value, true);
        }
      }
    }

    if (this.readOnlyMultidata) {
      if (this.readOnlyMultidataInitialValues) {
        this.readOnlyMultidataInitialValues.forEach((element, index) => {
          const multiDataInputGroup: FormGroup = new FormGroup({});
          const formControl: FormControl = new FormControl(this.inputs[0].value, this.inputs[0].validators);
          multiDataInputGroup.addControl(this.inputs[0].name, formControl);

          this.array.push(multiDataInputGroup);
          this.readOnlyValues.push({ name: element.value.toString(), label: element.display });
          this.array.get([this.array.length - 1])?.setValue({ professional: element.value.toString() });
        })

        this.readOnlyValues?.forEach((a: any) => {
          this.inputs[0].dropdownOptions?.forEach((element, index) => {
            if (a.name == element.name) {
              this.inputs[0].dropdownOptions?.splice(index, 1);
            }
          })
        })
      }
    }
  }

  dataAddHandler(event: Event) {
    if (!this.readOnlyMultidata) {
      if (this.array.valid) {
        const multiDataInputGroup: FormGroup = new FormGroup({});

        for (let input = 0; input < this.inputs.length; input++) {
          if (this.phoneNumber && input == 0) {
            const formControl: FormControl = new FormControl("+55", [Validators.required]);
            multiDataInputGroup.addControl("country_code", formControl);

            const formControl2: FormControl = new FormControl("", [Validators.required]);
            multiDataInputGroup.addControl("phone_number", formControl2);
          }
          else {
            const formControl: FormControl = new FormControl(this.inputs[input].value, this.inputs[input].validators);
            multiDataInputGroup.addControl(this.inputs[input].name, formControl);
          }

          var newOptions: OptionModel[] = [];
          this.healthInsurancePlanInputOptions.push(newOptions);
        }

        this.array.push(multiDataInputGroup);
      }
    }
    else {
      if (this.array.get([0])?.value.professional) {
        const multiDataInputGroup: FormGroup = new FormGroup({});
        const formControl: FormControl = new FormControl(this.inputs[0].value, this.inputs[0].validators);
        multiDataInputGroup.addControl(this.inputs[0].name, formControl);

        this.array.push(multiDataInputGroup);
        this.inputs[0].dropdownOptions?.forEach((element: any, index: number) => {
          if (element.name == parseInt(this.array.get([0])?.value.professional)) {
            this.readOnlyValues.push(element);
            this.array.get([this.array.length - 1])?.setValue({ professional: element.name });
            this.inputs[0].dropdownOptions?.splice(index, 1);
          }
        })
        this.array.get([0])?.reset();
      }
    }

    event.preventDefault();
  }

  async dataRemoveHandler(index: number, event: Event) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja remover este item?";
    modalRef.result.then((result) => {
      if (result == true) {
        if (this.readOnlyValues) {
          this.inputs[0].dropdownOptions?.push(this.readOnlyValues[index - 1]);
          this.readOnlyValues.splice(index - 1, 1);
          this.array.removeAt(index);
        }
        else {
          if (this.array.length > 1)
            this.array.removeAt(index);
          else {
            this.array.get([0])?.reset();
            this.canRemoveFirst = false;
          }
        }
      }
    })

    event.preventDefault();
  }

  onDropdownChange(arrayIndex: number, inputIndex: number, value: string) {
    this.inputs![inputIndex].onValueChange!(arrayIndex, value);
  }

  onSearchValueChange(inputArrayIndex: number, inputIndex: number, value: string, first: boolean = false) {
    if (this.healthInsuranceData && inputIndex == 0) {
      var selectedHealthInsurance = this.healthInsuranceData.filter((healthInsurance) => healthInsurance.id == parseInt(value));
      if(selectedHealthInsurance) {
          var plans = selectedHealthInsurance[0].plans!.map(({ id, name }) => ({ name: id, label: name }) as OptionModel);

          if (inputArrayIndex == 0) this.inputs![1].dropdownOptions = plans;
          else this.healthInsurancePlanInputOptions[inputArrayIndex - 1] = plans;

          this.array.get([inputArrayIndex])?.get("health_insurance_plan")?.setValue(plans[0]);
      }
    }
    else if (this.healthInsuranceEdit && inputIndex == 1) {
      var selectedHealthInsurance = this.healthInsuranceData.filter((healthInsurance) => healthInsurance.id == parseInt(value));
      if(selectedHealthInsurance && selectedHealthInsurance[0]) {
          var plans = selectedHealthInsurance[0].plans!.map(({ id, name }) => ({ name: id, label: name }) as OptionModel);

          if (inputArrayIndex == 0) { this.inputs![2].dropdownOptions = plans; }
          else {
            this.healthInsurancePlanInputOptions[inputArrayIndex - 1] = plans;
          }

          if (!first) this.array.get([inputArrayIndex])?.get("health_insurance_plan")?.setValue(plans[0]);
      }
    }
  }

  findNestedFormGroup(index: number): FormGroup {
    return (this.formGroup.get(this.id)?.get([index]) as FormGroup);
  }

  getOptionsList(inputArrayIndex: number, inputIndex: number): OptionModel[] {
    if (!this.healthInsuranceEdit) {
      if (inputArrayIndex > 0) {
        if (inputIndex == 0) {
          return this.inputs[0].dropdownOptions!;
        }
        else {
          return this.healthInsurancePlanInputOptions[inputArrayIndex - 1];
        }
      }
      else {
        if (inputIndex == 0) {
          return this.inputs[0].dropdownOptions!;
        }
        else {
          return this.inputs[1].dropdownOptions!;
        }
      }
    }
    else {
      if (inputArrayIndex > 0) {
        if (inputIndex == 1) {
          return this.inputs[1].dropdownOptions!;
        }
        else {
          return this.healthInsurancePlanInputOptions[inputArrayIndex - 1];
        }
      }
      else {
        if (inputIndex == 1) {
          return this.inputs[1].dropdownOptions!;
        }
        else {
          return this.inputs[2].dropdownOptions!;
        }
      }
    }
  }
}
