import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {OximeterService} from "../../../../core/services/oximeter.service";
import {Question} from "../../../../core/models/question.model";
import {ToastrService} from "ngx-toastr";
import {HttpErrorResponse} from "@angular/common/http";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {Options} from "../../select-input/models/Options";

@Component({
  selector: 'app-answer-question-modal',
  templateUrl: './answer-question-modal.component.html',
  styleUrls: ['./answer-question-modal.component.scss']
})
export class AnswerQuestionModalComponent implements OnInit {

  polysomnographyId!: number;
  questionId!: number | null;
  clinicId!: number;

  question!: Question;
  questionList: Question[] = [];
  questionOptions: Options[] = [];

  answer!: any;

  loading: boolean = true;

  images: File[] = [];


  constructor(private activeModal: NgbActiveModal, private oximeterService: OximeterService,
              private toast: ToastrService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    if (this.questionId) {
      this.fetchQuestion();
    } else {
      this.fetchQuestionsWithoutAnswer();
    }
  }

  get disabledSubmitButton() {
    if (this.answer == null) {
      return !this.question.answer
    } else if (this.question.type == 'IMAGE') {
      return !(this.images.length > 0)
    }
    return this.answer == this.question.answer
  }

  closeModal() {
    this.activeModal.close();
  }

  isSelfie(question: Question) {
    return question.question.includes('Selfie');
  }

  setQuestionId(event: any) {
    if (event) {
      this.questionId = event['value'];
    } else {
      this.questionId = null;
    }
  }

  setQuestion() {
    let question = this.questionList.find(_quesiton => _quesiton.id == this.questionId);
    this.question = question!;
    this.answer = this.question.answer;
  }

  fetchQuestionsWithoutAnswer() {
    this.oximeterService.listQustionsWithoutAnswer(this.polysomnographyId, this.clinicId).subscribe(response => {
      this.questionOptions = response.map(item => {
        return {
          value: item.id,
          label: item.question
        } as Options
      })
      this.questionList = response;
      this.loading = false;
    }, error => {
      this.mapErrorResponse(error)
    })

  }

  fetchQuestion() {
    this.oximeterService.getQuestion(this.polysomnographyId, this.questionId!, this.clinicId).subscribe(response => {
      this.question = response;
      this.answer = this.question.answer;
      this.loading = false;
    }, error => {
      this.mapErrorResponse(error);
      this.closeModal();
    })
  }

  treatAnswer(): any {
    let answer = this.question.answer

    if (this.question?.type == 'NUMBER') {
      answer = parseInt(answer);
    } else if (this.question?.type == 'FLOAT') {
      let replacedAnswer = answer.replaceAll(',', '.');
      answer = parseFloat(replacedAnswer);
    } else if (this.question.type == 'IMAGE') {
      answer = this.images;
    }
    return answer
  }

  getRequestData() {
    let treatedAnswer = this.treatAnswer();
    if (this.question.type == 'IMAGE') {
      let data = new FormData();
      data.set('override_images', this.isSelfie(this.question).toString());
      for (let file of treatedAnswer) {
        data.append('answer', file);
      }
      return data
    }
    return {answer: treatedAnswer}
  }

  getSuccessMessage(): string {
    let message = 'Resposta alterada com sucesso';
    if (!this.answer) {
      message = 'Pergunta respondida com sucesso'
    }
    return message
  }



  submit() {
    let data = this.getRequestData();
    this.oximeterService.answerQuestion(this.polysomnographyId, this.questionId!, this.clinicId, data).subscribe(response => {
      let message = this.getSuccessMessage();
      console.log(message)
      this.toast.success(message, 'Sucesso')
      this.activeModal.close(response)
    }, error => {
      this.mapErrorResponse(error);
    })
  }

  toggleCheckbox(answer: string) {
    if (this.question) {
      if (!this.question.answer) {
        this.question.answer = [];
      }
      if (this.question.answer.includes(answer)) {
        this.question.answer = this.question?.answer.filter((_answer: string) => _answer != answer);
      } else {
        this.question.answer.push(answer);
      }
    }
  }

  addImage(event: any) {
    if (this.isSelfie(this.question!)) {
      this.images = [];
      this.question!.answer = [];
    }
    this.images.push(...event.target.files);
    if (!this.question!.answer) {
      this.question!.answer = [];
    }
    this.question!.answer.push(...event.target.files);
  }

  readImage(file: File | string): SafeUrl {
    if (file instanceof File) {
      return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
    } else {
      return file;
    }
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error['detail'], 'Erro')
    }
  }

}
