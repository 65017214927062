import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { MenuItem } from '../models/menu-item';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { ClinicsService } from 'src/app/core/services/clinics.service';

@Component({
  selector: 'list-nav',
  templateUrl: './list-nav.component.html',
  styleUrls: ['./list-nav.component.scss']
})
export class ListNavigationComponent implements OnInit {

  constructor(private router: Router, private sessionManager: SessionManagerService, private clinicService: ClinicsService) { }

  get menuItems(): MenuItem[] {
    if (this.router.url.split("/")[2] == "registers") {
      return this.menuRegisters;
    } else return this.menuPatient;
  }

  get user() {
    return this.sessionManager.getSessionData();
  }

  get superUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  get labelClinic() {
    if (!this.superUserOrStaff) {
      return 'Minha clínica';
    }
    return 'Clínicas';
  }

  get labelProfessional(): string {
    if (this.user.is_professional && this.user.professional_id) {
      return this.user.name!;
    }
    return 'Profissionais';
  }

  get routerLinkClinic() {
    if (!this.superUserOrStaff) {
      // if (this.user.clinics != null && this.user.clinics?.length == 1) {
        let name = this.sessionManager.getClinicCurrent();
        return `clinics/edit/${name.value}`;
      // }
    }
    return 'clinics';
  }

  get routerLinkProfessional() {
    if (!this.superUserOrStaff) {
      if (this.user.is_professional && this.user.professional_id) {
        return `professionals/edit/${this.user.professional_id}`;
      }
    }
    return '';
  }

  get toViewProfessionalLabel() {
    if(!this.superUserOrStaff) {
      if(this.user.is_professional && this.user.professional_id){
        return true;
      }
    }
    return false;
  }

  menuRegisters = [
    { label: this.labelClinic, routerLink: this.routerLinkClinic, toView: this.superUserOrStaff || this.sessionManager.getUserPermission(PermissionConst.view_clinic) },
    { label: "Usuários de Administração", routerLink: "systemUsers", toView: this.superUserOrStaff },
    { label: "Usuários", routerLink: "clinicUsers", toView: this.superUserOrStaff || this.sessionManager.getUserPermission(PermissionConst.view_account) },
    { label: this.labelProfessional, routerLink: this.routerLinkProfessional, toView: this.toViewProfessionalLabel },
    { label: "Profissionais", routerLink: "professionals", toView: this.superUserOrStaff || this.sessionManager.getUserPermission(PermissionConst.view_professional) },
    { label: "Profissões", routerLink: "professions", toView: this.superUserOrStaff || this.sessionManager.getUserPermission(PermissionConst.view_profession) },
    {
      label: "Tags", collapsed: true, subItems: [
        { label: "Tags de Pacientes", routerLink: "patientTags", toView: this.superUserOrStaff || this.sessionManager.getUserPermission(PermissionConst.view_tag) },
        { label: "Tags de Clínicas", routerLink: "clinicTags", toView: this.superUserOrStaff },
        { label: "Tags de Profissionais", routerLink: "professionalTags", toView: this.superUserOrStaff  || this.sessionManager.getUserPermission(PermissionConst.view_tag)},
      ], toView: this.superUserOrStaff || this.sessionManager.getUserPermission(PermissionConst.view_tag)
    },
    { label: "Áreas de Atuação", routerLink: "specialties", toView: this.superUserOrStaff || this.sessionManager.getUserPermission(PermissionConst.view_specialty) },
    { label: "Cupons", routerLink: "coupons", toView: this.superUserOrStaff }
  ];

  menuPatient = [
    { label: "Dados pessoais", routerLink: "personal", toView: this.superUserOrStaff || this.sessionManager.getUserPermission(PermissionConst.view_patient) },
    { label: "Anexos", routerLink: "attachments", toView: this.superUserOrStaff || this.sessionManager.getUserPermission(PermissionConst.view_patient) },
    { label: "Polissonografia", routerLink: "polysomnography", toView: this.superUserOrStaff || this.sessionManager.getUserPermission(PermissionConst.view_polysomnography) }
  ];

  ngOnInit() {
    if (this.router.url.split("/")[2] == "registers") {
      if (this.router.url.split("/")[3]) {
        if (this.router.url.split("/")[3] != "clinicTags" && this.router.url.split("/")[3] != "patientTags" && this.router.url.split("/")[3] != "professionalTags") {
          this.menuItems[4].collapsed = true;
        }
        else {
          this.menuItems[4].collapsed = false;
        }

        this.router.events.subscribe(event => {
          if (event instanceof NavigationStart) {
            if (event.url.split("/")[3] != "clinicTags" && event.url.split("/")[3] != "patientTags" && event.url.split("/")[3] != "professionalTags") {
              this.menuItems[4].collapsed = true;
            }
            else {
              this.menuItems[4].collapsed = false;
            }
          }
        })
      }
    }
  }

  subItemsClickHandle(index: number) {
    this.menuItems[index].collapsed = !this.menuItems[index].collapsed;
  }
}
