import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GroupModel } from 'src/app/core/models/group.model';
import { PermissionModel } from 'src/app/core/models/permission.model';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { FormRendererComponent } from 'src/app/shared/renderers/form-renderer/form-renderer.component';
import { FormInput, inputType } from 'src/app/shared/renderers/models/form-input';
import { FormTemplate } from 'src/app/shared/renderers/models/form-template';
import { OptionModel } from 'src/app/shared/renderers/models/option';

@Component({
  selector: 'app-user-group-register',
  templateUrl: './user-group-register.component.html',
  styleUrls: ['./user-group-register.component.scss']
})
export class UserGroupRegisterComponent implements OnInit {

  formTemplate: FormTemplate = {
    renderOnInit: false,
    submitted: false,
    groupName: "userGroupForm",
    submitButton: { label: "Salvar" },
    cancelButton: { label: "Cancelar" },
    cancelLink: "/dashboard/registers/userGroups",
    separators: [
      {
        groupName: "groupData",
        inputs: [
          { name: "name", label: "Nome do grupo", type: inputType.BASIC, widthInCols: { sm: 8 }, validators: [Validators.required] },
        ],
      },
      {
        groupName: "groupPermissions",
        title: "Permissões do grupo",
        inputs: []
      },
    ]
  };

  @ViewChild(FormRendererComponent) renderedForm!: FormRendererComponent;

  userPermissions!: PermissionModel[];

  constructor(private accountsService: AccountsService, private router: Router) { }

  ngOnInit(): void {
    this.fetchUserGroups();
  }

  submitHandler() {
    const userGroupData = this.getFormData();
    this.userGroupRegister(userGroupData);
  }

  async userGroupRegister(userGroupData: GroupModel) {
    try {
      await this.accountsService.groupRegister(userGroupData).toPromise();
      this.formTemplate.submitted = true;
      this.router.navigate(['/dashboard/registers/userGroups'])
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.formTemplate.loading = false;
    }
  }

  async fetchUserGroups() {
    try {
      this.userPermissions = await (await this.accountsService.permissionsList().toPromise());
      this.setPermissions();
      this.renderedForm.initializeForm();
      this.formTemplate.fullLoading = false;
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.router.navigate(["/dashboard/registers/userGroups"]);
    }
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      alert(errorResponse.error["detail"]);
    }
    else {
      this.setFormErrors(errorResponse);
    }
  }

  getFormData(): GroupModel {
    var selectedPermissions: number[] = [];

    Object.values(this.renderedForm.formGroup.get("groupPermissions")?.value).forEach((permissionGroup) => {
      const selectedPermissionsCodenames = permissionGroup as string[];

      selectedPermissionsCodenames.forEach((selectedPermissionCodename) => {
        this.userPermissions.forEach((permission) => {
          if (permission.codename == selectedPermissionCodename)
            selectedPermissions.push(permission.id!)
        })
      });
    });

    return {
      name: this.renderedForm.formGroup.value['groupData']['name'],
      permissions: selectedPermissions
    } as GroupModel;
  }

  setPermissions() {
    Object.values(this.groupBy(this.userPermissions, "model")).forEach((values: any) => {
      const options = (values as PermissionModel[]).map(({ codename, name }) => ({ name: codename, label: name }) as OptionModel);
      const input = {
        name: options[0].name,
        type: inputType.CHECKBOX,
        label: values[0]['model'],
        dropdownOptions: options,
        widthInCols: { sm: 4 }
      } as FormInput
      this.formTemplate.separators![1].inputs?.push(input);
    });
  }

  groupBy(arr: any[], property: string) {
    return arr.reduce((acc, cur) => {
      acc[cur[property]] = [...acc[cur[property]] || [], cur];
      return acc;
    }, {});
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["name"]) this.renderedForm.formGroup.get("groupData")?.get("name")?.setErrors({ response: errorResponse.error['name'] });
  }

  isFormLoading(): boolean {
    if (this.userPermissions) return false;
    else return true;
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return this.renderedForm.formExitHandler();
  };
}
