import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { PasswordSetComponent } from './password-set/password-set.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { NgxMaskModule } from 'ngx-mask';
import { NaoEncontradoComponent } from './nao-encontrado/nao-encontrado.component';
import { ManualComponent } from './manual/manual.component';
import { QuestionsComponent } from './questions/questions.component';
import { RedirectToStoreComponent } from './redirect-to-store/redirect-to-store.component';
import {PlatformModule} from "@angular/cdk/platform";
import {SharedModule} from "../../shared/shared.module";

@NgModule({
  declarations: [
    LoginComponent,
    PasswordRecoveryComponent,
    PasswordSetComponent,
    NaoEncontradoComponent,
    ManualComponent,
    QuestionsComponent,
    RedirectToStoreComponent,
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RenderersModule,
        NgxMaskModule,
        PlatformModule,
        SharedModule
    ]
})
export class PublicModule { }
