import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountModel } from 'src/app/core/models/account.model';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['../publicstyle.scss']
})
export class PasswordRecoveryComponent implements OnInit {
  loading: boolean = false;
  formGroup!: FormGroup;
  showConfirm: boolean = false;

  constructor(
    private accountsService: AccountsService,
    private sessionManager: SessionManagerService,
    private router: Router,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      document_number: ['', [Validators.required]]
    });
  }

  onSubmit(e: Event): void {
    this.loading = true;
    this.passwordRecoveryRequest();
    e.preventDefault();
  }

  email?: string;

  passwordRecoveryRequest() {
    var accountData = {
      document_number: this.formGroup.get('document_number')!.value,
    } as AccountModel;

    this.accountsService.passwordRecovery(accountData).subscribe((response: AccountModel) => {
      this.email = "*****" + response.email?.slice(5);
      this.showConfirm = true;
    }, (response: HttpErrorResponse) => {
      alert(response.error['detail']);
      this.loading = false;
    });
  }

  loginLinkClickHandler() {
    this.router.navigate(['login'])
  }
}
