import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'zipcode-input',
  templateUrl: './zipcode-input.component.html',
  styleUrls: ['./zipcode-input.component.scss']
})
export class ZipcodeInputComponent implements OnInit {

  @Input() id!: string;
  @Input() formGroup!: FormGroup;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Output() onValueChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  focusOutHandle(e: any) {
    if (this.formGroup.get(this.id)?.valid) {
      this.onValueChange.emit(this.formGroup.get(this.id)?.value);
    }
  }
}
