import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ClinicsService } from 'src/app/core/services/clinics.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { Options } from 'src/app/shared/components/select-input/models/Options';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() title!: string;
  @Input() subTitle!: string;
  @Input() subTitleTemplate!: TemplateRef<any>;

  clinicFilterIsOpened: boolean = false;
  clinicList!: Options[];

  labelClinic: string = 'Carregando...';

  constructor(private sessionManager: SessionManagerService, private clinicsService: ClinicsService, private router: Router) { }

  get user() {
    return this.sessionManager.getSessionData();
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  openClinicFilter() {
    if (this.user.clinics != null && this.user.clinics.length > 0) {
      this.clinicFilterIsOpened = true;
    }
  }

  closeClinicFilter() {
    this.clinicFilterIsOpened = false;
  }

  ngOnInit() {
    if(!this.isSuperUserOrStaff){
      this.clinicList = this.sessionManager.getClinics();
      if (!this.sessionManager.getClinicCurrent()) {
        this.sessionManager.setClinicCurrent(this.clinicList.find(item => this.user.clinic == item.value));
      }
      let userClinic = this.sessionManager.getClinicCurrent();
      this.labelClinic = this.clinicList.find(item => userClinic.value == item.value)?.label ?? '';
    }
  }

  toggleClinic(clinic: any) {
    this.sessionManager.setClinicCurrent(clinic);
    this.labelClinic = clinic.label;
    this.clinicFilterIsOpened = false;

    if (this.router.url.split("/")[3] == "clinics"){
      this.redirectTo(`dashboard/registers/clinics/edit/${clinic.value}`);
    } else {
      this.redirectTo(this.router.url);
    }
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('nao-encontrado', { skipLocationChange: true }).then(() =>{
      this.router.navigate([uri]).then((a)=>console.log(a));
    });
  }
}
