import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {QuizSummaryVideoService} from "../../../core/services/quiz-summary-video.service";
import {QuizSummaryVideo} from "../../../core/models/quiz-summary-video.model";

@Component({
  selector: 'app-show-video',
  templateUrl: './show-video.component.html',
  styleUrls: ['./show-video.component.scss']
})
export class ShowVideoComponent implements OnInit {
  loading = false;

  quizSummaryVideo?: QuizSummaryVideo;
  videoSource?: SafeUrl;

  constructor(private activatedRoute: ActivatedRoute, private quizService: QuizSummaryVideoService, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.fetchQuizSummaryVideo(params['code']);
    });
  }

  fetchQuizSummaryVideo(code: string) {
    this.loading = true;

    this.quizService.getQuizSummaryVideo(code).subscribe({
      next: response => {
        this.quizSummaryVideo = response;
        this.setVideoUrl();
        this.loading = false;
      }, error: error => {
        console.error(error);
      }
    });
  }

  setVideoUrl() {
    let url = this.quizSummaryVideo?.video_url ?? '';
    let videoId;

    if (url.includes('youtu.be')) {
      let urlWithoutQueryParams = url.split('?')[0];
      videoId = urlWithoutQueryParams.split('youtu.be/')[1];
    } else {
      let queryParams = url.split('?')[1];
      let splittedQueryParams = queryParams.split('&');

      for (let param of splittedQueryParams) {
        let [key, value] = param.split('=');
        if (key == 'v') {
          videoId = value;
          break;
        }
      }
    }

    this.videoSource = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${videoId}`);
  }

  goToWhatsApp() {
    let url = `https://wa.me/${this.quizSummaryVideo?.phone_number}?text=`;
    window.open(url, '_blank');
  }
}
