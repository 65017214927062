<div class="field" [formGroup]="formGroup" [ngClass]="['field', removeVerticalSpacing ? 'remove-vertical-space' : '']">
    <label class="field__title" [for]="id">{{label ? label : ""}}</label>
    <select [id]="id" [formControlName]="id"
        [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '']"
        (change)="onValueChangeHandler()">
        <option *ngFor="let option of optionList" value={{option.name}}>
            {{option.label}}</option>
    </select>

    <form-error [id]="id" [formGroup]="formGroup"></form-error>
</div>