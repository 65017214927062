const healthInsuranceTypeMap: string[][] = [
  ["MEDICAL", "Médico"],
  ["DENTAL", "Odontológico"],
];

export function healthInsuranceTypeStringFormatter(string: string): string {
  return healthInsuranceTypeMap.filter((formattedString) => formattedString[0] == string)[0][1];
}

const healthInsuranceRefundMap: string[][] = [
  ["TOTAL", "Amplo"],
  ["RESTRICTED", "Restrito"],
  ["NONEXISTENT", "Inexistente"]
];

export function healthInsuranceRefundStringFormatter(string: string): string {
  return healthInsuranceRefundMap.filter((formattedString) => formattedString[0] == string)[0][1];
}

export function documentsStringFormatter(string: string): string {
  if (string.length === 11) return string.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  return string.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}

export function capitalizeStringFormatter(string: string): string {
  return string.toUpperCase();
}

export function isActiveStatusStringFormatter(status: boolean): string {
  if (status == true) return "Ativo";
  else return "Inativo";
}

export function phoneNumberStringFormatter(string: string): string {
  if (!string)
    return '';
  string = string.replace(/\D/g, "");
  if (string.length == 13) {
    return string.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/g, '+$1 ($2) $3-$4');
  } else if (string.length == 12) {
    return string.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/g, '+$1 ($2) $3-$4');
  } else {
    string = string.replace(/^(\d{2})(\d)/g, "($1) $2");
    string = string.replace(/(\d)(\d{4})$/, "$1-$2");
  }

  return string;
}
