import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AccountModel } from '../models/account.model';
import { ListModel } from '../models/list.model';

@Injectable({
  providedIn: 'root'
})
export class AccountClinicService {

  constructor(private httpClient: HttpClient) { }

  register(clinicId: number, clinicUserData: AccountModel): Observable<AccountModel> {
    return this.httpClient.post<AccountModel>(`${environment.API_URL}/clinic_accounts/?clinic_id=${clinicId}`, JSON.stringify(clinicUserData));
  }

  edit(clinicId: number, clinicUserData: AccountModel): Observable<AccountModel> {
    return this.httpClient.patch<AccountModel>(`${environment.API_URL}/clinic_accounts/${clinicUserData.id}/?clinic_id=${clinicId}`, JSON.stringify(clinicUserData));
  }

  getOne(clinicId: number, clinicUserId: number): Observable<AccountModel> {
    return this.httpClient.get<AccountModel>(`${environment.API_URL}/clinic_accounts/${clinicUserId}/?clinic_id=${clinicId}`);
  }

  getAll(clinicId: number): Observable<AccountModel[]> {
    return this.httpClient.get<AccountModel[]>(`${environment.API_URL}/clinic_accounts/all/?clinic_id=${clinicId}&is_active=true`);
  }

  list(searchString: string, orderingItem: string, clinicId: string = '', isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/clinic_accounts/?clinic_id=${clinicId}&search=${searchString}&ordering=${orderingItem}&is_active=${isActive}&page=${page}`);
  }

  connect(clinicId: number, account_id: number): Observable<AccountModel> {
    return this.httpClient.patch<AccountModel>(`${environment.API_URL}/clinic_accounts/connect/?clinic_id=${clinicId}`, {'account_id': account_id});
  }

  revoke(clinicId: number, account_id: number): Observable<AccountModel> {
    return this.httpClient.patch<AccountModel>(`${environment.API_URL}/clinic_accounts/revoke/?clinic_id=${clinicId}`, {'account_id': account_id});
  }

  uploadImage(imageFile: File | undefined, accountId: number, clinicId: number): Observable<object> {
    const formData = new FormData();
    formData.append("profile_image", imageFile ? imageFile : '');

    return this.httpClient.patch<object>(`${environment.API_URL}/accounts/${accountId}/profile_image/?clinic_id=${clinicId}`, formData);
  }

}
