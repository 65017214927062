import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { TagModel } from 'src/app/core/models/tag.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/modals/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-clinic-tag-form',
  templateUrl: './clinic-tag-form.component.html',
  styleUrls: ['./clinic-tag-form.component.scss']
})
export class ClinicTagFormComponent implements OnInit {

  formGroup = this.fb.group({
    id: [],
    name: [''],
    type: ['COMPANY']
  });

  constructor(@Inject(DOCUMENT) private document: Document, private activatedRoute: ActivatedRoute, private fb: FormBuilder,
    private router: Router, config: NgbModalConfig, private modalService: NgbModal, private registersService: RegistersService,
    private sessionManager: SessionManagerService, private toast: ToastrService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  isRegister: boolean = true;
  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton: String = "Desativar";
  submitButton: String = "Salvar";
  button: boolean = false;

  id!: number;
  existingTagData!: TagModel;
  fetchingTag: boolean = false;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params["id"];
      if (this.id) {
        this.isRegister = false;
      }
    });
    this.initializeForm();
  }

  initializeForm() {
    if (this.id) setTimeout(() => this.fetchTagExistingData(), 1000);
  }

  get fetchingData(): boolean {
    if (this.isRegister) {
      return true;
    } else {
      if (this.fetchingTag) return true;
    }
    return false;
  }

  get canSave() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_tag);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_tag);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_tag);
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  cancelHandler() {
    this.router.navigate(['dashboard/registers/clinicTags']);
  }

  fetchTagExistingData() {
    this.registersService.tagGet("", this.id, "COMPANY").subscribe(response => {
      this.existingTagData = response;
      this.formGroup.patchValue(this.existingTagData);
      if(this.existingTagData.is_active) {
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
        this.button = true;
      } else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
      }
      if(!this.isSuperUserOrStaff) {
        this.button = false;
      }
      this.fetchingTag = true;
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
    });
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar esta tag?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.registersService.tagRemove("", this.id, "COMPANY").subscribe(() => {
          this.toast.success('Tag de clínica removida com sucesso', "Sucesso");
          this.cancelHandler();
        }, (errorResponse: HttpErrorResponse) => {
          this.submittingRemove = false;
          this.mapErrorResponse(errorResponse);
        });
      } else {
        this.submittingRemove = false;
      }
    });
  }

  submitHandler() {
    this.formGroup.markAllAsTouched();
    this.submitting = true;

    let tagData = this.formGroup.getRawValue() as TagModel;
    if (this.isRegister) {
      this.tagRegister(tagData);
    } else {
      this.tagEdit(tagData);
    }
  }

  tagEdit(tagData: TagModel) {
    tagData.is_active = true;
    this.registersService.tagEdit("", tagData).subscribe(res => {
      this.toast.success('Tag de clínica alterada com sucesso', 'Sucesso');
      this.submitting = false;
      this.cancelHandler();
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    });
  }

  tagRegister(tagData: TagModel) {
    tagData.id = undefined;
    this.registersService.tagRegister("", tagData).subscribe(res => {
      this.toast.success('Tag de clínica alterada com sucesso', 'Sucesso');
      this.submitting = false;
      this.cancelHandler();
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    } else {
      this.setFormErrors(errorResponse);
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "id", "name", "type"
    ];

    errNames.forEach(name => {
      if (errorResponse.error[name]) {
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
      }
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  };

}
