import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientEditComponent } from './patient-edit.component';
import { PatientEditPersonalComponent } from './patient-edit-personal/patient-edit-personal.component';
import { PatientEditImageDatabaseComponent } from './patient-edit-image-database/patient-edit-image-database.component';
import { PatientEditAttachmentsComponent } from './patient-edit-attachments/patient-edit-attachments.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { NavigationModule } from '../../navigation/navigation.module';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { PatientPolysomnographyComponent } from './patient-polysomnography/patient-polysomnography.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    PatientEditComponent,
    PatientEditPersonalComponent,
    PatientEditImageDatabaseComponent,
    PatientEditAttachmentsComponent,
    PatientPolysomnographyComponent
  ],
  imports: [
    CommonModule,
    NavigationModule,
    RouterModule,
    RenderersModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule,
    SharedModule,
    CommonModule,
    RouterModule,
    NavigationModule,
    RenderersModule,
    SharedModule,
    NgbTooltipModule,
    ReactiveFormsModule,
  ]
})
export class PatientEditModule { }
