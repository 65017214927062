import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FeaturesModule } from './features/features.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarModule } from 'ng-sidebar';
import { CoreModule } from './core/core.module';

import {initialConfig, NgxMaskModule} from 'ngx-mask'
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import {IConfig} from "ngx-mask";
import { PatientModule } from './features/patient/patient.module';

const maskConfig: () => Partial<IConfig> = () => {
  initialConfig['patterns']['B'] = {pattern: /[A-Z0-9]/};
  return initialConfig;
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    NgxMaskModule.forRoot(maskConfig),
    SidebarModule.forRoot(),
    ToastrModule.forRoot({
      closeButton: true,
      progressBar: true,
      progressAnimation: 'increasing',
    }),
    SharedModule,
    CoreModule,
    FeaturesModule,
    PatientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
