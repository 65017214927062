<div *ngIf="!loading else loadingTemplate" [formGroup]="formGroup">
  <div class="row">
    <div class="col card">
      <div class="col px-0">
        <h3>Cupons</h3>
      </div>
      <div class="row separator">
        <div class="col-6">
          <app-basic-input id="name" controlName="name" [formGroup]="formGroup" label="Nome (Somente letras maiúsculas ou números)"
                           [maskOptions]="{mask: 'B*'}">
          </app-basic-input>
        </div>
      </div>

      <div class="row separator">
        <div class="col-3">
          <app-basic-input id="exams_nights" controlName="exams_nights" [formGroup]="formGroup" label="Noites de exames"
                           type="number" min="1">
          </app-basic-input>
        </div>

        <div class="col-5">
          <app-select-input id="quiz_type" label="Tipo do Questionário" [formGroup]="formGroup" [items]="quiz_types"
                            [hideSelected]="false" [addTag]="false" [multiple]="false" [clearable]="false"
                            [searchable]="false">
          </app-select-input>
        </div>
      </div>

      <div class="row separator">
        <div class="col-12">
          <label class="field__title">Descrição</label>
          <textarea type="text" id="description" formControlName="description"></textarea>
        </div>
      </div>

    </div>
  </div>

  <div class="row justify-content-end mt-5 mb-5">
    <div class="col-12 col-md-3 mb-3">
      <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
        Cancelar
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3" *ngIf="button">
      <button (click)="removeHandler()" [disabled]="submittingRemove || !canRemove" class="btn btn-secondary btn-lg"
        type="button">
        {{removeButton}}
        <span class="spinner-border" *ngIf="submittingRemove"></span>
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3">
      <button (click)="submitHandler()" [disabled]="submitting" class="btn btn-primary btn-lg"
        type="button">
        {{submitButton}}
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
