<div class="confirm-code">
  <div class="logo">
    <img src="assets/chegaderonco-color.svg" alt="">
  </div>

  <div class="text">
    <p>
      Você recebeu um código por WhatsApp no telefone<br>
      {{ patient?.phone_number ?? '' }}
    </p>
  </div>

  <form class="form" [formGroup]="formGroup">
    <input type="hidden" formControlName="country_code">
    <input type="hidden" formControlName="phone_number">

    <div class="field">
      <label for="code">Insira o código abaixo</label>
      <input class="background-transparent" type="text" id="code" formControlName="code" max="5" mask="00000">
    </div>

    <div class="button-control">
      <button class="btn" (click)="submit()" [disabled]="loading">
        <ng-container *ngIf="loading">Carregando</ng-container>
        <ng-container *ngIf="!loading">Confirmar</ng-container>
      </button>
      <button class="btn-text" (click)="backToRegister()">voltar</button>
    </div>
  </form>
</div>
