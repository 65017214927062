import { PolysomnographyExamModel } from 'src/app/core/models/polysomnography-exam.model';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PolysomnographyExamsService {
  constructor(private http: HttpClient) { }

  importExamFromCSVFile(polysomnographyId: number, file: File) {
    let data = new FormData();
    data.set('file', file);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('enctype', 'multipart/form-data');
    return this.http.post(`${environment.API_URL}/polysomnography/${polysomnographyId}/exams/?upload=true`, data);
  }

  importClinicUserExam(polysomnographyId: number, examId: number, file: File, clinicId: number) {
    let formData = new FormData();
    formData.set('file', file);
    return this.http.patch(`${environment.API_URL}/polysomnography/${polysomnographyId}/exams/${examId}/csv_to_json_file/?upload=true&clinic_id=${clinicId}`, formData);
  }

}
