export enum PermissionConst {
    add_account = 1,
    change_account = 2,
    remove_account = 3,
    view_account = 4,

    add_clinic = 5,
    change_clinic = 6,
    remove_clinic = 7,
    view_clinic = 8,

    add_professional = 9,
    change_professional = 10,
    remove_professional = 11,
    view_professional = 12,

    add_healthinsurance = 13,
    change_healthinsurance = 14,
    remove_healthinsurance = 15,
    view_healthinsurance = 16,

    add_profession = 17,
    change_profession = 18,
    remove_profession = 19,
    view_profession = 20,

    add_tag = 21,
    change_tag = 22,
    remove_tag = 23,
    view_tag = 24,

    add_specialty = 25,
    change_specialty = 26,
    remove_specialty = 27,
    view_specialty = 28,

    add_patient = 29,
    change_patient = 30,
    remove_patient = 31,
    view_patient = 32,

    export_account = 33,
    export_clinic = 34,
    export_professional = 35,
    export_healthinsurance = 36,
    export_profession = 37,
    export_tag = 38,
    export_specialty = 39,
    export_patient = 40,

    view_oximeter = 41,
    change_oximeter = 42,
    add_oximeter = 43,
    remove_oximeter = 44,
    export_oximeter = 45,

    view_polysomnography = 46,
    change_polysomnography = 47,
    add_polysomnography = 48,
    remove_polysomnography = 49,
    export_polysomnography = 50,
    import_csv_polysomnography = 51,

    change_coupon = 52,
    remove_coupon = 53,
    add_coupon = 54,
    view_coupon = 55,

}
