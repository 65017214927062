<div class="wallet-id-modal-wrapper">
  <div class="wallet-id-modal">
    <div class="wallet-id-modal__header">
      <div class="row p-0 justify-content-end">
        <button class="btn btn-link col-1" (click)="activeModal.close()">
          <i class="icon-Icon-ionic-ios-close"></i>
        </button>
      </div>
    </div>

    <div class="wallet-id-modal__content">
      <h5>1. <a href="https://asaas.com/customerConfigIntegrations/index" target="_blank">Clique aqui</a> para acessar a sua conta Asaas.</h5>
      <h5>2. Copie o código circulado em vermelho (como na imagem abaixo) e cole no campo Wallet ID.</h5>
      <div class="modal-img">
        <img src="../../../../../assets/wid.png" />
      </div>
    </div>
  </div>
</div>
