import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { Options } from '../select-input/models/Options';

@Component({
  selector: 'app-badge-input',
  templateUrl: './badge-input.component.html',
  styleUrls: ['./badge-input.component.scss']
})
export class BadgeInputComponent implements OnInit {

  @Input() id!: string;
  @Input() items!: Options[];
  @Input() clearButton: boolean = true;

  @Output() onClick = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  clear(value: any) {
    this.onClick.emit(value);
  }

}
