<div class="field" [formGroup]="formGroup">
    <label class="inputLabel" [for]="id">{{label ? label : ""}}</label>

    <div class="container-fluid">
        <div class="row header">
            <div class="col-3">
            </div>

            <div class="col-3">
                <label>Início</label>
            </div>

            <div class="col-3">
                <label>Fim<br /></label>
            </div>

            <div class="col-3">
                <label>Duração do atendimento</label>
            </div>
        </div>

        <div class="row body">
            <div class="col-3">
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>
        </div>

        <div class="row body">
            <div class="col-3">
                Segunda
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>
        </div>

        <div class="row body">
            <div class="col-3">
                Terça
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>
        </div>

        <div class="row body">
            <div class="col-3">
                Quarta
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>
        </div>

        <div class="row body">
            <div class="col-3">
                Quinta
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>
        </div>

        <div class="row body">
            <div class="col-3">
                Sexta
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>
        </div>

        <div class="row body">
            <div class="col-3">
                Sábado
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>
        </div>

        <div class="row body">
            <div class="col-3">
                Domingo
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>

            <div class="col-3">
                <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
                    [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''" />
            </div>
        </div>
    </div>
    <form-error [id]="id" [formGroup]="formGroup"></form-error>
</div>