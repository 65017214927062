import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegistersService } from 'src/app/core/services/registers.service';
import { FormBuilder } from '@angular/forms';
import { FileModel } from 'src/app/core/models/file.model';
import { FileInputComponent } from '../file-input/file-input.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { PatientService } from 'src/app/core/services/patient.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'attachments-input',
  templateUrl: './attachments-input.component.html',
  styleUrls: ['./attachments-input.component.scss']
})
export class AttachmentsInputComponent implements OnInit {

  @Input() attachmetsInput: FileModel[] = [];
  @Input() disabled: boolean = false;
  attachments: FileModel[] = [];
  id!: number;
  currentFile!: File;
  hasFile: boolean = false;

  attachmentForm = this.formBuilder.group({
    name: '',
  });

  get currentClinicId() {
    if(!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent().value;
    }
    return '';
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }


  constructor(private patientService: PatientService, private toast: ToastrService, private sessionManager: SessionManagerService, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder, private modalService: NgbModal) { }

  @ViewChild(FileInputComponent) fileInput!: FileInputComponent;

  ngOnInit(): void {
    this.activatedRoute.parent?.params.subscribe(params => {
      this.id = params['id'];

      if (this.attachmetsInput) {
        this.attachments = this.attachmetsInput;
      }
    });
  }

  onFileChange(file: File) {
    this.currentFile = file;
    this.hasFile = true;
    this.attachmentForm.get("name")?.setValue(this.currentFile.name);
  }

  addButtonHandler(e: Event) {
    const fnResponse = (response: FileModel) => {
      this.toast.success('Anexo criado com sucesso', "Sucesso",);
      this.attachmentForm.reset();
      this.attachments.push(response);
      this.fileInput.reset();
      this.hasFile = false
    };

    const fnError = (error: HttpErrorResponse) => {
      this.toast.error(error.error["detail"], "Erro",);
    };

    if (this.hasFile && this.attachmentForm.valid) {
      if (this.isSuperUserOrStaff) {
        this.patientService.patientAttachmentRegister(this.currentFile, this.attachmentForm.get("name")?.value, this.id).subscribe(fnResponse, fnError);
      } else {
        this.patientService.clinicUserPatientAttachmentRegister(this.currentClinicId, this.currentFile, this.attachmentForm.get("name")?.value, this.id).subscribe(fnResponse, fnError);
      }
    }

    e.preventDefault();
  }

  removeButtonHandler(attachmentIndex: number, attachmentId: number, e: Event) {
    const fnResponse = (response: FileModel) => {
      this.toast.success('Anexo removido com sucesso', "Sucesso");
      this.attachments.splice(attachmentIndex, 1);

    };

    const fnError = (error: HttpErrorResponse) => {
      this.toast.error(error.error["detail"], "Erro",);
    };

    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja remover este anexo?";
    modalRef.result.then((result) => {
      if (result == true) {
        if(this.isSuperUserOrStaff) {
          this.patientService.patientAttachmentRemove(this.id, attachmentId).subscribe(fnResponse, fnError);
        } else {
          this.patientService.clinicUserPatientAttachmentRemove(this.currentClinicId,this.id, attachmentId).subscribe(fnResponse, fnError);
        }
      }
    })
    e.preventDefault();
  }


  getPlaceholder(): string {
    if (!this.hasFile) return "Enviar arquivo";
    else return "Substituir arquivo"
  }

  downloadFile(url: string, event: Event) {
    window.open(url, '_blank');
    event.preventDefault();
  }
}
