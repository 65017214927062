import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ClinicsService} from "../../../../core/services/clinics.service";
import {Options} from "../../select-input/models/Options";
import {FormBuilder, Validators} from "@angular/forms";
import {PatientModel} from "../../../../core/models/patient.model";
import {ClinicModel} from "../../../../core/models/clinic.model";
import {AccountClinicService} from "../../../../core/services/account-clinic.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-associate-user-modal',
  templateUrl: './associate-user-modal.component.html',
  styleUrls: ['./associate-user-modal.component.scss']
})
export class AssociateUserModalComponent implements OnInit {

  loading: boolean = false;

  clinicId!: number;
  clinicOptions: Options[] = [];

  formGroup = this.fb.group({
    clinic_id: [null, [Validators.required]],
    patient_id: [null, [Validators.required]],
  });

  @Input() patient!: PatientModel;
  @Input() clinic!: ClinicModel;

  constructor(public activeModal: NgbActiveModal, private clinicsService: ClinicsService, private fb: FormBuilder,
              private accountClinicService: AccountClinicService, private toast: ToastrService) { }

  ngOnInit(): void {
    this.loading = true;
    this.formGroup.get('patient_id')?.setValue(this.patient.id)
    if (this.clinic) {
      this.formGroup.get('clinic_id')?.setValue(this.clinic.id);
      this.formGroup.get('clinic_id')?.disable();
    }
    this.fetchClinics();
  }

  fetchClinics() {
    this.clinicsService.getAll().subscribe(response => {
      this.clinicOptions = response.map(item => {
        return {value: item.id?.toString(), label: item.name} as Options
      });
      this.loading = false;
    })
  }

  connectUser() {
    this.loading = true;
    let clinicId = this.formGroup.get('clinic_id')?.value;
    this.accountClinicService.connect(clinicId, this.patient.id!).subscribe(response => {
      this.toast.success('Usuário conectado com sucesso', 'Sucesso');
      this.activeModal.close(true)
    })
  }
}
