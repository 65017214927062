import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientTagsListComponent } from './patient-tags-list/patient-tags-list.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { PatientTagFormComponent } from './patient-tag-form/patient-tag-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    PatientTagsListComponent,
    PatientTagFormComponent
  ],
  imports: [
    CommonModule,
    RenderersModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class PatientTagsModule { }
