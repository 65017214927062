import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { ClinicUsersListComponent } from './clinic-users-list/clinic-users-list.component';
import { ClinicUserFormComponent } from './clinic-user-form/clinic-user-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    ClinicUsersListComponent,
    ClinicUserFormComponent
  ],
  imports: [
    CommonModule,
    RenderersModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class ClinicUsersModule { }
