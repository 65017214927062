<div class="modal-wrapper">
  <h1>Recortar imagem</h1>

  <image-cropper
    [imageURL]="imageUrl"
    [maintainAspectRatio]="isSelfie"
    [aspectRatio]="isSelfie ? 3/4 : 1"
    [canvasRotation]="canvasRotation"
    [transform]="transform"></image-cropper>

  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <div class="d-flex">
          <button class="btn w-auto" (click)="rotateAllImage(-1)">
            <i class="icon icon-rotate-left"></i>
          </button>
          <input type="range" class="w-100" [min]="-90" [max]="90" [(ngModel)]="rotation" (input)="updateRotation($event)">
          <button class="btn w-auto" (click)="rotateAllImage(+1)">
            <i class="icon icon-rotate-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="button-control" *ngIf="!loading else loadingTemplate">
    <button class="btn btn-outline-primary" (click)="closeModal()">Cancelar</button>
    <button class="btn btn-primary" (click)="cropAndCreateNew()" *ngIf="!isSelfie">Criar nova</button>
    <button class="btn btn-primary" (click)="cropAndOverwrite()">Substituir</button>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
