import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Question} from "../../../../core/models/question.model";
import {ImageCropperComponent, ImageTransform} from "ngx-image-cropper";
import {QuizService} from "../../../../core/services/quiz.service";

@Component({
  selector: 'app-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  styleUrls: ['./image-cropper-modal.component.scss']
})
export class ImageCropperModalComponent implements OnInit {
  @ViewChild(ImageCropperComponent) imageCropperComponent!: ImageCropperComponent;

  polysomnographyId!: number;
  imageUrl!: string;
  question!: Question;
  transform: ImageTransform = {rotate: 0};
  rotation = 0;
  canvasRotation = 0;

  isSelfie = false;

  loading = false;

  constructor(private activeModal: NgbActiveModal, private quizService: QuizService) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close(null);
  }

  updateRotation(ev: any) {
    console.log(ev.target.value);
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    }
  }

  rotateAllImage(event: any) {
    this.canvasRotation += event;
  }

  async crop() {
    let response = await this.imageCropperComponent.crop('blob');
    if (response?.blob) {
      return response.blob;
    }
    return null;
  }

  cropAndCreateNew() {
    this.loading = true;
    this.imageCropperComponent.crop('blob')?.then(res => {
      if (res.blob) {
        let file = new File([res.blob], 'cropped');
        this.quizService.saveImage(this.polysomnographyId, this.question.question_id, file).subscribe({
          next: response => {
            this.activeModal.close(response.answer);
            this.loading = false;
          }, error: error => {
            console.error(error);
            this.loading = false;
          }
        });
      }
    }, _ => {
      this.loading = false;
    });
  }

  cropAndOverwrite() {
    this.loading = true;
    this.imageCropperComponent.crop('blob')?.then(res => {
      if (res.blob) {
        let currentPath = new URL(this.imageUrl).pathname;
        let file = new File([res.blob], 'cropped');
        this.quizService.saveImage(this.polysomnographyId, this.question.question_id, file, currentPath).subscribe({
          next: response => {
            this.activeModal.close(response.answer);
            this.loading = false;
          }, error: error => {
            console.error(error);
            this.loading = false;
          }
        });
      }
    }, _ => {
      this.loading = false;
    });
  }
}
