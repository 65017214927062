<div class="page-info">
  <div class="page-info__content">
    <h1 class="page-info__content--title">{{title}}</h1>
    <h2 class="page-info__content--subtitle" *ngIf="subTitle">{{subTitle}}</h2>
    <ng-container [ngTemplateOutlet]="subTitleTemplate" *ngIf="subTitleTemplate"></ng-container>
  </div>

  <div class="page-info__clinic" *ngIf="!isSuperUserOrStaff">
    Clínica:
    <div class="d-flex modal-box-support">
      <button class="btn btn-search" (click)="openClinicFilter()">
        {{labelClinic}}
      </button>

      <div class="modal-box-wrapper" *ngIf="clinicFilterIsOpened" (click)="closeClinicFilter()"></div>
      <div class="modal-box" *ngIf="clinicFilterIsOpened">
        <div class="close-button" (click)="closeClinicFilter()">
          <span class="icon icon-Icon ionic-ios-close"></span>
        </div>

        <ul class="modal-box__search">
          <ng-container *ngFor="let clinic of clinicList">
            <li (click)="toggleClinic(clinic)">
              {{clinic.label}}
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>
