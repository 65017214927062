<div class="main">
  <div class="content">
    <img class="logo" src="../../../assets/chegaderonco-color.svg" alt="Chega de Ronco">
    <span class="spinner-border" *ngIf="fetching"></span>
    <div class="form" *ngIf="!fetching">
      <label>Selecione um paciente</label>
      <app-select-input *ngIf="patientList.length > 0" id="patient_id" label="" [formGroup]="formGroup" [items]="patientList"
                        [hideSelected]="true" [addTag]="false" [multiple]="false" [clearable]="false"
                        [searchable]="true">
      </app-select-input>
      <label class="pt-4">Telefone</label>
      <phone-number-input [formGroup]="formGroup"></phone-number-input>
      <label class="mb-0 pt-4">Link do vídeo</label>
      <basic-input id="video_url" [formGroup]="formGroup"></basic-input>
      <button class="button-style" (click)="submitHandler()" [disabled]="submitting">SALVAR</button>
    </div>
  </div>
</div>
