<div class="row p-0 m-0 header">
  <div class="col-12">
    <app-header title="Paciente" subTitle="Novo Paciente"></app-header>
  </div>
</div>
<div class="row p-0 m-0 content">
  <div class="col-12">
    <div *ngIf="fetchingData else loadingTemplate" [formGroup]="formGroup">
      <div class="row separator">
        <div class="col card">
          <div class="row">
            <div class="col-12 p-0 m-0">
              <phone-number-input [formGroup]="formGroup" label="Telefone" (change)="searchAccount()"
                                  formControlName="phone_number"></phone-number-input>
            </div>
          </div>
        </div>
      </div>
      <div class="row separator">
        <div class="col card">
          <div class="row">
            <div class="px-2 col-12">
              <h3>Dados do Paciente</h3>
            </div>

            <div class="col-12 col-lg-3">
              <app-image-input id="profile_image" [disabled]="formGroup.disabled"
                (onImageChange)="onImageSelect($event)" >
              </app-image-input>
            </div>

            <div class="col p-0">
              <div class="row p-0 m-0 px-2">
                <div class="col-sm-8 p-0 m-0 pl-1 mb-3 pr-sm-2">
                  <app-basic-input id="name" controlName="name" [formGroup]="formGroup" label="Nome completo">
                  </app-basic-input>
                </div>

                <div class="col-sm-4 p-0 m-0 pl-1 mb-3 pr-sm-2">
                  <app-basic-input id="document_number" controlName="document_number" [formGroup]="formGroup"
                                   label="CPF"></app-basic-input>
                </div>

                <div class="col-sm-6 p-0 m-0 mb-3 pr-sm-3">
                  <app-basic-input id="display_name" controlName="display_name" [formGroup]="formGroup"
                    label="Nome de tratamento"></app-basic-input>
                </div>

                <div class="col-sm-3 p-0 m-0 mb-3 px-sm-2">
                  <label class="field__title">Gênero</label>

                  <div class="radio-option">
                    <label for="gender_male"><input type="radio" formControlName="gender" value="MALE"
                        id="gender_male" />
                      Masculino</label>
                  </div>

                  <div class="radio-option">
                    <label for="gender_female"><input type="radio" formControlName="gender" value="FEMALE"
                        id="gender_female" />
                      Feminino </label>
                  </div>
                </div>

                <div class="col-sm-3 p-0 m-0 mb-3">
                  <app-basic-input type="date" id="birthdate" [formGroup]="formGroup" controlName="birthdate"
                    label="Data de nascimento">
                  </app-basic-input>
                </div>

                <div class="col-sm-6 p-0 m-0 pl-1 mb-3 pr-sm-2">
                  <app-basic-input id="email" controlName="email" [formGroup]="formGroup"
                    label="Email"></app-basic-input>
                </div>

                <div class="col-sm-3 p-0 m-0 pl-1 mb-3 pr-sm-2">
                  <app-select-input id="marital_status" label="Estado civil" [formGroup]="formGroup"
                    [items]="maritalStatusList" [searchable]="false" [clearable]="false">
                  </app-select-input>
                </div>

                <div class="col-sm-3 p-0 m-0 pl-1 mb-3">
                  <app-select-input id="profession" label="Profissão" [formGroup]="formGroup" [items]="professionList"
                    [searchable]="true" [clearable]="false" addTagText="Adicionar novo"
                    [addTag]="true"></app-select-input>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row separator" *ngIf="isSuperUserOrStaff">
        <div class="col card">
          <div class="row">
            <div class="px-2 col-12">
              <h3>Clínicas</h3>
            </div>

            <div class="px-2 row col-12 p-0 m-0 radio-type mb-3">
              <div class="radio-option mr-3">
                <label for="cnpj"><input type="radio" formControlName="type" value="COMPANY"
                  (click)="onSubtypeSelected('COMPANY')" id="cnpj">CNPJ</label>
              </div>

              <div class="radio-option">
                <label for="cpf"><input type="radio" formControlName="type" value="PERSONAL"
                  id="cpf" (click)="onSubtypeSelected('PERSONAL')">CPF</label>
              </div>
            </div>

            <div class="px-2 col-9 mb-3">
              <div class="search_clinic">
                <app-basic-input id="clinic" [formGroup]="formGroup" controlName="clinic"
                placeholder="Procurar por CNPJ/CPF"
                [maskOptions]="{mask: maskSearch}"></app-basic-input>
              </div>
            </div>

            <div class="col-3 d-flex align-items-center">
              <button class="btn btn-link"
              (click)="setClinic()"
              [disabled]="formGroup.disabled">Adicionar novo</button>
            </div>

            <div class="col-9 list">
              <p *ngFor="let item of clinicsList">
                {{item.label}}
                <button (click)="removeClinic(item)"
                [disabled]="formGroup.disabled"
                class="remove-button">Remover</button>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row separator">
        <div class="col card">
          <div class="row">
            <div class="px-2 col-12">
              <h3>Tags</h3>
            </div>
            <div class="px-2 col-12">
              <app-select-input id="tags" [formGroup]="formGroup" [items]="patientTagsList" [hideSelected]="true"
                [addTag]="false" [multiple]="true" [clearable]="false" [searchable]="false"></app-select-input>
            </div>
          </div>
        </div>
      </div>

      <div class="row separator" formArrayName="phone_numbers">
        <div class="col card">
          <div class="row">
            <div class="px-2 col-12">
              <h3>Telefone</h3>
            </div>

            <ng-container *ngFor="let phoneNumber of phoneNumbersForms.controls; index as i">
              <div class="row col-12 px-2 m-0 " [class]="{'d-none': !phoneNumberIsActive(i)}">
                <div class="col-6 pr-sm-2 p-0 m-0 mb-3">
                  <phone-number-input [formGroup]="getPhoneNumberForm(i)"></phone-number-input>
                </div>

                <div class="col-4 p-0 m-0 mb-3" [formGroup]="getPhoneNumberForm(i)">
                  <app-select-input id="type" [formGroup]="getPhoneNumberForm(i)" [items]="phoneTypeList"
                    [searchable]="false" [clearable]="false"></app-select-input>

                </div>

                <div class="col-2">
                  <button (click)="removePhoneNumber(i)" [disabled]="formGroup.disabled"
                    class="remove-button">Remover</button>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="row">
            <div class="row">
              <div class="col-12 d-flex align-items-center">
                <button class="btn btn-link" [disabled]="formGroup.disabled" (click)="addPhoneNumber()">Adicionar
                  novo</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row separator">
        <div class="col card">
          <div class="row">
            <div class="px-2 col-12">
              <h3>Endereço</h3>
            </div>
            <div class="p-0 m-0 mb-3 px-sm-2 col-2 zipcode">
              <app-basic-input id="zipcode" controlName="zipcode" label="CEP" [formGroup]="addressFormGroup"
                (focusout)="handleZipCodeChange($event)" [maskOptions]="{mask: '00000-000'}">
              </app-basic-input>
              <span *ngIf="searchZipcode" class="spinner-border"></span>
            </div>

            <div class="col-8 p-0 m-0 mb-3 pr-sm-2">
              <app-basic-input id="address" [formGroup]="addressFormGroup" controlName="address" label="Endereço">
              </app-basic-input>
            </div>

            <div class="col-2 p-0 m-0 mb-3 pr-sm-2">
              <app-basic-input id="number" [formGroup]="addressFormGroup" controlName="number" label="Número">
              </app-basic-input>
            </div>

            <div class="col-3 p-0 m-0 mb-3 px-sm-2">
              <app-basic-input id="complement" [formGroup]="addressFormGroup" controlName="complement"
                label="Complemento">
              </app-basic-input>
            </div>

            <div class="col-3 p-0 m-0 mb-3 pr-sm-2">
              <app-basic-input id="neighborhood" [formGroup]="addressFormGroup" controlName="neighborhood"
                label="Bairro">
              </app-basic-input>
            </div>

            <div class="col-3 p-0 m-0 mb-3 pr-sm-2">
              <app-basic-input id="city" [formGroup]="addressFormGroup" controlName="city"
                label="Cidade"></app-basic-input>
            </div>

            <div class="col-3 p-0 m-0 mb-3 pr-sm-2">
              <app-basic-input id="state" [formGroup]="addressFormGroup" controlName="state" label="Estado">
              </app-basic-input>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row justify-content-end mt-5 mb-5">
      <div class="col-12 col-md-3 mb-3">
        <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
          Cancelar
        </button>
      </div>

      <div class="col-12 col-md-3 mb-3">
        <button (click)="submitHandler()" [disabled]="submitting || !canSave" class="btn btn-primary btn-lg"
          type="button">
          {{submitButton}}
          <span class="spinner-border" *ngIf="submitting"></span>
        </button>
      </div>
    </div>

    <ng-template #loadingTemplate>
      <div class="col card full-loading text-center">
        <div class="spinner-border spinner-border-xl" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-template>

    <div class="backdropLoading" *ngIf="loadingAccount">
      <div class="spinner-border spinner-border-xl" role="status">
        <span class="sr-only">Carregando...</span>
      </div>
    </div>
  </div>
</div>
