<div *ngIf="!loadingPage else loadingTemplate">
  <div class="row mb-5">
    <div class="col card" [formGroup]="formGroup">
      <div class="row px-0">
        <div class="col px-0">
          <app-basic-input id="serial_number" label="Número de série" [formGroup]="formGroup"
            controlName="serial_number"></app-basic-input>
        </div>
        <div class="col px-0 pl-4">
          <app-basic-input id="model" label="Modelo" [formGroup]="formGroup" controlName="model"></app-basic-input>
        </div>

        <div class="col-4 px-0 pl-4">
          <div class="px-2 col-12" *ngIf="isSuperUserOrStaff">
            <app-select-input id="clinic_id" label="Clínica" [formGroup]="formGroup" [items]="clinicList" [hideSelected]="true"
              [addTag]="false" [multiple]="false" [clearable]="false" [searchable]="false">
            </app-select-input>
          </div>
        </div>

        <div class="col-12 checkbox-option px-0 mt-4">
          <label for="is_damaged">
            <input class="mr-2" formControlName="is_damaged" id="is_damaged" type="checkbox">
            Oxímetro danificado
          </label>
        </div>

        <div class="col-12 p-0 mt-4">
          <label class="field__title" for="notes">Observações</label>
          <textarea type="text" id="notes" rows="3" formControlName="notes"></textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-end mt-5 mb-5">

    <div class="col-12 col-md-2 mb-3">
      <button (click)="historic()" *ngIf="button" [disabled]="submitting" class="btn btn-outline-primary" type="button">
        Histórico
      </button>
    </div>

    <div class="col-12 col-md-2 mb-3">
      <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
        Cancelar
      </button>
    </div>

    <div class="col-12 col-md-2 mb-3">
      <button (click)="submitHandler()" [disabled]="submitting || !canSave" class="btn btn-primary btn-lg"
        type="button">
        {{submitButton}}
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>

</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
