import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { OptionModel } from '../../models/option';

@Component({
  selector: 'checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss']
})
export class CheckboxInputComponent implements OnInit {

  @Input() id!: string;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() optionList!: OptionModel[];
  @Input() formGroup!: FormGroup;
  @Input() columns: boolean = false;
  @Input() disabled!: boolean;
  @Input() onSelect!: Function;

  formArray!: FormArray;

  constructor() { }

  ngOnInit(): void {
    this.formArray = <FormArray>this.formGroup.controls[this.id];
  }

  onChange(option: OptionModel, checboxIndex: number) {
    this.formGroup.controls[this.id].markAsTouched();

    const formArrayIndex = this.formArray.value!.findIndex((i: any) => i == option.name);

    if (!this.optionList[checboxIndex].checked) {
      this.optionList[checboxIndex].checked = true;
      this.formArray.push(new FormControl(option.name));
    } else {
      this.optionList[checboxIndex].checked = false;
      this.formArray.removeAt(formArrayIndex)
    }

    this.formGroup.controls[this.id] = this.formArray;
  }
}
