<div class="field" [formGroup]="formGroup">
    <label class="field__title" [for]="id">{{label ? label : ""}}</label>

    <ng-select [attr.list]="'list-' + id" [formControlName]="id"
        [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '', 'custom']"
        (change)="onValueChangeHandler()" [clearable]="!hideClear" [addTag]="showAddItem != false ? true : false"
        addTagText="Adicionar novo" notFoundText="Não encontrado">
        <ng-option *ngFor="let option of optionList" [value]="option.name">{{option.label}}
        </ng-option>
    </ng-select>

    <form-error [id]="id" [formGroup]="formGroup"></form-error>
</div>