<div class="row separator">
  <div class="col-12 card">
    <div class="row px-0">
      <div class="col-12 p-0 m-0">
        <div class="row table__header">

          <div *ngFor="let header of examsPerformedHeader" [style]="{'min-width': header.widthWithPercentage}"
            [ngClass]="header.name != 'started_at' && header.name != 'ended_at'  ? 'd-flex justify-content-center' : ''">
            <label>{{ header.label }}</label>
          </div>

        </div>

        <div class="row table__body" *ngFor="let row of examsPerformedData" (click)="openReportFile(row)">
          <div *ngFor="let colum of examsPerformedHeader" [style]="{'min-width': colum.widthWithPercentage}"
            [ngClass]="colum.name != 'started_at' && colum.name != 'ended_at'  ? 'd-flex justify-content-center' : ''">
              <span *ngIf="colum.name == 'started_at' || colum.name == 'ended_at'">
                {{ getDate(row, colum.name) }}
              </span>
  
              <span *ngIf="colum.name == 'birthdate'">
                {{ getAge(row) }}
              </span>
  
              <span *ngIf="colum.name == 'exam_duration'">
                {{ getDuration(row) }}
              </span>
  
              <span *ngIf="colum.name == 'polysomnography__imc'">
                {{ getKeyByValue(row, colum.name) }}
              </span>
  
              <span *ngIf="colum.name ==  'snoring'">
                {{ getKeyByValue(row, colum.name) }}%
              </span>
  
              <span *ngIf="colum.name == 'gender'" class="align-items-center">
                <i [class]="getGender(row)" ></i>
              </span>
  
              <span *ngIf="colum.name == 'conditions'" class="d-flex">
                <div *ngFor="let icon of getConditions(row, colum.name) | keyvalue : originalOrder">
                  <ng-template #tipContent><b>{{icon.value}}</b></ng-template>
                  <i class="{{icon.key}}" [ngbTooltip]="tipContent" placement="top" container="body"
                    tooltipClass="tooltipClass"></i>
                </div>
              </span>
  
              <span *ngIf="colum.name == 'spo2'" [style]="getBackground(row, colum.name)">
                {{ getSpo2(row) }}
              </span>
  
              <span *ngIf="colum.name == 'ido'" [style]="getBackground(row, colum.name)">
                {{ getKeyByValue(row, colum.name) }}
              </span>



            <span *ngIf="colum.name == 'report'">
              <!-- <button *ngIf="row.snoring_filename" class="attachment" (click)="downloadAttachment(row)"><span>
                  <i-bs name="paperclip" width="2rem" height="2rem"></i-bs>
                </span></button> -->
            </span>
          </div>
        </div>

        <div *ngIf="loading" class="loading">
          <div class="col full-loading text-center">
            <div class="spinner-border spinner-border-xl" role="status">
              <span class="sr-only">Carregando...</span>
            </div>
          </div>
        </div>


        <div *ngIf="error" class="error">
          {{errorMessage}}
        </div>

        <div *ngIf="loading == false && (!examsPerformedData || examsPerformedData!.length == 0) && !this.error"
          class="message">
          Nenhum exame encontrado
        </div>

      </div>
    </div>
  </div>
</div>
