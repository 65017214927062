<div class="background" *ngIf="currentQuestion && !isLoading else loadingTemplate">
  <div class="main" *ngIf="finished">
    <div class="header">
      <p class="screen-def"></p>
      <p class="title-question">Agradecemos sua resposta</p>
      <p class="subtitle">Entraremos em contato em breve</p>
    </div>
  </div>

  <div class="main" *ngIf="!finished">
    <div class="header">
      <p class="screen-def" *ngIf="title">{{ title }}</p>

      <p class="title-question" *ngIf="currentQuestion.question && currentQuestion.question != ' '">{{ currentQuestion.question }}</p>

      <p class="subtitle" *ngIf="currentQuestion.hint && currentQuestion.type != 'IMAGE'" [innerHTML]="currentQuestion.hint.replace('\\n', '<br>')"></p>
    </div>

    <ng-container *ngIf="currentQuestion.type == 'VIDEO'">
      <div class="video">
        <video [src]="currentQuestion.data" #video (click)="toggleVideo(video)" [muted]="true" autoplay playsinline></video>

        <div class="video-muted" *ngIf="video.muted" (click)="toggleVideo(video)">
          <span>Clique aqui para<br>ativar o som</span>
        </div>

        <div class="video-controls" *ngIf="!video.muted">
          <i class="icon icon-play" *ngIf="video.paused || video.ended" (click)="toggleVideo(video)"></i>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="currentQuestion.type == 'RADIO'">
      <form class="form-style">
        <ng-container *ngFor="let option of currentQuestion.data; let index=index">
          <label>
            <input type="radio" name="list-option" [(ngModel)]="currentQuestion.answer" [value]="index.toString()" *ngIf="currentQuestion.variables == 'quiz'">
            <input type="radio" name="list-option" [(ngModel)]="currentQuestion.answer" [value]="option.label" *ngIf="currentQuestion.variables != 'quiz'">
            {{ option.label }}
          </label>
        </ng-container>
      </form>
    </ng-container>

    <ng-container *ngIf="currentQuestion.type == 'CHECKBOX'">
      <form class="form-style-checkbox">
        <ng-container *ngFor="let option of currentQuestion.data">
          <label>
            {{ option.label }}
            <input type="checkbox" [name]="[]" (click)="toggleCheckbox(option.label)" [checked]="(currentQuestion.answer ?? []).includes(option.label)">
          </label>
        </ng-container>
      </form>
    </ng-container>

    <ng-container *ngIf="currentQuestion.type == 'NUMBER'">
      <input placeholder="Digite apenas números" class="input-default" mask="0*" [(ngModel)]="currentQuestion.answer">
    </ng-container>

    <ng-container *ngIf="currentQuestion.type == 'TEXT'">
      <input placeholder="Digite aqui" class="input-default" mask="A*" [(ngModel)]="currentQuestion.answer">
    </ng-container>

    <ng-container *ngIf="currentQuestion.type == 'FLOAT'">
      <input placeholder="Digite apenas números" class="input-default" [(ngModel)]="currentQuestion.answer"
             mask="0,00" [dropSpecialCharacters]="false">
    </ng-container>

    <ng-container *ngIf="currentQuestion.type == 'IMAGE'">
      <div class="type-img-button">
        <input type="file" (change)="addImage($event)" #fileInput accept="image/*" class="hidden" [multiple]="!isSelfie(currentQuestion)">

        <button (click)="fileInput.click()">
          Selecionar ou tirar foto
        </button>

        <span *ngIf="currentQuestion.answer?.length > 0">clique na imagem para removê-la</span>
      </div>

      <div class="img-block">
        <ng-container *ngFor="let image of currentQuestion.answer">
          <img [src]="readImage(image)" alt="" (click)="removeImage(image)">
        </ng-container>
      </div>
    </ng-container>

    <div class="button-style box-buttons">
      <button (click)="previousQuestion()">voltar</button>
      <button (click)="nextQuestion()">
        <span *ngIf="currentQuestion == questions[questions.length - 1]">finalizar</span>
        <span *ngIf="currentQuestion != questions[questions.length - 1]">próxima</span>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="background col full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Carregando...</span>
    </div>
  </div>
</ng-template>
