<div class="list-wrapper">
  <div class="card">
    <div class="row p-0 m-0" *ngIf="listTemplate.showSearch">
      <div class="col col-md p-0 m-0 py-3 py-md-0 search__searchbar">
        <input type="text" #searchbar [placeholder]="listTemplate.searchBarPlaceholder ?? 'Busca'" />
      </div>
      <div class="col-2 p-0 m-0 search__advanced" *ngIf="listTemplate.showAdvancedSearch">
        <button class="btn btn-advanced btn-lg" (click)="searchAdvanced()">
          Busca avançada
          <span class="icon-Icon-ionic-ios-arrow-down " [ngClass]="searchAdvancedBool ? 'up' : 'null'"></span>
        </button>
      </div>

      <div class="col-6 px-0 py-3 py-md-0 pl-2 px-md-2" [ngClass]="listTemplate.searchBarPlaceholder ? 'col-md-3' : ''"
        *ngIf="listTemplate.searchBarStatus">
        <div class="d-flex modal-box-support" *ngIf="statusList">
          <button class="btn btn-search" (click)="openStatusFilter()">
            {{ labelStatus }}
          </button>

          <div class="modal-box-wrapper" *ngIf="statusFilterIsOpened" (click)="closeStatusFilter()"></div>
          <div class="modal-box" *ngIf="statusFilterIsOpened">
            <div class="close-button" (click)="closeStatusFilter()">
              <span class="icon icon-Icon ionic-ios-close"></span>
            </div>

            <ul class="modal-box__search">
              <li class="mb-1" (click)="toggleAllStatus()">Selecionar todos</li>
              <ng-container *ngFor="let status of statusList">
                <li (click)="toggleStatus(status)">
                  <input type="checkbox" [checked]="statusIsSelected(status)">
                  {{status.name}}
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-3 px-0 py-3 p-md-0 search_date" *ngIf="listTemplate.searchBarDate">
        <input type="date" #date (change)="getSearchDate()" />
      </div>

      <div class="col-12 p-0 py-3 p-md-0 search_date" [ngClass]="listTemplate.searchBarPlaceholder ? 'col-md-3' : ''"
        *ngIf="listTemplate.searchBarDateRange">
        <div class="row p-0 m-0">
          <input type="date" class="col-5" #dateStart (change)="getSearchDateRange()" />
          <span class="col-2 d-flex align-self-center justify-content-center p-0">até</span>
          <input type="date" class="col-5" #dateEnd (change)="getSearchDateRange()" />
        </div>
      </div>

      <div class="col-6 px-0 py-3 py-md-0 pl-2 px-md-2" [ngClass]="listTemplate.searchBarPlaceholder ? 'col-md-3' : ''"
        *ngIf="(listTemplate.onClinicFocusOut && isSuperUserOrStaff)">
        <div class="d-flex modal-box-support" *ngIf="clinicList">
          <button class="btn btn-search" (click)="openClinicFilter()">
            {{ labelClinic }}
          </button>

          <div class="modal-box-wrapper" *ngIf="clinicFilterIsOpened" (click)="closeClinicFilter()"></div>
          <div class="modal-box" *ngIf="clinicFilterIsOpened">
            <div class="close-button" (click)="closeClinicFilter()">
              <span class="icon icon-Icon ionic-ios-close"></span>
            </div>

            <ul class="modal-box__search">
              <li (click)="toggleAllClinics()">Selecionar todos</li>
              <ng-container *ngFor="let clinic of clinicList">
                <li (click)="toggleClinic(clinic)">
                  <input type="checkbox" [checked]="clinicIsSelected(clinic)">
                  {{clinic.name}}
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="row p-0 m-0 info d-flex"
    [ngClass]="listTemplate.newDataText || listTemplate.onClickActive ? 'justify-content-between' : 'justify-content-center'"
    *ngIf="listTemplate.showInfo">
    <div class="info__new" *ngIf="listTemplate.newDataText"
      [ngClass]="listTemplate.count || listTemplate.onClickActive ? '' : 'col-12'">
      <a [routerLink]="listTemplate.newDataRouterLink"
        [ngClass]="listTemplate.newDataRouterLink == undefined ? 'info__disabled' : 'null'">{{listTemplate.newDataText}}</a>
    </div>

    <div class="info__data" *ngIf="!listTemplate.loading && !listTemplate.error && listTemplate.count">
      Total cadastrado {{listTemplate.data ? listTemplate.data.total_count : ""}} -
      Mostrando {{ listTemplate.data!.results!.length }} de {{ listTemplate.data!.count! }}
    </div>

    <!-- <div class="info__export" *ngIf="listTemplate.exportUrl">
      <span (click)="exportCSV()">Exportar CSV</span>
    </div> -->

    <div class="info__is_active mr-1" *ngIf="listTemplate.onClickActive">
      <a (click)="onClickActive()">{{ isActiveText }}</a>
    </div>
  </div>

  <div class="card">
    <div class="data-list-wrapper">
      <div class="list__header" *ngIf="listTemplate.header" [ngClass]="mediaQuery ? '' : 'fit-content'">
        <div *ngFor="let item of listTemplate.header; let columnIndex = index" class="title"
          [style]="{'min-width': getWidth(columnIndex)}">
          <button (click)="onHeaderClick(item.name)">
            {{item['label']}}
            <i *ngIf="item.name == listTemplate.selectedOrdering && !listTemplate.invertedOrdering"
              class="icon-Icon-awesome-caret-down ordering-indicator"></i>

            <i *ngIf="item.name == listTemplate.selectedOrdering && listTemplate.invertedOrdering"
              class="icon-Icon-awesome-caret-down ordering-indicator-reverse"></i>
          </button>
        </div>
      </div>
      <div class="table" [ngClass]="mediaQuery ? '' : 'fit-content'">
        <div class="table__body" *ngIf="listTemplate.data && listTemplate.header">
          <div class="table__data" *ngFor="let row of listTemplate.data!.results; let rowIndex = index"
            (click)="rowClickHandle($event, row['id'])"
            [ngStyle]="listTemplate.clickRowClickHandle || isSuperUserOrStaff ? {cursor: 'pointer'} : {cursor: 'default'}">
            <div *ngFor="let item of listTemplate.header; let columnIndex = index"
              [style]="{'min-width': getWidth(columnIndex)}" class="table__data-column">

              <span
                *ngIf="item['name'] != 'phone_numbers' && item['name'] != 'patient__patientphonenumber__phone_number' && item['name'] !='patientphonenumber' && item['name'] != 'exam_nights' && item['name'] != 'devolution_date' && item['name'] != 'sent_at' && item['name'] != 'status_exam' && item['name'] != 'current_patient__devolution_date' && item['name'] != 'current_patient__sent_at' && item['name'] != 'payment_status' && item['name'] != 'invoice_url' && item['name'] != 'status_oximeter'">
                {{ getKeyByValue(columnIndex, row, item['name']) }}
              </span>

              <span *ngIf="item['name'] == 'phone_numbers' || item['name'] =='patientphonenumber'">
                <span>{{getFirstPhoneNumber(columnIndex, row, item["name"])}}</span>
                <button *ngIf="isPhoneNumberWhatsapp(row, item['name'])!"
                  (click)="whatsappButtonClickHandle(getFirstPhoneNumber(columnIndex, row, item['name'], false)!)"
                  class="whatsapp-button icon icon-Icon-awesome-whatsapp "></button>
              </span>

              <span *ngIf="item['name'] == 'created_at' || item['name'] == 'date_time'">
                {{getDate(columnIndex, row, item['name'])}}
              </span>

              <span
                *ngIf="item['name'] == 'sent_at' || item['name'] == 'devolution_date' || item['name'] == 'current_patient__devolution_date' || item['name'] == 'current_patient__sent_at'">
                {{getDateSentAt(columnIndex, row, item['name'])}}
              </span>

              <span *ngIf="item['name'] == 'status_oximeter'">
                {{getStatusOximeter(row)}}
              </span>

              <span *ngIf="item['name'] == 'status_exam'">
                {{getStatusExam(row)}}
              </span>

              <span *ngIf="item['name'] == 'exam_nights'">
                {{getExamNights(row)}}
              </span>

              <span *ngIf="item['name'] == 'patient__patientphonenumber__phone_number'" class="d-flex">
                {{ getPhoneNumberPatientFormatter(row) }}
                <button (click)="whatsappButtonClickHandle(getPhoneNumberPatient(columnIndex, row, item['name'])!)"
                  class="whatsapp-button icon icon-Icon-awesome-whatsapp "
                  *ngIf="getPhoneNumberPatientFormatter(row) != '-'"></button>
              </span>

              <span *ngIf="item['name'] == 'payment_status'">
                <button *ngIf="!row['is_courtesy']" class="credit-button icon-credit" [style]="getColorPaid(row)" (click)="abrirFaturaAsaas(row)"></button>
                <span *ngIf="row['is_courtesy']" class="icon-gift icon-iconmonstr-gift"></span>
              </span>

              <span *ngIf="item['name'] == 'invoice_url' && row['invoice_url']"
                (click)="toggleShareDropdown($event, row)" class="overflow-visible">
                <div class="dropdown-share">
                  <button class="btn share-button" role="button">
                    <i class="icon icon-share"></i>
                  </button>
                </div>
              </span>

              <span *ngIf="item['name'] == 'invoice_url' && !row['invoice_url']">
                <button *ngIf="row['payment_status'] == 'RESEND'" class="btn btn-outline" (click)="emitDue(row)">Gerar fatura</button>
                <div *ngIf="row['payment_status'] != 'RESEND'" id="hifen">-</div>
              </span>
            </div>
          </div>
        </div>

        <div *ngIf="hasNext && !listTemplate.loading && listTemplate.data && listTemplate.header" (click)="nextPage()">
          <div class="loading-next">
            <ng-container *ngIf="!listTemplate.loadingNext">
              Carregar mais...
            </ng-container>
            <ng-container *ngIf="listTemplate.loadingNext">
              Carregando
            </ng-container>
          </div>
        </div>

        <div *ngIf="listTemplate.loading" class="loading">
          <div class="spinner-border spinner-border-xl" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div *ngIf="listTemplate.error" class="error">
          {{listTemplate.errorMessage}}
        </div>

        <div
          *ngIf="listTemplate.loading == false && (!listTemplate.data || listTemplate.data.results!.length == 0) && !this.listTemplate.error"
          class="message">
          {{this.listTemplate.notFoundMessage}}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="share-options-backdrop" [class]="{'share-options-backdrop--show': shareIsOpened}"
  (click)="toggleShareDropdown($event, null)"></div>

<ul class="share-options" [style.top]="shareDropdownY + 'px'" [style.left]="shareDropdownX + 'px'"
  [style]="{'display': shareIsOpened ? 'block' : 'none'}">
  <li>
    <button class="btn btn-outline" (click)="copyToClipboard()">Copiar link</button>
  </li>
  <li>
    <button class="btn btn-outline" (click)="sendInvoiceUrlViaWhatsapp()">Enviar via whatsapp</button>
  </li>
</ul>
