import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  formGroup: FormGroup = this.fb.group({
    old_password: ['', Validators.required],
    new_password: ['', Validators.compose([
      Validators.required,
      Validators.minLength(6)
    ])],
    new_password_confirm: ['', Validators.compose([
      Validators.required,
      Validators.minLength(6)
    ])]
  });

  id!: number;
  submitting: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private accountsService: AccountsService,
    private sessionManager: SessionManagerService,
    private toast: ToastrService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error['detail']) {
      this.document.getElementById('main-container')?.scroll({ top: 0 });
      this.toast.error(errorResponse.error['detail'], 'Erro');
    } else {
      this.setFormErrors(errorResponse);
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      'old_password', 'new_password', 'new_password_confirm'
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({
          response: errorResponse.error[name]
        });
    });
  }

  submitHandler() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      const passwordData = this.getFormData();
      let new_password = this.formGroup.get('new_password')?.value;
      let passwordConfirm = this.formGroup.get('new_password_confirm')?.value;
      if (new_password == passwordConfirm) {
        if (this.isSuperUserOrStaff) {
          this.systemUserRedefinePassword(passwordData);
          return;
        } else {
          this.clinicUserRedefinePassword(passwordData);
          return;
        }        
      } else {
        this.toast.error("Senhas não coincidem", "Erro");
      }      
    } else {
      this.toast.error('Preencha corretamente os campos obrigatórios', 'Erro');
    }
  }

  getFormData() {
    return {
      old_password: this.formGroup.get('old_password')?.value,
      new_password: this.formGroup.get('new_password')?.value
    };
  }

  async systemUserRedefinePassword(passwordData: any) {
    this.submitting = true;
    let accountId = this.sessionManager.accountData.id;
    try {
      await this.accountsService.systemUserRedefinePassword(accountId!, passwordData).toPromise();
      this.toast.success("Senha alterada com sucesso", "Sucesso");
      this.cancelHandler();
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    }
  }

  async clinicUserRedefinePassword(passwordData: any) {
    this.submitting = true;
    let accountId = this.sessionManager.accountData.id;
    let clinicId = this.currentClinicId;
    try {
      await this.accountsService.clinicUserRedefinePassword(accountId!, clinicId!, passwordData).toPromise();
      this.toast.success("Senha alterada com sucesso", "Sucesso");
      this.cancelHandler();
    } catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    }
  }

  cancelHandler() {
    this.router.navigate([`dashboard/registers/${this.routerLinkUsers}/edit/${this.id}`]);
  }

  get routerLinkUsers() {
    if (!this.isSuperUserOrStaff) {
      return 'clinicUsers';
    }
    return 'systemUsers';
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  get currentClinicId() {
    if (!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent().value;
    }
    return '';
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  }

}

