import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { OximeterModel } from 'src/app/core/models/oximeter.model';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { ClinicsService } from 'src/app/core/services/clinics.service';
import { OximeterService } from 'src/app/core/services/oximeter.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/modals/confirmation-modal/confirmation-modal.component';
import { Options } from 'src/app/shared/components/select-input/models/Options';

@Component({
  selector: 'app-oximeter-form',
  templateUrl: './oximeter-form.component.html',
  styleUrls: ['./oximeter-form.component.scss']
})
export class OximeterFormComponent implements OnInit {
  id!: number;

  formGroup = this.fb.group({
    id: [''],
    serial_number: ['', [Validators.required]],
    model: ['', [Validators.required]],
    is_damaged: [false],
    notes: [''],
    clinic_id: ['']
  });

  existingOximeter!: OximeterModel;

  clinicList!: Options[];
  fetchingClinic: boolean = false;
  fetchingOximeter = false;

  isRegister: boolean = true;

  submitting: boolean = false;
  removeButton?: String = "Desativar";
  submitButton?: String = "Salvar";

  button: boolean = false;

  constructor(private fb: FormBuilder, private clinicsService: ClinicsService, private oximeterService: OximeterService, private router: Router, private activatedRoute: ActivatedRoute, private sessionManager: SessionManagerService, private toast: ToastrService, config: NgbModalConfig, private modalService: NgbModal) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.fetchClinicsList();
    this.activatedRoute.params.subscribe((params: any) => {
      this.id = Number(params['id']);
      if (this.id) {
        this.isRegister = false;
        this.fetchOximeter();
      }
    });
  }

  get canSave() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_oximeter);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_oximeter);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_oximeter);
  }

  get clinicId() {
    if (this.isSuperUserOrStaff) {
      return '';
    }
    return this.sessionManager.getClinicCurrent().value?.toString();
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  get loadingPage(): boolean {
    return this.fetchingClinic || this.fetchingOximeter;
  }

  fetchOximeter() {
    this.fetchingOximeter = true;
    this.oximeterService.oximeterGet(this.clinicId, this.id).subscribe((response) => {
      this.fetchingOximeter = false;
      this.existingOximeter = response;
      this.formGroup.patchValue(response);
      if (this.existingOximeter.history) {
        if (this.existingOximeter.history.length > 0) {
          this.button = true;
        }
      }
    });
  }

  historic() {
    this.router.navigate(['/dashboard/oximeter/historic', this.id]);
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/oximeter']);
  }

  submitHandler() {
    let oximeterData = this.formGroup.getRawValue();
    this.submitting = true;
    if (this.isRegister) {
      if (this.formGroup.valid) {
        this.oximeterRegister(oximeterData);
      }
    } else {
      this.oximeterEdit(oximeterData);
    }
  }

  fetchClinicsList() {
    this.fetchingClinic = true;
    this.clinicsService.getAll().subscribe((response) => {
      this.fetchingClinic = false;
      this.clinicList = response!.map((item: any) => {
        return {
          value: item.id!.toString(),
          label: item.name
        } as Options;
      });
    });
  }

  oximeterRegister(oximeterData: OximeterModel) {
    oximeterData.id = undefined;
    this.oximeterService.oximeterRegister(this.clinicId, oximeterData).subscribe(response => {
      this.toast.success("Oxímetro criado com sucesso", "Sucesso");
      this.cancelHandler();
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  oximeterEdit(oximeterData: OximeterModel) {
    oximeterData.notes = oximeterData.notes ?? '';
    this.oximeterService.oximeterEdit(this.clinicId, oximeterData).subscribe(response => {
      this.toast.success("Oxímetro alterado com sucesso", "Sucesso");
      this.cancelHandler();
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    }
    else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      'serial_number', 'model', 'is_damaged', 'notes'
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if ((this.formGroup.dirty || this.submitting) && this.isRegister) {
      const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.text = "As alterações no formulário não foram salvas e serão descartadas, deseja prosseguir?";
      return modalRef.result
    }
    else return true;
  }

}
