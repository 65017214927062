import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PolysomnographyExamModel } from 'src/app/core/models/polysomnography-exam.model';
import { OximeterService } from 'src/app/core/services/oximeter.service';
import * as moment from 'moment';
import { KeyValue } from '@angular/common';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';

@Component({
  selector: 'app-patient-polysomnography',
  templateUrl: './patient-polysomnography.component.html',
  styleUrls: ['./patient-polysomnography.component.scss']
})
export class PatientPolysomnographyComponent implements OnInit {

  patientId!: number;
  loading: boolean = false;
  error: boolean = false;
  errorMessage!: string;
  notFoundMessage: string = "Nenhuma polissonografia encontrada";

  examsPerformedHeader = [
    { name: 'started_at', label: 'Início', width: '140px', widthWithPercentage: '16%' },
    { name: 'ended_at', label: 'Fim', width: '140px', widthWithPercentage: '16%' },
    { name: 'exam_duration', label: 'Duração', width: '100px', widthWithPercentage: '9%' },
    { name: 'gender', label: 'Gênero', width: '80px', widthWithPercentage: '7%' },
    { name: 'birthdate', label: 'Idade', width: '80px', widthWithPercentage: '7%' },
    { name: 'polysomnography__imc', label: 'IMC', width: '30px', widthWithPercentage: '7%' },
    { name: 'conditions', label: 'Condições', width: '100px', widthWithPercentage: '14%' },
    { name: 'snoring', label: 'Ronco', width: '50px', widthWithPercentage: '6%' },
    { name: 'spo2', label: '↓SpO2', width: '30px', widthWithPercentage: '10%' },
    { name: 'ido', label: 'IDO', width: '30px', widthWithPercentage: '7%' }
  ];

  examsPerformedData: PolysomnographyExamModel[] = [];

  constructor(private activatedRoute: ActivatedRoute, private oximeterService: OximeterService, private sessionManager: SessionManagerService) { }

  ngOnInit(): void {
    this.activatedRoute.parent?.params.subscribe(params => {
      this.patientId = params['id'];
      this.fetchList();
    });
  }

  get currentClinic() {
    if(!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent();
    }
    return '';
  }

  get currentClinicId() {
    if(!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent().value;
    }
    return '';
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  fetchList() {
    this.loading = true;
    const fnResponse = (response: any) => {
      this.examsPerformedData = response.results;
      this.loading = false;
    };

    const fnError = (error: HttpErrorResponse) => {
      this.loading = false;
      this.error = true;
      this.errorMessage = error.error['detail'];
    };

    if(this.isSuperUserOrStaff) {
      this.oximeterService.patientExamsList(this.patientId).subscribe(fnResponse, fnError);
    } else {
      this.oximeterService.clinicUserPatientExamsList(this.currentClinicId, this.patientId).subscribe(fnResponse, fnError);
    }
  }

  getKeyByValue(object: any, name: string) {
    return object[name];
  }

  getDate(object: any, name: string) {
    return moment(object[name]).format('DD/MM/YYYY HH:mm');
  }

  getAge(object: any) {
    return Math.floor(moment(new Date()).diff(moment(object.patient.birthdate), 'years'));
  }

  getDuration(object: any) {
    let hours = Math.floor(object.exam_duration / 60);
    let minutes = object.exam_duration % 60;
    if (minutes.toString().length == 1) return `${hours}h0${minutes}min`;
    else return `${hours}h${minutes}min`;
  }

  getSpo2(object: any) {
    let minutes = object.spo2 ? object.spo2+"min" : '-';
    let percentage = object.percentage_spo2 ?? '-';
    return `${minutes} (${percentage}%)`;
  }

  getGender(object: any) {
    if (object.patient.gender == 'female' || object.patient.gender == 'FEMALE') {
      return 'icon-female';
    } else if(object.patient.gender == 'male' || object.patient.gender == 'MALE') {
      return 'icon-male'
    } else return '';
  }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  getConditions(object: any, name: string) {
    let conditions = new Map<string, string>();

    if (object[name]) {
      object[name].forEach((item: string) => {
        if (item == 'ALCOHOLIC_BEVERAGE') {
          conditions.set('icon-Icon-awesome-cocktail', 'Bebida alcoólica');
        } else if (item == 'STUFFY_NOSE') {
          conditions.set('icon-Grupo-409', 'Nariz entupido');
        } else if (item == 'SEDATIVES') {
          conditions.set('icon-pill', 'Sedativos');
        } else if (item == 'CPAP') {
          conditions.set('icon-cpap', 'CPAP');
        } else if (item == 'INTRAORAL_SNORING_DEVICE') {
          conditions.set('icon-intraoral_snoring_device', 'Aparelho de Ronco Intraoral');
        }
      });
    }
    return conditions;
  }

  getBackground(object: any, name: string) {
    let style = {
      'background-color': '#000',
      'border-radius': '4px',
      'padding': '0 4px'
    }
    if (name == 'spo2') {
      if (object.percentage_spo2 == 0) {
        style['background-color'] = '#88f988';
      } else if (object.percentage_spo2 > 0 && object.percentage_spo2 < 11) {
        style['background-color'] = '#f5f988';
      } else {
        style['background-color'] = '#f6a9a8';
      }
    } else {
      if (object[name] >= 0 && object[name] < 10) {
        style['background-color'] = '#88f988';
      } else if (object[name] > 10 && object[name] < 20) {
        style['background-color'] = '#f5f988';
      } else if (object[name] > 20 && object[name] < 40) {
        style['background-color'] = '#ffd0a8';
      } else {
        style['background-color'] = '#f6a9a8';
      }
    }
    return style;
  }

  downloadAttachment(object: PolysomnographyExamModel) {
    if (object.snoring_file) {
      window.open(object.snoring_file, '_blank');
    } else return;
  }

  openReportFile(exam: PolysomnographyExamModel) {
    window.open(exam.report_file, '_blank');
  }

}
