import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ClinicModel } from 'src/app/core/models/clinic.model';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { PhoneModel } from 'src/app/core/models/phone.model';
import { ProfessionModel } from 'src/app/core/models/profession.model';
import { BrapiService } from 'src/app/core/services/brapi.service';
import { ClinicsService } from 'src/app/core/services/clinics.service';
import { ProfessionalsService } from 'src/app/core/services/professionals.service';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/modals/confirmation-modal/confirmation-modal.component';
import { WalletIdModalComponent } from 'src/app/shared/components/modals/wallet-id-modal/wallet-id-modal.component';
import { Options } from 'src/app/shared/components/select-input/models/Options';
import { FieldValidator } from 'src/app/shared/renderers/utils/field-validator/field-validator';

@Component({
  selector: 'app-clinic-form',
  templateUrl: './clinic-form.component.html',
  styleUrls: ['./clinic-form.component.scss']
})
export class ClinicFormComponent implements OnInit {

  addressFormGroup = this.fb.group({
    zipcode: [],
    address: [],
    number: [],
    complement: [],
    neighborhood: [],
    city: [],
    state: []
  });

  searchZipcode: boolean = false;

  formGroup = this.fb.group({
    id: [],
    name: [null],
    display_name: [],
    type: ['COMPANY'],
    email: [],
    document_number: ['', [FieldValidator.document3Validator()]],
    cro: [],
    tags: [],
    phone_numbers: this.fb.array([]),
    specialties: [],
    address: this.addressFormGroup,
    professional: [],
    professionals: [],
    amount_charged_by_clinic: [],
    discount: [],
    amount_charged_to_clinic: [],
    aliquot: [],
    exams_due_days: ['', [Validators.min(0)]],
    courtesy_exams: ['', [Validators.min(0)]],
    wallet_id: [],
    maximum_nights_per_exam: ['', [Validators.min(1)]],
    split: [false],
    invoice_description: []
  });

  constructor(@Inject(DOCUMENT) private document: Document, private activatedRoute: ActivatedRoute, private fb: FormBuilder,
    private router: Router, config: NgbModalConfig, private modalService: NgbModal, private clinicsService: ClinicsService,
    private registersService: RegistersService, private brApiService: BrapiService, private toast: ToastrService,
    private sessionManager: SessionManagerService, private professionalsService: ProfessionalsService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  imageFile!: File | null;
  changedImage: boolean = false;
  image!: string;

  isRegister: boolean = true;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton: String = "Desativar";
  submitButton: String = "Salvar";
  button: boolean = false;

  loadingProfessional: boolean = false;
  clearSearch: boolean = false;

  phonesList!: PhoneModel[];
  professionalListAll: Options[] = [];
  professionalAll: Options[] = [];
  professionalList: Options[] = [];
  fetchingProfessional: boolean = false;
  clinicTagsList!: Options[];
  fetchingTags: boolean = false;
  specialtiesList!: Options[];
  fetchingSpecialties: boolean = false;

  id!: number;
  existingClinicData!: ClinicModel;
  fetchingClinic: boolean = false;

  labelCpfCnpj: string = "CNPJ";
  labelName: string = "Razão social";
  labelDisplay: string = "Nome fantasia";
  mask: string = "00.000.000/0000-00";


  maskSearch: string = '000.000.000-00';

  phoneTypeList: Options[] = [
    { label: "Whatsapp", value: "WHATSAPP" },
    { label: "Comercial", value: "COMERCIAL" },
    { label: "Residencial", value: "RESIDENTIAL" },
  ];

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params["id"];
      if (this.id) {
        this.isRegister = false;
      }
    });
    this.initializeForm();
  }

  initializeForm() {
    this.fetchProfessional();
    this.fetchClinicTags();
    this.fetchSpecialties();
    this.addPhoneNumber();
    if (this.id) setTimeout(() => this.fetchClinicsExistingData(), 1000);
  }

  get fetchingData(): boolean {
    console.log(this.fetchingProfessional, this.fetchingSpecialties, this.fetchingTags, this.fetchingClinic)
    return this.fetchingProfessional || this.fetchingSpecialties || this.fetchingTags || this.fetchingClinic;
  }

  get canSave() {
    if (this.isRegister) {
      return this.sessionManager.getUserPermission(PermissionConst.add_account);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_account);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_clinic);
  }

  get currentClinic() {
    if (!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent();
    }
    return '';
  }

  get currentClinicId() {
    if (!this.isSuperUserOrStaff) {
      return this.sessionManager.getClinicCurrent().value;
    }
    return '';
  }

  get isSuperUserOrStaff() {
    return this.sessionManager.isSuperUserOrStaff();
  }

  cancelHandler() {
    this.router.navigate(['dashboard/registers/clinics']);
  }

  searchProfessional() {
    this.loadingProfessional = true;
    this.formGroup.get('professional')?.disable();
    let document_number = this.formGroup.get('professional')?.value;
    if (document_number) {
      this.clearSearch = true;
      this.clinicsService.professionalSearchAll(document_number).subscribe(response => {
        if (response?.account) {
          let professionalLabel = `${response.name} - CRO ${response.cro}`;
          this.maskSearch = '';
          this.formGroup.get('professional')?.setValue(professionalLabel);
          this.formGroup.get('professionals')?.setValue(response.id);
          let professional = {
            value: response.id,
            label: professionalLabel
          } as Options;

          let hasProfessional = this.professionalList.find(item => item.value == professional.value);
          if (!hasProfessional) {
            this.professionalAll.push(professional);
            this.professionalListAll.push(professional);
          } else {
            this.formGroup.get('professional')?.setErrors({ response: 'Esse profissional já está conectado com a clínica' });
            this.formGroup.get('professional')?.enable();
          }
        } else {
          this.formGroup.get('professional')?.setErrors({ response: 'Não existe profissional com esse CPF' });
          this.formGroup.get('professional')?.enable();
        }
        this.loadingProfessional = false;
      });
    } else {
      this.formGroup.get('professional')?.setErrors({ response: 'Digite um CPF para pesquisar' });
      this.loadingProfessional = false;
      this.formGroup.get('professional')?.enable();
    }
  }

  clearProfessional() {
    this.maskSearch = '000.000.000-00';
    this.formGroup.get('professionals')?.reset();
    this.formGroup.get('professional')?.enable();
    this.formGroup.get('professional')?.reset();
    this.clearSearch = false;
  }

  setProfessional(professionalId?: any) {
    let professional = this.formGroup.get('professionals')?.value || professionalId || '';
    let professionals = this.professionalListAll.filter(item => item.value == professional);
    if (professional != '') {
      if (professionals[0] != undefined) this.professionalList.push(professionals[0]);
      this.professionalListAll = this.professionalAll.filter((item) => {
        return this.professionalList.find((item2) => item.value == item2.value) ? false : true;
      });
      this.clearProfessional();
    } else {
      this.formGroup.get('professional')?.setErrors({ response: 'Digite um CPF válido para adicionar' });
    }
  }

  removeProfessional(professional: any) {
    let index = this.professionalList.indexOf(professional);
    this.professionalList.splice(index, 1);
  }

  fetchClinicTags() {
    if (this.isSuperUserOrStaff) {
      this.fetchingTags = true;
      this.clinicsService.tagGetAll().subscribe((response) => {
        if (response) this.clinicTagsList = response.map(items => {
          return {
            value: items.id!.toString(),
            label: items.name
          } as Options;
        });
        this.fetchingTags = false;
      });
    } else {
      this.fetchingTags = false;
    }
  }

  fetchSpecialties() {
    this.fetchingSpecialties = true;
    this.registersService.specialtyAll(this.currentClinicId).subscribe(response => {
      this.specialtiesList = response!.map(items => {
        return {
          value: items.id!.toString(),
          label: items.name
        } as Options;
      });
      this.fetchingSpecialties = false;
    });
  }

  fetchProfessional() {
    const fnResponse = (response: any) => {
      this.professionalListAll = response!.map((item: any) => {
        return {
          value: item.id,
          label: `${item.name} - CRO ${item.cro}`
        } as Options;
      });
      this.professionalAll = this.professionalListAll;
      this.fetchingProfessional = false;
    }
    const fnError = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    };

    this.fetchingProfessional = true;
    if (this.isSuperUserOrStaff) {
      this.professionalsService.listAll().subscribe(fnResponse, fnError);
    } else {
      this.professionalsService.clinicUserGetAll(this.currentClinicId).subscribe(fnResponse, fnError);
    }
  }

  fetchClinicsExistingData() {
    const fnResponse = (response: ClinicModel) => {
      this.existingClinicData = response;
      this.phoneNumbersForms.clear();
      response.phone_numbers.forEach(() => this.addPhoneNumber());
      this.onSubtypeSelected(this.existingClinicData.type);
      let professional = this.existingClinicData.professionals;
      professional!.forEach(item => this.setProfessional(item));
      this.existingClinicData.professionals = [];
      this.formGroup.patchValue(this.existingClinicData);
      if (this.existingClinicData.is_active) {
        this.button = true;
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
      } else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
        this.phoneNumbersForms.disable();
      }

      this.image = response.profile_image ?? '';

      if (!this.isSuperUserOrStaff) {
        this.button = false;
        this.formGroup.get('maximum_nights_per_exam')?.disable();
      }

      this.fetchingClinic = false;
    }
    const fnError = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.cancelHandler();
    };

    if (this.isSuperUserOrStaff) {
      this.clinicsService.getOne(this.id).subscribe(fnResponse, fnError);
    } else {
      this.clinicsService.userClinicGetOne(this.currentClinicId, this.id).subscribe(fnResponse, fnError);
    }
  }

  onSubtypeSelected(value?: string) {
    if (value == "COMPANY") {
      this.labelCpfCnpj = "CNPJ";
      this.mask = "00.000.000/0000-00";
      this.labelName = "Razão social";
      this.labelDisplay = "Nome fantasia";
      this.formGroup.get('document_number')?.clearValidators();
      this.formGroup.get('document_number')?.addValidators([FieldValidator.document3Validator()]);
    }
    else {
      this.labelCpfCnpj = "CPF";
      this.mask = "000.000.000-00";
      this.labelName = "Nome completo";
      this.labelDisplay = "Nome de tratamento";
      this.formGroup.get('document_number')?.clearValidators();
      this.formGroup.get('document_number')?.addValidators([FieldValidator.documentValidator()]);

    }

    if (this.formGroup.get("document_number")?.value) {
      this.formGroup.get("document_number")?.reset();
    }
  }

  openWalletModal() {
    const modalRef = this.modalService.open(WalletIdModalComponent, {centered: true});
    modalRef.result.then(() => {});
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.text = "Deseja desativar esta clínica?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.clinicsService.remove(this.id).subscribe(() => {
          this.toast.success('Clínica removida com sucesso', "Sucesso");
          this.cancelHandler();
        }, (errorResponse: HttpErrorResponse) => {
          this.submittingRemove = false;
          this.mapErrorResponse(errorResponse);
        });
      } else {
        this.submittingRemove = false;
      }
    });
  }

  onImageSelect(file: any) {
    this.imageFile = file;
    this.changedImage = true
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();
    (this.phoneNumbersForms?.value as PhoneModel[]).forEach((phone, index) => {
      if (phone.id == null) this.phoneNumbersForms.at(index).get('id')?.setValue(undefined);
    });
    if (this.formGroup.get('tags')?.value == null) {
      this.formGroup.get('tags')?.setValue([]);
    }
    if (this.formGroup.get('specialties')?.value == null) {
      this.formGroup.get('specialties')?.setValue([]);
    }
    let clinicData = this.formGroup.getRawValue() as ClinicModel;
    clinicData.professionals = this.professionalList.map(item => Number(item!.value!));
    clinicData.tags = clinicData.tags?.map(item => { return Number(item) });
    clinicData.specialties = clinicData.specialties?.map(item => { return Number(item) });
    if (this.isRegister) {
      this.clinicRegister(clinicData);
    } else {
      this.clinicEdit(clinicData);
    }
  }

  clinicRegister(clinicData: ClinicModel) {
    const fnResponse = (response: ClinicModel) => {
      if (this.changedImage) {
        if (this.isSuperUserOrStaff) {
          this.clinicsService.uploadClinicImage(this.imageFile!, response.id!).subscribe(() => { }, fnError);
        } else {
          this.clinicsService.uploadClinicImage(this.imageFile!, response.id!, this.currentClinicId).subscribe(() => { }, fnError);
        }
      }
      this.isRegister = false;
      this.submitting = false;
      this.toast.success('Clínica criada com sucesso', "Sucesso");
      this.cancelHandler();
    };
    const fnError = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.toast.error('Verifique se todos os campos obrigatórios foram preenchidos corretamente.', 'Erro');
      this.submitting = false;
    };

    if (this.isSuperUserOrStaff) {
      this.clinicsService.register(clinicData).subscribe(fnResponse, fnError);
    }
  }

  clinicEdit(clinicData: ClinicModel) {
    clinicData.is_active = true;
    if(!this.isSuperUserOrStaff) {
      clinicData.aliquot = undefined;
      clinicData.amount_charged_to_clinic = undefined;
      clinicData.maximum_nights_per_exam = undefined;
      clinicData.split = undefined;
    }
    const fnResponse = (response: ClinicModel) => {
      if (this.changedImage) {
        if (this.isSuperUserOrStaff) {
          this.clinicsService.uploadClinicImage(this.imageFile!, response.id!).subscribe(() => { }, fnError);
        } else {
          this.clinicsService.uploadClinicImage(this.imageFile!, response.id!, this.currentClinicId).subscribe(() => { }, fnError);
        }
      }
      this.submitting = false;
      this.toast.success('Clínica alterado com sucesso', "Sucesso");
      this.cancelHandler();
    };

    const fnError = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.toast.error('Verifique se todos os campos obrigatórios foram preenchidos corretamente.', 'Erro');
      this.submitting = false;
    }
    if (this.isSuperUserOrStaff) {
      this.clinicsService.edit(clinicData).subscribe(fnResponse, fnError);
    } else {
      this.clinicsService.userClinicEdit(this.currentClinicId, clinicData).subscribe(fnResponse, fnError);
    }
  }

  get phoneNumbersForms() {
    return this.formGroup.get('phone_numbers') as FormArray;
  }

  getPhoneNumberForm(index: number) {
    return this.phoneNumbersForms.controls[index] as FormGroup;
  }

  addPhoneNumber() {
    let phoneNumberFormGroup = this.fb.group({
      id: [],
      country_code: ['+55'],
      phone_number: [],
      type: ['WHATSAPP'],
      is_active: [true]
    });
    this.phoneNumbersForms.push(phoneNumberFormGroup);
  }

  removePhoneNumber(at: number) {
    let formGroup = this.getPhoneNumberForm(at);
    if (formGroup.get('id')?.value) {
      formGroup.patchValue({ is_active: false });
    } else {
      this.phoneNumbersForms.removeAt(at);
    }
  }

  phoneNumberIsActive(index: number) {
    return this.getPhoneNumberForm(index).get('is_active')?.value;
  }

  handleZipCodeChange(ev: any) {
    this.searchZipcode = true;
    let zipcode = this.addressFormGroup.get('zipcode')?.value;
    this.addressFormGroup.disable();
    this.brApiService.getAddressByZipCode(zipcode).subscribe(response => {
      this.addressFormGroup.patchValue({
        address: response.street,
        neighborhood: response.neighborhood,
        city: response.city,
        state: response.state
      });
      this.searchZipcode = false;
      this.addressFormGroup.enable();
    }, (error: HttpErrorResponse) => {
      this.addressFormGroup.get('zipcode')?.setErrors({ response: 'Cep não encontrado' });
      this.searchZipcode = false;
      this.addressFormGroup.enable();
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    } else {
      this.setFormErrors(errorResponse);
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "id", "name", "display_name", "type", "email", "document_number", "cro", "tags",
      "phone_numbers", "specialties", "address", "professionals", "amount_charged_by_clinic",
      "amount_charged_to_clinic", "aliquot", "exams_due_days", "wallet_id", "maximum_nights_per_exam",
      "split", "courtesy_exams"
    ];

    let addressErrNames = [
      "zipcode", "address", "number", "complement", "neighborhood", "city", "state"
    ];

    let phoneErrNames = [
      "id", "country_code", "phone_number", "type"
    ];

    errNames.forEach(name => {
      if (errorResponse.error[name]) {
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
      }
    });

    if (errorResponse.error.address) {
      let address = errorResponse.error.address;
      addressErrNames.forEach(name => {
        this.addressFormGroup.get(name)?.setErrors({ response: address[name] });
      });
    }

    if (errorResponse.error.phone_numbers) {
      let phone_numbers: [] = errorResponse.error.phone_numbers;
      Object.keys(phone_numbers).map((index) => {
        let formPhone = this.getPhoneNumberForm(Number(index)) as FormGroup;
        let errorResponsephone_numbers = phone_numbers[Number(index)];
        phoneErrNames.forEach(name => {
          formPhone.get(name)?.setErrors({ response: errorResponsephone_numbers[name] });
        });
      });
    }
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  };

  hasErrors(formControlName: string): boolean {
    return (this.formGroup.get(formControlName)!.errors && this.formGroup.get(formControlName)!.touched) || false;
  }

}
