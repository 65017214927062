<div class="bottom-logo">
    <img class="logo" src="/assets/chegaderonco-color.svg">
</div>
<div class="container-text">
  <span>Baixe o app por uma das lojas abaixo</span>
</div>

<div class="platform-container">
  <a class="row" href="https://play.google.com/store/apps/details?id=br.com.chegaderonco.app">
    <img class="platform-icon" src="/assets/g8.svg">
  </a>

  <a class="row pt-5" href="https://apps.apple.com/br/app/chega-de-ronco/id1672047028">
    <img class="platform-icon" src="/assets/apple-store.svg">
  </a>
</div>

