<div class="field file-uploader">
    <label class="inputLabel" [for]="id">{{label ? label : ""}}</label>

    <button *ngIf="imageUrl" class="remove-button" (click)="removeButtonClickHandle($event)"><i
            class="icon-Icon-ionic-ios-close"></i></button>
    <label for="uploader" class="uploader-wrapper" [ngClass]="disabled? 'uploader-wrapper__disabled' : 'null'">
        <div class="preview-wrapper">
            <i class="icon-Icon-ionic-ios-camera" *ngIf="!imageUrl"></i>
            <img id="preview">
        </div>
        <label>Enviar imagem</label>
    </label>
    <input type="file" id="uploader" accept="image/*" hidden>
</div>