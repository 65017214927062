import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrapiService {

  constructor(private httpClient: HttpClient) { }

  getAddressByZipCode(zipCode: string): Observable<any> {
    return this.httpClient.get<any>(`https://brasilapi.com.br/api/cep/v2/${zipCode}`);
  }
}
