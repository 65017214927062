import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavigationModule } from './navigation/navigation.module';
import { PatientsModule } from './patients/patients.module';
import { RegistersModule } from './registers/registers.module';
import { OximetersModule } from './oximeters/oximeters.module';
import { ExamsModule } from './exams/exams.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    NavigationModule,
    PatientsModule,
    RegistersModule,
    OximetersModule,
    ExamsModule
  ]
})
export class DashboardModule { }
