<div class="list-wrapper">

  <div class="row p-0 m-0 info">
    <button (click)="returnHandler()" #searchbar class="btn">
      <!-- <i-bs name="arrow-left-short" width="2rem" height="2rem"></i-bs> -->
      Voltar
    </button>
    <div class="info__data" *ngIf="dataHistoric?.results">
      Total cadastrado {{dataHistoric!.results!.length }} -
      Mostrando {{ dataHistoric!.results!.length }} de {{ dataHistoric!.results!.length }}
    </div>
  </div>

  <div class="card">
    <div class="data-list-wrapper">
      <div class="list__header" [ngClass]="mediaQuery ? '' : 'fit-content'">
        <div *ngFor="let header of headers; let columnIndex = index" class="title"
          [style]="{'min-width': getWidth(columnIndex)}">
          <button (click)="onHeaderClick(header.name)">
            {{header.label}}
            <!-- <i *ngIf="header.name == selectedOrdering && !invertedOrdering"
              class="icon-Icon-awesome-caret-down ordering-indicator"></i>

            <i *ngIf="header.name == selectedOrdering && invertedOrdering"
              class="icon-Icon-awesome-caret-down ordering-indicator-reverse"></i> -->
          </button>
        </div>
      </div>
      <div class="table">
        <div class="table__body" *ngIf="dataHistoric?.results">
          <div class="table__border-bottom" *ngFor="let row of dataHistoric?.results; let rowIndex = index">
            <div class="table__data" (click)="rowClickHandle(rowIndex)" [ngClass]="mediaQuery ? '' : 'fit-content'">
              <div *ngFor="let item of headers; let columnIndex = index" [style]="{'min-width': getWidth(columnIndex)}"
                class="table__data-column">
                <span *ngIf="item.name && item.name != 'sent_at' && item.name != 'attendance__date_time'">
                  {{ getKeyByValue(row, item.name) }}
                </span>
                <span *ngIf="item.name == 'sent_at' || item.name == 'attendance__date_time'">
                  {{ getDate(row, item.name) }}
                </span>
              </div>
            </div>
            <div class="table__note" *ngIf="showNote(rowIndex)">
              <span>Observações:</span> {{ row.notes }}
            </div>
          </div>
        </div>

        <div *ngIf="hasNext && !loading && dataHistoric?.results" (click)="nextPage()">
          <div class="loading-next">
            <ng-container *ngIf="!loadingNext">
              Carregar mais...
            </ng-container>
            <ng-container *ngIf="loadingNext">
              Carregando
            </ng-container>
          </div>
        </div>

        <div *ngIf="loading" class="loading">
          <div class="spinner-border spinner-border-xl" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
