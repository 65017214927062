import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TagModel } from 'ngx-chips/core/accessor';
import { OptionModel } from '../../models/option';

@Component({
  selector: 'chip-input',
  templateUrl: './chip-input.component.html',
  styleUrls: ['./chip-input.component.scss']
})
export class ChipInputComponent implements OnInit {

  @Input() id!: string;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() formGroup!: FormGroup;
  @Input() optionList!: OptionModel[];
  @Input() disabled!: boolean;

  items: TagModel[] = [];

  constructor() { }

  ngOnInit(): void {
    this.optionList?.forEach((option) => {
      this.items = [...this.items, { label: option.label, value: option.name }];
    })
  }

  addTagFn(name: string) {
    return { name: name, tag: true };
  }
}
