import { Injectable } from '@angular/core';

import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { AccountModel } from '../models/account.model';
import { Observable } from 'rxjs';
import { GroupModel } from '../models/group.model';
import { ListModel } from '../models/list.model';
import { PatientModel } from '../models/patient.model';
import { ProfessionalModel } from '../models/professional.model';
import { PermissionModel } from '../models/permission.model';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private httpClient: HttpClient) { }

  login(accountData: AccountModel): Observable<AccountModel> {
    return this.httpClient.post<AccountModel>(`${environment.API_URL}/accounts/oauth/login/`, JSON.stringify(accountData));
  }

  loginConfirmation(data: any): Observable<AccountModel> {
    return this.httpClient.post<AccountModel>(`${environment.API_URL}/accounts/oauth/login/confirmation/`, JSON.stringify(data));
  }

  register(accountData: AccountModel): Observable<AccountModel> {
    return this.httpClient.post<AccountModel>(`${environment.API_URL}/accounts/`, JSON.stringify(accountData));
  }

  edit(accountData: AccountModel): Observable<AccountModel> {
    return this.httpClient.put<AccountModel>(`${environment.API_URL}/accounts/${accountData.id}/`, JSON.stringify(accountData));
  }

  getOne(accountId: number): Observable<AccountModel> {
    return this.httpClient.get<AccountModel>(`${environment.API_URL}/accounts/${accountId}/`);
  }

  remove(accountId: number): Observable<AccountModel> {
    return this.httpClient.delete<AccountModel>(`${environment.API_URL}/accounts/${accountId}/`);
  }

  list(searchString: string, orderingItem: string, isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/accounts/?search=${searchString}&ordering=${orderingItem}&is_active=${isActive}&page=${page}`);
  }

  accountSearch(document_number: number): Observable<AccountModel> {
    return this.httpClient.get<AccountModel>(`${environment.API_URL}/accounts/search/?document_number=${document_number}`);
  }

  accountSearchByPhoneNumber(phoneNumber: string, countryCode: string): Observable<AccountModel> {
    return this.httpClient.get<AccountModel>(`${environment.API_URL}/accounts/search_by_phone_number/?phone_number=${phoneNumber}&country_code=${countryCode}`);
  }

  patientSearch(document_number: number): Observable<PatientModel> {
    return this.httpClient.get<PatientModel>(`${environment.API_URL}/patient/search/?document_number=${document_number}`);
  }

  professionalSearch(document_number: number): Observable<ProfessionalModel> {
    return this.httpClient.get<ProfessionalModel>(`${environment.API_URL}/professional/search/?document_number=${document_number}`);
  }

  permissionsList(): Observable<PermissionModel[]> {
    return this.httpClient.get<PermissionModel[]>(`${environment.API_URL}/permissions/user_permissions/`);
  }

  groupRegister(groupData: GroupModel): Observable<GroupModel> {
    return this.httpClient.post<GroupModel>(`${environment.API_URL}/permissions/groups/`, JSON.stringify(groupData));
  }

  groupEdit(groupData: GroupModel): Observable<GroupModel> {
    return this.httpClient.put<GroupModel>(`${environment.API_URL}/permissions/groups/${groupData.id}/`, JSON.stringify(groupData));
  }

  groupGet(groupId: number): Observable<GroupModel> {
    return this.httpClient.get<GroupModel>(`${environment.API_URL}/permissions/groups/${groupId}`);
  }

  groupsList(searchString: string, orderingItem: string): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/permissions/groups/?search=${searchString}&ordering=${orderingItem}`);
  }

  uploadImage(imageFile: File | undefined, accountId: number): Observable<object> {
    const formData = new FormData();
    formData.append("profile_image", imageFile ? imageFile : '');

    return this.httpClient.patch<object>(`${environment.API_URL}/accounts/${accountId}/profile_image/`, formData);
  }

  passwordRecovery(accountData: AccountModel) {
    return this.httpClient.patch<object>(`${environment.API_URL}/accounts/reset_password/`, accountData);
  }

  passwordSet(accountData: AccountModel) {
    return this.httpClient.patch<object>(`${environment.API_URL}/accounts/password/`, accountData);
  }

  systemUserRedefinePassword(accountId: number, accountRedefinePassword: any) {
    return this.httpClient.patch<any>(`${environment.API_URL}/accounts/${accountId}/change_password/`, JSON.stringify(accountRedefinePassword));
  }

  clinicUserRedefinePassword(accountId: number, clinicId: number, accountRedefinePassword: any) {
    return this.httpClient.patch<any>(`${environment.API_URL}/accounts/${accountId}/change_password/?clinic_id=${clinicId}`, JSON.stringify(accountRedefinePassword));
  }

  clinicUserEdit(clinicUserData: AccountModel): Observable<AccountModel> {
    return this.httpClient.patch<AccountModel>(`${environment.API_URL}/clinic_accounts/${clinicUserData.id}/`, JSON.stringify(clinicUserData));
  }

  clinicUserRegister(clinicUserData: AccountModel): Observable<AccountModel> {
    return this.httpClient.post<AccountModel>(`${environment.API_URL}/clinic_accounts/`, JSON.stringify(clinicUserData));
  }

  clinicUserGet(clinicUserId: number): Observable<AccountModel> {
    return this.httpClient.get<AccountModel>(`${environment.API_URL}/clinic_accounts/${clinicUserId}/`);
  }

  clinicUserList(searchString: string, orderingItem: string, clinicId: string, isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/clinic_accounts/?clinic_id=${clinicId}&search=${searchString}&ordering=${orderingItem}&is_active=${isActive}&page=${page}`);
  }

  clinicUserRemove(clinicUserId: number): Observable<AccountModel> {
    return this.httpClient.delete<AccountModel>(`${environment.API_URL}/clinic_accounts/${clinicUserId}/`);
  }

}
