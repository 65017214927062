<div class="modal-wrapper" *ngIf="!loading else loadingTemplate">
  <div class="main" *ngIf="question">
    <div class="header">
      <p class="title-question" *ngIf="question.question && question.question != ' '">{{ question.question }}</p>
      <p class="subtitle" *ngIf="question.hint" [innerHTML]="question.hint.replace('\\n', '<br>')"></p>
    </div>

    <ng-container *ngIf="question.type == 'FLOAT'">
      <input placeholder="Digite apenas números" class="input-default" [(ngModel)]="question.answer" mask="0,00"
             [dropSpecialCharacters]="false">
    </ng-container>

    <ng-container *ngIf="question.type == 'NUMBER'">
      <input placeholder="Digite apenas números" class="input-default" mask="0*" [(ngModel)]="question.answer">
    </ng-container>

    <ng-container *ngIf="question.type == 'TEXT'">
      <input placeholder="Digite aqui" class="input-default" mask="A*" [(ngModel)]="question.answer">
    </ng-container>

    <ng-container *ngIf="question.type == 'CHECKBOX'">
      <form class="form-style-checkbox">
        <ng-container *ngFor="let option of question.data">
          <label>
            {{ option.label }}
            <input type="checkbox" [name]="[]" (click)="toggleCheckbox(option.label)"
                   [checked]="(question.answer ?? []).includes(option.label)">
          </label>
        </ng-container>
      </form>
    </ng-container>

    <ng-container *ngIf="question.type == 'RADIO'">
      <form class="form-style">
        <ng-container *ngFor="let option of question.data; let index=index">
          <label>
            <input type="radio" name="list-option" [(ngModel)]="question.answer" [value]="option.label">
            {{ option.label }}
          </label>
        </ng-container>
      </form>
    </ng-container>

    <ng-container *ngIf="question.type == 'IMAGE'">
      <div class="type-img-button">
        <input type="file" (change)="addImage($event)" #fileInput accept="image/*" class="hidden" [multiple]="!isSelfie(question)">

        <button (click)="fileInput.click()">
          Selecionar foto
        </button>
      </div>

      <div class="img-block">
        <ng-container *ngFor="let image of question.answer">
          <img [src]="readImage(image)" alt="">
        </ng-container>
      </div>
    </ng-container>

    <div class="button-control">
      <button class="btn btn-outline-primary" (click)="closeModal()">Cancelar</button>
      <button class="btn btn-primary" (click)="submit()" [disabled]="disabledSubmitButton">
        Salvar
      </button>
    </div>

  </div>

  <div class="main" *ngIf="!question && questionOptions">
    <ng-select [items]="questionOptions" [hideSelected]="true" [searchable]="true" [multiple]="false" bindLabel="label"
               bindValue="value" (change)="setQuestionId($event)" placeholder="Selecione a pergunta que deseje responder"></ng-select>

    <div class="button-control">
      <button class="btn btn-outline-primary" (click)="closeModal()">Cancelar</button>
      <button class="btn btn-primary" (click)="setQuestion()" [disabled]="!questionId">Responder</button>
    </div>
  </div>
</div>



<ng-template #loadingTemplate>
  <div class="col full-loading text-center loading-modal">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
