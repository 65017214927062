import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { TagInputModule } from 'ngx-chips';

import { FormRendererComponent } from './form-renderer/form-renderer.component';
import { BasicInputComponent } from './components/basic-input/basic-input.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { DropdownInputComponent } from './components/dropdown-input/dropdown-input.component';
import { ChipInputComponent } from './components/chip-input/chip-input.component';
import { MultilineInputComponent } from './components/multiline-input/multiline-input.component';
import { MultidataInputComponent } from './components/multidata-input/multidata-input.component';
import { ImageInputComponent } from './components/image-input/image-input.component';
import { RadioInputComponent } from './components/radio-input/radio-input.component';
import { ListRendererComponent } from './list-renderer/list-renderer.component';
import { RouterModule } from '@angular/router';
import { ObjectValuesPipe } from './pipes/object-keys.pipe';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { CheckboxInputComponent } from './components/checkbox-input/checkbox-input.component';
import { NgxMaskModule } from 'ngx-mask';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { ReadonlyComponent } from './components/readonly/readonly.component';
import { AttachmentsInputComponent } from './components/attachments-input/attachments-input.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { NumberOnlyComponent } from './components/number-only/number-only.component';
import { ZipcodeInputComponent } from './components/zipcode-input/zipcode-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { DdiInputComponent } from './components/ddi-input/ddi-input.component';
import { ExportModalComponent } from './components/export-modal/export-modal.component';

@NgModule({
  declarations: [
    FormRendererComponent,
    BasicInputComponent,
    DateInputComponent,
    SearchInputComponent,
    DropdownInputComponent,
    ChipInputComponent,
    MultilineInputComponent,
    MultidataInputComponent,
    ImageInputComponent,
    RadioInputComponent,
    ListRendererComponent,
    ObjectValuesPipe,
    FormErrorComponent,
    CheckboxInputComponent,
    ScheduleComponent,
    ReadonlyComponent,
    AttachmentsInputComponent,
    FileInputComponent,
    NumberOnlyComponent,
    ZipcodeInputComponent,
    ConfirmationModalComponent,
    ErrorModalComponent,
    DdiInputComponent,
    ExportModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TagInputModule,
    NgxMaskModule,
  ],
    exports: [
        FormRendererComponent,
        ListRendererComponent,
        BasicInputComponent,
        DateInputComponent,
        SearchInputComponent,
        MultilineInputComponent,
        DropdownInputComponent,
        DdiInputComponent,
        ImageInputComponent,
        CheckboxInputComponent,
        FormErrorComponent
    ]
})
export class RenderersModule { }
