<div class="field" [formGroup]="formGroup">
    <label class="field__label" [for]="id">{{label ? label : ""}}</label>
    <div *ngIf="readOnlyMultidata == false || readOnlyMultidata == undefined">
        <div class=" row" *ngFor="let group of array.controls; let index = index">
            <div *ngFor="let input of inputs; let inputIndex = index" [ngClass]="['px-2', input.name == 'id' ? 'id-input' : '', 'input', input.widthInCols?.xs ? 'col-' +
            input.widthInCols?.xs : 'col-12' , input.widthInCols?.sm ? 'col-sm-' + input.widthInCols?.sm : '' ,
            input.widthInCols?.md ? 'col-md-' + input.widthInCols?.md : '' , input.widthInCols?.lg ? 'col-lg-' +
            input.widthInCols?.lg : '' , input.widthInCols?.xl ? 'col-xl-' + input.widthInCols?.xl : '' ]">
                <basic-input *ngIf="input.type == 0" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                    [placeholder]="input.placeholder!" [formGroup]="findNestedFormGroup(index)"
                    [removeVerticalSpacing]="input.removeVerticalSpacing! ? input.removeVerticalSpacing! : false"
                    [maskOptions]="input.maskOptions!">
                </basic-input>

                <search-input *ngIf="input.type == 1" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                    [placeholder]="input.placeholder!" [formGroup]="findNestedFormGroup(index)"
                    [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '']"
                    [optionList]="getOptionsList(index, inputIndex)"
                    (onValueChange)="onSearchValueChange(index, inputIndex, $event)" [showAddItem]="input.showAddItem!">
                </search-input>

                <date-input *ngIf="input.type == 2" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                    [placeholder]="input.placeholder!" [formGroup]="findNestedFormGroup(index)"
                    [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '']">
                </date-input>

                <dropdown-input *ngIf="input.type == 3" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                    [placeholder]="input.placeholder!" [optionList]="input.dropdownOptions!"
                    [formGroup]="findNestedFormGroup(index)"
                    [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '']"
                    [removeVerticalSpacing]="input.removeVerticalSpacing! ? input.removeVerticalSpacing! : false">
                </dropdown-input>

                <chip-input *ngIf="input.type == 4" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                    [placeholder]="input.placeholder!" [formGroup]="findNestedFormGroup(index)"
                    [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '']">
                </chip-input>

                <multiline-input *ngIf="input.type == 5" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                    [placeholder]="input.placeholder!" [formGroup]="findNestedFormGroup(index)"
                    [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '']">
                </multiline-input>

                <image-input *ngIf="input.type == 7" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                    [placeholder]="input.placeholder!" [formGroup]="findNestedFormGroup(index)"
                    [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '']">
                </image-input>

                <radio-input *ngIf="input.type == 8" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                    [placeholder]="input.placeholder!" [optionList]="input.dropdownOptions!"
                    [formGroup]="findNestedFormGroup(index)"
                    [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '']">
                </radio-input>

                <dropdown-input *ngIf="input.type == 14" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                    [placeholder]="input.placeholder!" [optionList]="input.dropdownOptions!"
                    [formGroup]="findNestedFormGroup(index)"
                    (onValueChange)="onDropdownChange(index, inputIndex, $event)">
                </dropdown-input>

                <ddi-input *ngIf="input.type == 18" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                    [placeholder]="input.placeholder!" [formGroup]="findNestedFormGroup(index)"
                    [removeVerticalSpacing]="input.removeVerticalSpacing! ? input.removeVerticalSpacing! : false">
                </ddi-input>
            </div>
            <div *ngIf="canRemoveAll">
                <button *ngIf="(index > 0)" (click)="dataRemoveHandler(index, $event)"
                    class="remove-button">Remover</button>

            </div>
            <div class="remove-button-wrapper">
                <button *ngIf="(index == 0 && canRemoveFirst)" (click)="dataRemoveHandler(index, $event)"
                class="remove-button">Remover</button>
            </div>
        </div>

        <button *ngIf="showAddItem" class="add-button" (click)="dataAddHandler($event)">Adicionar novo</button>
    </div>

    <div *ngIf="readOnlyMultidata == true">
        <div *ngFor="let group of array.controls; let index = index">
            <div *ngIf="index == 0" class=" row">
                <div *ngFor="let input of inputs; let inputIndex = index" [ngClass]="['px-2', input.name == 'id' ? 'id-input' : '', 'input', input.widthInCols?.xs ? 'col-' +
            input.widthInCols?.xs : 'col-12' , input.widthInCols?.sm ? 'col-sm-' + input.widthInCols?.sm : '' ,
            input.widthInCols?.md ? 'col-md-' + input.widthInCols?.md : '' , input.widthInCols?.lg ? 'col-lg-' +
            input.widthInCols?.lg : '' , input.widthInCols?.xl ? 'col-xl-' + input.widthInCols?.xl : '' ]">
                    <basic-input *ngIf="input.type == 0" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                        [placeholder]="input.placeholder!" [formGroup]="findNestedFormGroup(index)"
                        [removeVerticalSpacing]="input.removeVerticalSpacing! ? input.removeVerticalSpacing! : false"
                        [maskOptions]="input.maskOptions!">
                    </basic-input>

                    <search-input *ngIf="input.type == 1" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                        [placeholder]="input.placeholder!" [formGroup]="findNestedFormGroup(index)"
                        [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '']"
                        [optionList]="getOptionsList(index, inputIndex)"
                        (onValueChange)="onSearchValueChange(index, inputIndex, $event)" [showAddItem]="false"
                        [hideClear]="true">
                    </search-input>

                    <date-input *ngIf="input.type == 2" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                        [placeholder]="input.placeholder!" [formGroup]="findNestedFormGroup(index)"
                        [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '']">
                    </date-input>

                    <dropdown-input *ngIf="input.type == 3" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                        [placeholder]="input.placeholder!" [optionList]="input.dropdownOptions!"
                        [formGroup]="findNestedFormGroup(index)"
                        [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '']"
                        [removeVerticalSpacing]="input.removeVerticalSpacing! ? input.removeVerticalSpacing! : false">
                    </dropdown-input>

                    <chip-input *ngIf="input.type == 4" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                        [placeholder]="input.placeholder!" [formGroup]="findNestedFormGroup(index)"
                        [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '']">
                    </chip-input>

                    <multiline-input *ngIf="input.type == 5" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                        [placeholder]="input.placeholder!" [formGroup]="findNestedFormGroup(index)"
                        [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '']">
                    </multiline-input>

                    <image-input *ngIf="input.type == 7" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                        [placeholder]="input.placeholder!" [formGroup]="findNestedFormGroup(index)"
                        [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '']">
                    </image-input>

                    <radio-input *ngIf="input.type == 8" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                        [placeholder]="input.placeholder!" [optionList]="input.dropdownOptions!"
                        [formGroup]="findNestedFormGroup(index)"
                        [ngClass]="[formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : '']">
                    </radio-input>

                    <dropdown-input *ngIf="input.type == 14" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                        [placeholder]="input.placeholder!" [optionList]="input.dropdownOptions!"
                        [formGroup]="findNestedFormGroup(index)"
                        (onValueChange)="onDropdownChange(index, inputIndex, $event)">
                    </dropdown-input>

                    <ddi-input *ngIf="input.type == 18" [id]="input.name" [label]="index == 0 ? input.label! : ''"
                        [placeholder]="input.placeholder!" [formGroup]="findNestedFormGroup(index)"
                        [removeVerticalSpacing]="input.removeVerticalSpacing! ? input.removeVerticalSpacing! : false">
                    </ddi-input>
                </div>

                <div *ngIf="canRemoveAll">
                    <button *ngIf="(index > 0 )" (click)="dataRemoveHandler(index, $event)"
                        class="remove-button">Remover</button>
                </div>

                <button class="add-button" (click)="dataAddHandler($event)">Adicionar novo</button>
            </div>

            <div *ngIf="index > 0" class=" row read-only-value-wrapper">
                <div *ngFor="let input of inputs; let inputIndex = index" [ngClass]="['px-2', input.name == 'id' ? 'id-input' : '', 'input', input.widthInCols?.xs ? 'col-' +
                input.widthInCols?.xs : 'col-12' , input.widthInCols?.sm ? 'col-sm-' + input.widthInCols?.sm : '' ,
                input.widthInCols?.md ? 'col-md-' + input.widthInCols?.md : '' , input.widthInCols?.lg ? 'col-lg-' +
                input.widthInCols?.lg : '' , input.widthInCols?.xl ? 'col-xl-' + input.widthInCols?.xl : '' ]">
                    <p class="read-only-value">{{readOnlyValues[index - 1].label}}</p>
                </div>

                <div *ngIf="canRemoveAll">
                    <button *ngIf="(index > 0 )" (click)="dataRemoveHandler(index, $event)"
                        class="remove-button2">Remover</button>
                </div>
            </div>
        </div>
    </div>
</div>
