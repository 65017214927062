import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MaskOptions } from '../../models/form-input';

@Component({
  selector: 'schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {

  @Input() id!: string;
  @Input() formGroup!: FormGroup;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() maskOptions!: MaskOptions;

  constructor() { }

  ngOnInit(): void {
  }

}
