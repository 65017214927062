import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileModel } from '../models/file.model';
import { ListModel } from '../models/list.model';
import { PatientModel } from '../models/patient.model';
import { TagModel } from '../models/tag.model';
import { ClinicModel } from '../models/clinic.model';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private httpClient: HttpClient) { }

  patientRegister(patientData: PatientModel): Observable<PatientModel> {
    return this.httpClient.post<PatientModel>(`${environment.API_URL}/patients/`, JSON.stringify(patientData));
  }

  patientAttachmentRegister(file: File, name: string, patientId: number): Observable<FileModel> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("label", name);
    formData.append("is_active", "True");

    return this.httpClient.post<FileModel>(`${environment.API_URL}/patients/${patientId}/attachments/`, formData);
  }

  patientAttachmentRemove(patientId: number, attachmentId: number) {
    return this.httpClient.delete(`${environment.API_URL}/patients/${patientId}/attachments/${attachmentId}`);
  }

  patientAttachmentsList(patientId: string): Observable<object[]> {
    return this.httpClient.get<object[]>(`${environment.API_URL}/patients/${patientId}`);
  }

  patientEdit(patientData: PatientModel): Observable<PatientModel> {
    return this.httpClient.patch<PatientModel>(`${environment.API_URL}/patients/${patientData.id}/`, JSON.stringify(patientData));
  }

  patientRemove(patientId: number) {
    return this.httpClient.delete(`${environment.API_URL}/patients/${patientId}/`);
  }

  patientsList(searchString: string, orderingItem: string, clinicId: string = "", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/patients/?clinic_id=${clinicId}&search=${searchString}&ordering=${orderingItem}&is_active=${isActive}&page=${page}`);
  }

  patientsAll(): Observable<PatientModel[]> {
    return this.httpClient.get<PatientModel[]>(`${environment.API_URL}/patients/all/?ordering=name&is_active=true`);
  }

  patientGet(patientId: number): Observable<PatientModel> {
    return this.httpClient.get<PatientModel>(`${environment.API_URL}/patients/${patientId}`);
  }

  uploadImage(imageFile: File | undefined, patientId: number, clinicId: string = ''): Observable<object> {
    const formData = new FormData();
    formData.append("profile_image", imageFile ? imageFile : '');

    return this.httpClient.patch<object>(`${environment.API_URL}/patients/${patientId}/profile_image/?clinic_id=${clinicId}`, formData);
  }

  listAllTagPatient(clinicId: string = ''): Observable<TagModel[]> {
    return this.httpClient.get<TagModel[]>(`${environment.API_URL}/tags/patient/all/?clinic_id=${clinicId}&ordering=name&is_active=true`);
  }

  searchPatient(document_number: number): Observable<PatientModel> {
    return this.httpClient.get<PatientModel>(`${environment.API_URL}/search_patient/?document_number=${document_number}`);
  }

  searchPatientByPhoneNumber(phoneNumber: string, countryCode: string): Observable<PatientModel> {
    return this.httpClient.get<PatientModel>(`${environment.API_URL}/search_patient/phone_number/?phone_number=${phoneNumber}&country_code=${countryCode}`);
  }

  searchPatientByClinic(clinicId: number, document_number: number): Observable<PatientModel> {
    return this.httpClient.get<PatientModel>(`${environment.API_URL}/patients/search/?clinic_id=${clinicId}&document_number=${document_number}`);
  }

  searchPatientAll(document_number: number): Observable<PatientModel> {
    return this.httpClient.get<PatientModel>(`${environment.API_URL}/patients/search/?document_number=${document_number}`);
  }

  connect(clinicId: number, patient_id: number): Observable<PatientModel> {
    return this.httpClient.patch<PatientModel>(`${environment.API_URL}/patients/connect/?clinic_id=${clinicId}`, { 'patient_id': patient_id });
  }

  clinicUserPatientRegister(clinicId: number, patientData: PatientModel): Observable<PatientModel> {
    return this.httpClient.post<PatientModel>(`${environment.API_URL}/patients/?clinic_id=${clinicId}`, JSON.stringify(patientData));
  }

  clinicUserPatientEdit(clinicId: number, patientData: PatientModel): Observable<PatientModel> {
    return this.httpClient.patch<PatientModel>(`${environment.API_URL}/patients/${patientData.id}/?clinic_id=${clinicId}`, JSON.stringify(patientData));
  }

  clinicUserPatientGet(clinicId: number, patientId: number): Observable<PatientModel> {
    return this.httpClient.get<PatientModel>(`${environment.API_URL}/patients/${patientId}/?clinic_id=${clinicId}`);
  }

  clinicUserPatientGetAll(clinicId: number): Observable<PatientModel[]> {
    return this.httpClient.get<PatientModel[]>(`${environment.API_URL}/patients/all/?clinic_id=${clinicId}&ordering=name&is_active=true`);
  }

  clinicUserPatientAttachmentsList(clinicId: number, patientId: string): Observable<object[]> {
    return this.httpClient.get<object[]>(`${environment.API_URL}/patients/${patientId}/?clinic_id=${clinicId}`);
  }

  clinicUserPatientAttachmentRegister(clinicId: number, file: File, name: string, patientId: number): Observable<FileModel> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("label", name);
    formData.append("is_active", "True");

    return this.httpClient.post<FileModel>(`${environment.API_URL}/patients/${patientId}/attachments/?clinic_id=${clinicId}`, formData);
  }

  clinicUserPatientAttachmentRemove(clinicId: number, patientId: number, attachmentId: number) {
    return this.httpClient.delete(`${environment.API_URL}/patients/${patientId}/attachments/${attachmentId}/?clinic_id=${clinicId}`);
  }

  clinicSearchAll(document_number: number): Observable<ClinicModel>{
    return this.httpClient.get<ClinicModel>(`${environment.API_URL}/search_clinic/?document_number=${document_number}`);
  }

}
