import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PolysomnographyExamsService } from 'src/app/core/services/polysomnography-exams.service';
import {SessionManagerService} from "../../../../core/services/session-manager.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-import-clinic-user-exam-modal',
  templateUrl: './import-clinic-user-exam-modal.component.html',
  styleUrls: ['./import-clinic-user-exam-modal.component.scss']
})
export class ImportClinicUserExamModalComponent implements OnInit {

  formGroup: FormGroup = this.formBuilder.group({
    file: [null, [Validators.required]]
  });

  polysomnographyId: number | undefined;
  examId: number | undefined;

  selectedFile?: File;

  loading: boolean = false;

  textButton: string = "Importar"

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private sessionManager: SessionManagerService,
    private toastrManager: ToastrService,
    private polysomnographyExamService: PolysomnographyExamsService
  ) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close();
  }

  fileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  submit() {
    if (this.selectedFile) {
      let clinic = this.sessionManager.getClinicCurrent();
      this.loading = true;
      this.textButton = "Carregando...";
      this.polysomnographyExamService.importClinicUserExam(this.polysomnographyId!, this.examId!, this.selectedFile, clinic?.value).subscribe((response) => {
        this.activeModal.close(true);
      }, error => {
        console.log(error);
        this.toastrManager.error(error.error['detail'], 'Erro');
        this.activeModal.close();
      });
    }
  }
}
